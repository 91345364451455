import amplitude from 'amplitude-js';
import Cookies from 'js-cookie';
import { isIOS, isAndroid, isDesktop } from 'react-device-detect';
import posthog from 'posthog-js';

const currentDevice = () => {
    if (isIOS) {
        return 'IOS';
    }
    if (isAndroid) {
        return 'Android';
    }
    if (isDesktop) {
        return 'Desktop';
    }
    return 'unknown';
};

export const amplitudeInit = () => {
    let userId = Cookies.get('avilunaid');
    amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_KEY, userId, {
        includeReferrer: true,
        includeUtm: true,
        includeGclid: true,
        includeFbclid: true,
        logAttributionCapturedEvent: true,
    });
    //  posthog initimport posthog from 'posthog-js'

    posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
        api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
        person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
    });

    posthog.register({
        platform: currentDevice(),
    });
};

export const setUserProperties = (experiments) => {
    const identify = new amplitude.Identify()
        .set('fbp', Cookies.get('_fbp') || 'none')
        .set('fbc', Cookies.get('_fbc') || 'none')
        .set('ttclid', Cookies.get('ttclid') || 'none')
        .set('gclid', Cookies.get('gclid') || 'none')
        .set('platform', currentDevice())
        .set('experiments', experiments)
        .set(
            'href',
            (() => {
                const currentUrl = new URL(window.location);
                if (currentUrl.href && currentUrl.href.length > 1) {
                    return currentUrl.href;
                }
                return null;
            })() || 'none'
        );
    return amplitude.getInstance().identify(identify);
};

export const setPosthogUserProperties = (experiments) => {
    function getUserId() {
        let userId = localStorage.getItem('user_id');
        if (!userId) {
            userId = `user_${Math.random().toString(36).substr(2, 9)}`;
            localStorage.setItem('user_id', userId);
        }
        return userId;
    }
    const userId = getUserId();
    posthog.identify(userId);

    posthog.people.set({
        experiments: experiments,
    });
};

export const setUserPropertiesFromObj = (obj) => {
    const identify = new amplitude.Identify();
    Object.keys(obj).forEach((key) => {
        identify.set(key, obj[key]);
    });
    return amplitude.getInstance().identify(identify);
};

export const onboardingEvent = () => {
    return amplitude.getInstance().logEvent('Onboarding', {
        value: 'web_payments_2',
        price: localStorage.getItem('price'),
    });
};

export const firstOpenAction = () => {
    return amplitude
        .getInstance()
        .logEvent('First_Open_action', { landing: 'web_payments_2' });
};

export const landingScrollEvent = (data, headerClick, result) => {
    if (headerClick) {
        return amplitude
            .getInstance()
            .logEvent('Landing_scroll', { source: data, result: result });
    } else {
        return amplitude
            .getInstance()
            .logEvent('Landing_scroll', { result: data });
    }
};

export const paymentScrollEvent = (data, handleClick, result) => {
    if (handleClick) {
        // posthog event
        posthog.capture('paywall_scroll', {
            source: data,
            result: result,
        });
        return amplitude
            .getInstance()
            .logEvent('Payment_scroll', { source: data, result: result });
    } else {
        // posthog event
        posthog.capture('paywall_scroll', {
            result: result,
        });
        return amplitude
            .getInstance()
            .logEvent('Payment_scroll', { result: data });
    }
};

export const paymentTransactionInitiated = (type) => {
    // posthog event
    posthog.capture('payment_transaction_initiation', {
        type,
    });

    return amplitude
        .getInstance()
        .logEvent('payment transaction initiated', { type: type });
};

export const paymentTransactionCompleted = (screen, eventObj) => {
    // posthog event
    posthog.capture('payment_transaction_success', {
        screen: screen,
        ...eventObj,
    });

    return amplitude.getInstance().logEvent('payment transaction completed', {
        screen: screen,
        ...eventObj,
    });
};

export const paymentTransactionFailed = (message) => {
    // posthog event
    posthog.capture('payment_transaction_fail', {
        message,
    });

    return amplitude
        .getInstance()
        .logEvent('payment transaction failed', { message });
};

export const paymentScreenAppear = (data) => {
    // posthog event
    posthog.capture('paywall_view', {
        ...data,
    });
    window.dataLayer.push({ event: 'payment screen appear', ...data });
    return amplitude.getInstance().logEvent('payment screen appear', data);
};

export const paymentScreenInitiated = (data) => {
    // posthog event
    posthog.capture('payment_initiation', {
        ...data,
    });
    return amplitude.getInstance().logEvent('payment screen initiated', data);
};

export const paymentScreenDisappear = (data) => {
    // posthog event
    posthog.capture('paywall_close', {
        ...data,
    });
    return amplitude.getInstance().logEvent('payment screen disappear', data);
};

export const purchasePostBack = (dataObj) => {
    // posthog event
    posthog.capture('purchase_postback', {
        ...dataObj,
    });

    window.dataLayer.push({ event: 'purchase postback', ...dataObj });
    return amplitude.getInstance().logEvent('purchase postback', dataObj);
};

export const congratulationsScreenAppear = () => {
    // posthog event
    posthog.capture('congratulation_screen_view', {});

    return amplitude.getInstance().logEvent('congratulation screen appear');
};

export const installTapped = (type) => {
    posthog.capture('install_tapped', { type: type });
    return amplitude.getInstance().logEvent('install tapped', { type: type });
};

export const userGetStarted = () => {
    return amplitude.getInstance().logEvent('User_Get_started');
};

export const stepPersonalizationView = () => {
    return amplitude.getInstance().logEvent('Step_Personalization_view');
};

export const stepPersonalizationComplete = () => {
    return amplitude.getInstance().logEvent('Step_Personalization_complete');
};

export const setVersion = () => {
    return amplitude
        .getInstance()
        .setVersionName(process.env.REACT_APP_VERSION);
};

// quiz events
export const quizFirstOpenEvent = (type) => {
    window.dataLayer.push({ event: 'quiz_first_open_action', type: type });
    // posthog event
    posthog.capture('quiz_first_step_view', {
        type,
    });

    return amplitude.getInstance().logEvent('quiz_first_open_action', {
        type: type,
    });
};

export const quizContinueTapped = (key, value, dataObj) => {
    let data = {
        [key]: value,
        page_name: key,
    };
    if (dataObj) {
        data = dataObj;
    }
    // posthog event
    posthog.capture('quiz_continue_tap', {
        ...data,
    });
    window.dataLayer.push({ event: 'quiz_continue_tapped', ...data });
    return amplitude.getInstance().logEvent('quiz_continue_tapped', data);
};

export const quizBackTapped = (value) => {
    // posthog event
    posthog.capture('quiz_back_tap', {
        result: value,
    });
    return amplitude.getInstance().logEvent('quiz_back_tapped', {
        result: value,
    });
};

export const quizLandingPageViewEvent = (page_name) => {
    posthog.capture('quiz_landing_page_view', {
        page_name,
    });
};

export const quizScrollTapped = (value) => {
    // posthog event
    posthog.capture('quiz_landing_page_sсroll_tap', {
        result: value,
    });
    return amplitude.getInstance().logEvent('quiz_skroll_tapped ', {
        result: value,
    });
};

export const quizPlanSelectedTapped = (value) => {
    // posthog event
    posthog.capture('quiz_landing_page_plan_select', {
        result: value,
    });

    return amplitude.getInstance().logEvent('quiz_plan_selected ', {
        result: value,
    });
};

export const getAmplitudeUserId = () => {
    return amplitude.getInstance().getDeviceId();
};

export const paymentScreenClosed = (data) => {
    // posthog event
    posthog.capture('payment_card_popup_close', {
        ...data,
    });
    return amplitude.getInstance().logEvent('payment screen closed', data);
};

export const specialOfferViewEvent = () => {
    // posthog event
    posthog.capture('special_offer_view', {});
};

export const specialOfferAcceptedEvent = (data) => {
    // posthog event
    posthog.capture('special_offer_accept_tap', { ...data });

    return amplitude.getInstance().logEvent('special offer accepted', data);
};

export const specialOfferClosedEvent = (data) => {
    // posthog event
    posthog.capture('special_offer_close', { ...data });

    return amplitude.getInstance().logEvent('special_offer_closed', data);
};

export const setUserCountryEvent = (data) => {
    return amplitude.getInstance().logEvent('user_country', data);
};

// CHECK SCREEN EVENTS
export const checkPopupOpenEvent = () => {
    // posthog event
    posthog.capture('check_email_popup_view', {});

    window.dataLayer.push({
        event: 'pop-up subscription screen did appear',
    });
};

export const goToAppEvent = (data) => {
    // posthog event
    posthog.capture('check_email_popup_go_to_app_tap', { ...data });

    window.dataLayer.push({ event: 'Go_to_app_tapped', ...data });
};

export const buyAgainEvent = (data) => {
    // posthog event
    posthog.capture('check_email_popup_buy_again_tap', { ...data });

    window.dataLayer.push({ event: 'Buy_again', ...data });
};

export const checkPopupCloseEvent = (data) => {
    // posthog event
    posthog.capture('check_email_popup_close', { ...data });

    window.dataLayer.push({
        event: 'pop-up subscription screen did disappear',
        ...data,
    });
};

export const offerDisplayedEvent = (type) => {
    // posthog event
    posthog.capture('upsell_offer_view', { variant: type });

    window.dataLayer.push({
        event: 'offer_displayed',
        variant: type,
    });
};

export const offerRejectedEvent = (type) => {
    // posthog event
    posthog.capture('upsell_offer_reject_tap', { variant: type });

    window.dataLayer.push({
        event: 'offer_rejected',
        variant: type,
    });
};

export const offerAcceptedEvent = (type) => {
    // posthog event
    posthog.capture('upsell_offer_accept_tap', { variant: type });

    window.dataLayer.push({
        event: 'offer_accepted',
        variant: type,
    });
};

export const congratulationsScreenAppearEvent = () => {
    window.dataLayer.push({
        event: 'congratulation screen appear',
    });
};
