// step 1
import fresh from './../assets/images/quiz/steps/1/fresh_1.jpg';
import salt from './../assets/images/quiz/steps/1/salt_1.jpg';
// step 2
import boat from './../assets/images/quiz/steps/2/boat_1.jpg';
import off from './../assets/images/quiz/steps/2/off_1.jpg';
import pier from './../assets/images/quiz/steps/2/pier_1.jpg';
import wading from './../assets/images/quiz/steps/2/wading_1.jpg';

import jpBoat from './../assets/images/quiz/steps/2/jp/jp_boat.png';
import jpBoatSalt from './../assets/images/quiz/steps/2/jp/jp_boat_salt.png';
import jpShore from './../assets/images/quiz/steps/2/jp/jp_shore.png';
import jpShoreSalt from './../assets/images/quiz/steps/2/jp/jp_shore_salt.png';
import jpIce from './../assets/images/quiz/steps/2/jp/jp_ice.png';
import jpWater from './../assets/images/quiz/steps/2/jp/jp_in_water.png';
import jpPier from './../assets/images/quiz/steps/2/jp/jp_pier.png';
import jpPierSalt from './../assets/images/quiz/steps/2/jp/jp_pier_salt.png';
// step 3
import lake from './../assets/images/quiz/steps/3/lakes_1.jpg';
import rivers from './../assets/images/quiz/steps/3/rivers_1.jpg';
import ponds from './../assets/images/quiz/steps/3/ponds_1.jpg';
import ponds2 from './../assets/images/quiz/steps/3/ponds_2.png';
import creeks from './../assets/images/quiz/steps/3/creeks_1.jpg';
import bays from './../assets/images/quiz/steps/3/bays_1.jpg';
import ocean from './../assets/images/quiz/steps/3/ocean_1.jpg';
import reservioirs from './../assets/images/quiz/steps/3/reserviors.png';
import reservioirsAu from './../assets/images/quiz/steps/3/reservioirs_au.png';
import swamps from './../assets/images/quiz/steps/3/swamps.png';
import estuariesAu from './../assets/images/quiz/steps/3/estuaries_au.png';
import beachesAu from './../assets/images/quiz/steps/3/beaches_and_surf.png';
//jp
import jpLakes from './../assets/images/quiz/steps/3/jp/jp_lakes.png';
import jpRivers from './../assets/images/quiz/steps/3/jp/jp_rivers.png';
import jpPonds from './../assets/images/quiz/steps/3/jp/jp_ponds.png';
import jpBay from './../assets/images/quiz/steps/3/jp/jp_bay.png';
import jpOcean from './../assets/images/quiz/steps/3/jp/jp_ocean.png';
import jpSea from './../assets/images/quiz/steps/3/jp/jp_sea.png';
import jpEstuary from './../assets/images/quiz/steps/3/jp/jp_estuary.png';
import jpReservoirs from './../assets/images/quiz/steps/3/jp/jp_reservoirs.png';
import jpStreams from './../assets/images/quiz/steps/3/jp/jp_streams.png';
//ca
import caIce from './../assets/images/quiz/steps/3/ca/ice.png';
import caSee from './../assets/images/quiz/steps/3/ca/see.png';
import caEstuaries from './../assets/images/quiz/steps/3/ca/estuaries.png';
import caGulfs from './../assets/images/quiz/steps/3/ca/gulfs.png';
import caInlets from './../assets/images/quiz/steps/3/ca/inlets.png';
import caRivers from './../assets/images/quiz/steps/3/ca/rivers.png';
import caCanals from './../assets/images/quiz/steps/3/ca/canals.png';
//uk
import ukEsturies from './../assets/images/quiz/steps/3/uk/estuaries.png';

// step 4
import larg from './../assets/images/quiz/steps/4/largemous_bass.png';
import crappie from '../assets/images/quiz/steps/4/crappie.png';
import bluegill from './../assets/images/quiz/steps/4/bluegill.png';
import common from './../assets/images/quiz/steps/4/common_snook.png';
import small from './../assets/images/quiz/steps/4/smallmouth_bass.png';
import channel from './../assets/images/quiz/steps/4/channel_catfish.png';
import red from './../assets/images/quiz/steps/4/redfish.png';
import walleye from './../assets/images/quiz/steps/4/walleye.png';
import brown from './../assets/images/quiz/steps/4/brown_trout.png';
import north from './../assets/images/quiz/steps/4/northern_pike.png';
import muskie from './../assets/images/quiz/steps/4/muskie.png';
import panfish from './../assets/images/quiz/steps/4/panfish.png';
import snapper from './../assets/images/quiz/steps/4/red_snapper.png';
import tuna from './../assets/images/quiz/steps/4/tuna.png';
import mahi from './../assets/images/quiz/steps/4/mahi_mahi.png';
import flounder from './../assets/images/quiz/steps/4/flounder.png';
import grouper from './../assets/images/quiz/steps/4/grouper.png';
import striped from './../assets/images/quiz/steps/4/striped_bass.png';
import tarpon from './../assets/images/quiz/steps/4/tarpon.png';
import bluefish from './../assets/images/quiz/steps/4/bluefish.png';
import otherSalt from './../assets/images/quiz/steps/4/other_salt.png';
import otherFresh from './../assets/images/quiz/steps/4/other_fresh.png';
import cobia from './../assets/images/quiz/steps/4/cobia.png';
import marlin from './../assets/images/quiz/steps/4/marlin.png';
import salmon from './../assets/images/quiz/steps/4/salmon.png';
import commonCarp from './../assets/images/quiz/steps/4/common_carp.png';
import yellowPerch from './../assets/images/quiz/steps/4/yellow_perch.png';
import freshwaterDrum from './../assets/images/quiz/steps/4/freshwater_drum.png';
import rainbowTrout from './../assets/images/quiz/steps/4/rainbow_trout.png';
import sturgeon from './../assets/images/quiz/steps/4/sturgeon.png';
import whiteBass from './../assets/images/quiz/steps/4/white_bass.png';
import shad from './../assets/images/quiz/steps/4/shad.png';
import gar from './../assets/images/quiz/steps/4/gar.png';
import bullheadCatfish from './../assets/images/quiz/steps/4/bullhead_catfish.png';
import boraImg from './../assets/images/quiz/steps/4/bora.png';
import bigeyeTunaImg from './../assets/images/quiz/steps/4/bigeye_tuna.png';
import chumSalmonImg from './../assets/images/quiz/steps/4/chum_salmon.png';
import jpMackrele from './../assets/images/quiz/steps/4/japanese_spanish_mackerel.png';
import jpSeabass from './../assets/images/quiz/steps/4/japaneses_seabass.png';
import jpYellowtail from './../assets/images/quiz/steps/4/japanese_yellowtail.png';
import redSeabream from './../assets/images/quiz/steps/4/red_seabream.png';
import yellowfinTuna from './../assets/images/quiz/steps/4/yellowfin_tuna.png';
import breamUk from './../assets/images/quiz/steps/4/bream.png';

import murrayCodImg from './../assets/images/quiz/steps/4/murray_cod.png';
import goldenPerchImg from './../assets/images/quiz/steps/4/golden_perch.png';
import silverPerchImg from './../assets/images/quiz/steps/4/silver_perch.png';
import saratogaImg from './../assets/images/quiz/steps/4/saratoga.png';

import blackMarlin from './../assets/images/quiz/steps/4/black_marlin.png';
import yellowfinTunaAu from './../assets/images/quiz/steps/4/yellowfin_tuna_au.png';
import blackBream from './../assets/images/quiz/steps/4/black_bream.png';
import yellowfinBream from './../assets/images/quiz/steps/4/yellowfin_bream.png';
import sandWhiting from './../assets/images/quiz/steps/4/sand_whiting.png';
import austrSnapper from './../assets/images/quiz/steps/4/australasian_snapper.png';
import yellowTailKingfish from './../assets/images/quiz/steps/4/yellowtail_kingfish.png';
import mulloway from './../assets/images/quiz/steps/4/mulloway.png';
import southBluefinTuna from './../assets/images/quiz/steps/4/southern_bluefin_tuna.png';
import stripedMarlin from './../assets/images/quiz/steps/4/striped_marlin.png';

import chinookSalmon from './../assets/images/quiz/steps/4/chinook_salmon.png';
import atlSalmon from './../assets/images/quiz/steps/4/atlantic_salmon.png';
import catfishCa from './../assets/images/quiz/steps/4/catfish.png';
import mackerel from './../assets/images/quiz/steps/4/mackerel.png';
import lakeTrout from './../assets/images/quiz/steps/4/lake_trout.png';
import cod from './../assets/images/quiz/steps/4/cod.png';
import pacificHalibut from './../assets/images/quiz/steps/4/pacific_halibut.png';
import bluefinTuna from './../assets/images/quiz/steps/4/bluefin_tuna.png';
import haddock from './../assets/images/quiz/steps/4/haddock.png';
import lingcod from './../assets/images/quiz/steps/4/lingcod.png';
import rockfish from './../assets/images/quiz/steps/4/rockfish.png';

import roach from './../assets/images/quiz/steps/4/roach.png';
import europeanPerch from './../assets/images/quiz/steps/4/european_perch.png';
import breamFish from './../assets/images/quiz/steps/4/bream.png';
import chub from './../assets/images/quiz/steps/4/chub.png';
import tench from './../assets/images/quiz/steps/4/tench.png';
import barbel from './../assets/images/quiz/steps/4/barbel.png';
import pollock from './../assets/images/quiz/steps/4/pollock.png';
import europeanFlounder from './../assets/images/quiz/steps/4/european_flounder.png';
import ray from './../assets/images/quiz/steps/4/ray.png';
import bass from './../assets/images/quiz/steps/4/bass.png';
import whiting from './../assets/images/quiz/steps/4/whiting.png';
import dogfish from './../assets/images/quiz/steps/4/dogfish.png';
import congerEel from './../assets/images/quiz/steps/4/conger_eel.png';

// step 5
import flies from './../assets/images/quiz/steps/5/flies.png';
import jigs from './../assets/images/quiz/steps/5/jigs.png';
import spinner from './../assets/images/quiz/steps/5/spinner.png';
import plugs from './../assets/images/quiz/steps/5/plugs.png';
import spoons from './../assets/images/quiz/steps/5/spoons.png';
import soft from './../assets/images/quiz/steps/5/soft.png';
import other from './../assets/images/quiz/steps/5/other.png';
import insects from './../assets/images/quiz/steps/5/insects.png';
import minnows from './../assets/images/quiz/steps/5/minnows.png';
import maggots from './../assets/images/quiz/steps/5/maggots.png';
import worms from './../assets/images/quiz/steps/5/worms.png';
import baits from './../assets/images/quiz/steps/5/baits.png';
import shrimp from './../assets/images/quiz/steps/5/live_shrimp.png';
import squid from './../assets/images/quiz/steps/5/squid.png';
import othSalt from './../assets/images/quiz/steps/5/oteher_salt.png';
import crankBaits from './../assets/images/quiz/steps/5/crankbaits.png';
import boilies from './../assets/images/quiz/steps/5/boilies.png';
import fishBait from './../assets/images/quiz/steps/5/fish_bait.png';
import poppersImg from './../assets/images/quiz/steps/5/poppers.png';
import shellFish from './../assets/images/quiz/steps/5/shellfish.png';

import squidAu from './../assets/images/quiz/steps/5/squid_au.png';
import cutBait from './../assets/images/quiz/steps/5/cur_bait.png';
import yabbies from './../assets/images/quiz/steps/5/yabbies.png';
import cornOrDough from './../assets/images/quiz/steps/5/corn_or_dough.png';
import crankbaitsAu from './../assets/images/quiz/steps/5/crankbaits_au.png';
import poppersAu from './../assets/images/quiz/steps/5/poppers_au.png';
import otherAu from './../assets/images/quiz/steps/5/other_au.png';
// jp
import brookTrout from './../assets/images/quiz/steps/4/brook_trout.png';
import auy from './../assets/images/quiz/steps/4/ayu.png';
import crucianCarp from './../assets/images/quiz/steps/4/crucian_carp.png';
import jpEel from './../assets/images/quiz/steps/4/japanese_eel.png';

// step 8
import screen1 from './../assets/images/quiz/steps/8/algorithms_new.png';
// step 14
import screen2 from './../assets/images/quiz/steps/14/screen_2.jpg';
import screen2Jp from './../assets/images/quiz/steps/14/screen_2_jp.png';
import screen2JpSalt from './../assets/images/quiz/steps/14/screen_2_jp_salt.png';
import screen2Salt from './../assets/images/quiz/steps/14/screen_2_salt.jpg';
import screen2Lures from './../assets/images/quiz/steps/14/screen_2_lures.png';
import screen2CaSalt from './../assets/images/quiz/steps/14/screen_2_ca_salt.png';
// step 16
import city from './../assets/images/quiz/steps/15/city.jpg';
// access
import access1 from './../assets/images/quiz/landing/access_1.png';
import access1Jp from './../assets/images/quiz/landing/access_1_jp.png';
import accessLures from './../assets/images/quiz/landing/access_lures.png';
import access2 from './../assets/images/quiz/landing/access_2.png';
import access2Jp from './../assets/images/quiz/landing/access_2_jp.png';
import access3 from './../assets/images/quiz/landing/access_3.png';
// help
import helpImg from './../assets/images/quiz/landing/green_tick.svg';
// first screen
import firstScreen from './../assets/images/first_screen/first_screen_hero.jpg';
import firstScreenLures from './../assets/images/first_screen/first_screen_lures.png';
import firstScreenJp from './../assets/images/first_screen/first_screen_jp.png';

import dusky from './../assets/images/quiz/steps/4/duskie.png';
import ausBass from './../assets/images/quiz/steps/4/aus_bass.png';
import ausSalmon from './../assets/images/quiz/steps/4/aus_salmon.png';
import baramundi from './../assets/images/quiz/steps/4/barramundi.png';
import bream from './../assets/images/quiz/steps/4/surf_bream.png';
import tailor from './../assets/images/quiz/steps/4/tailor.png';
import {
    FIRST_SCREEN_VARIANT_WATER_V1,
    FIRST_SCREEN_VARIANT_WATER_V2,
} from './remoteKeys';

import freshFS from './../assets/images/first_screen/fresh.png';
import saltFS from './../assets/images/first_screen/salt.png';

export const PLACES_DEBOUNCE_MS = 500;

export const PROMO_TIMER_VALUE_IN_SEC = 300;

export const MIN_CHARS_PLACES = 3;
export const START_PLANS_REMAIN = 8;
export const PLANS_TOTAL = 50;

export const ANALYZE_ACTIONS = {
    FIRST_POPUP_OPEN: 'first_popup_open',
    FIRST_POPUP_ANSWER: 'first_popup_answer',
    SECOND_POPUP_OPEN: 'second_popup_open',
    SECOND_POPUP_ANSWER: 'second_popup_answer',
};

export const QUIZ_TYPES = {
    QUIZ_DEFAULT: 'quiz',
    QUIZ_LURES: 'quiz-lures',
};

export const TWEAKS_LOADERS = {
    analyze_loaders: [
        {
            text: 'Analysis of water bodies nearby',
            title: 'Analyzing your answers...',
            color: '#5682F2',
            steps: [
                {
                    finish: 30,
                    timeout: 133,
                },
                {
                    finish: 84,
                    timeout: 70,
                },
                {
                    type: 'stop',
                    action: ANALYZE_ACTIONS.FIRST_POPUP_OPEN,
                    actionToStart: ANALYZE_ACTIONS.FIRST_POPUP_ANSWER,
                    popupData: {
                        type: 'question',
                        mainQuestion: null,
                        step: null,
                        nextStep: null,
                        prevStep: null,
                        progress: null,
                        actionToStart: ANALYZE_ACTIONS.FIRST_POPUP_ANSWER,
                        analyticsKey: 'fishing_laws',
                        title: 'How familiar are you with fishing laws in your state?',
                        answerType: 'single',
                        answers: [
                            {
                                type: 'radio',
                                text: 'Confident',
                                value: 'confident',
                            },
                            {
                                type: 'radio',
                                text: 'Somewhat confident',
                                value: 'somewhat_confident',
                            },
                            {
                                type: 'radio',
                                text: 'Not very confident',
                                value: 'not_very_confident',
                            },
                            {
                                type: 'radio',
                                text: 'Not confident',
                                value: 'not_confident',
                            },
                        ],
                    },
                },
                {
                    finish: 100,
                    timeout: 33,
                },
            ],
        },
        {
            text: 'Calculation of biting activity',
            color: '#4ABCE1',
            title: 'Analyzing weather parameters...',
            steps: [
                {
                    finish: 30,
                    timeout: 133,
                },
                {
                    finish: 84,
                    timeout: 50,
                },
                {
                    type: 'stop',
                    action: ANALYZE_ACTIONS.FIRST_POPUP_OPEN,
                    actionToStart: ANALYZE_ACTIONS.FIRST_POPUP_ANSWER,
                    popupData: {
                        type: 'question',
                        mainQuestion: null,
                        step: null,
                        nextStep: null,
                        prevStep: null,
                        progress: null,
                        actionToStart: ANALYZE_ACTIONS.SECOND_POPUP_ANSWER,
                        analyticsKey: 'next_fishing_trip',
                        title: 'When are you planning your next fishing trip?',
                        answerType: 'single',
                        answers: [
                            {
                                type: 'radio',
                                text: 'Today',
                                value: 'today',
                            },
                            {
                                type: 'radio',
                                text: 'Tomorrow',
                                value: 'tomorrow',
                            },
                            {
                                type: 'radio',
                                text: 'This week',
                                value: 'this_week',
                            },
                            {
                                type: 'radio',
                                text: 'Later',
                                value: 'later',
                            },
                        ],
                    },
                },
                {
                    finish: 100,
                    timeout: 33,
                },
            ],
        },
        {
            text: 'Generating Your Personal Map',
            color: '#39CAB9',
            title: 'Looking for hotspots near you...',
            steps: [
                {
                    finish: 30,
                    timeout: 100,
                },
                {
                    finish: 70,
                    timeout: 50,
                },
                {
                    finish: 100,
                    timeout: 33,
                },
            ],
        },
    ],
};

const stepsUs = [
    {
        type: 'first_screen',
        step: -1,
        mainQuestion: 1,
        progress: 20,
        withoutButton: true,
        noSet: true,
        screens: {
            1: {
                img: firstScreen,
                title: 'Find the best fishing spots near you',
                text: 'Answer a quick quiz to receive your personal hot spots map',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                prevStep: 0,
                analyticsKey: 'type_of_water',
                answers: [
                    {
                        type: 'button',
                        text: 'Fresh',
                        img: fresh,
                        value: 'fresh',
                    },
                    {
                        type: 'button',
                        text: 'Salt',
                        img: salt,
                        value: 'salt',
                    },
                ],
            },
            2: {
                img: firstScreen,
                imgDesc: '*828 top spots in US',
                title: 'Discover new fishing spots nearby tailored to your fishing preferences',
                nextStep: 1,
                prevStep: 0,
                loadingArrays: [
                    {
                        title: 'Loading the test',
                        color: '#0A364A',
                        colorLoader: '#5682F2',
                        steps: [
                            {
                                finish: 30,
                                timeout: 133,
                            },
                            {
                                finish: 70,
                                timeout: 70,
                            },
                            {
                                finish: 100,
                                timeout: 33,
                            },
                        ],
                    },
                ],
            },
            [FIRST_SCREEN_VARIANT_WATER_V1]: {
                img: firstScreen,
                title: 'Your Fishing Map is Almost Ready!',
                text: 'Just answer 3 quick questions to customize it.',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                mainQuestion: 1,
                prevStep: 0,
                progress: 20,
                analyticsKey: 'type_of_water',
                answerType: 'single',
                answers: [
                    {
                        type: 'with_img',
                        text: 'Fresh',
                        img: freshFS,
                        value: 'fresh',
                    },
                    {
                        type: 'with_img',
                        text: 'Salt',
                        img: saltFS,
                        value: 'salt',
                    },
                ],
            },
            [FIRST_SCREEN_VARIANT_WATER_V2]: {
                title: ' Find the best fishing spots near you',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                mainQuestion: 1,
                prevStep: 0,
                progress: 20,
                analyticsKey: 'type_of_water',
                answerType: 'single',
                answers: [
                    {
                        text: 'Fresh',
                        type: 'button',
                        value: 'fresh',
                    },
                    {
                        text: 'Salt',
                        type: 'button',
                        value: 'salt',
                    },
                ],
            },
        },
    },
    {
        type: 'question',
        step: 0,
        analyticsKey: 'type_of_water',
        nextStep: 1,
        mainQuestion: 1,
        progress: 50,
        title: 'Discover Your Perfect Fishing Map',
        text: 'Answer a quick customized quiz to receive your hotspots map and 7-day fishing forecast.',
        desc: 'Select the type of water you usually fish in:',
        answerType: 'single',
        answers: [
            {
                type: 'with_img',
                text: 'Fresh us',
                img: fresh,
                value: 'fresh',
            },
            {
                type: 'with_img',
                text: 'Salt',
                img: salt,
                value: 'salt',
            },
            {
                type: 'radio',
                text: 'Both',
                value: 'both',
                textStyles: '!text-center',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 1,
        nextStep: 2,
        prevStep: 0,
        prevStepAlt: -1,
        progress: 20,
        analyticsKey: 'where_are_you_fishing',
        title: 'Where are you enjoying fishing most of all?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Boat',
                img: boat,
                value: 'boat',
            },
            {
                type: 'with_img',
                text: 'Bank',
                img: off,
                value: 'bank',
            },
            {
                type: 'with_img',
                text: 'Pier or dock',
                img: pier,
                value: 'pier_or_dock',
            },
            {
                type: 'with_img',
                text: 'Wading position',
                img: wading,
                value: 'wading_position',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 2,
        nextStep: 3,
        prevStep: 1,
        progress: 30,
        analyticsKey: 'what_waterbodies',
        title: 'What waterbodies you usually fish at?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Lakes',
                img: lake,
                value: 'lakes',
            },
            {
                type: 'with_img',
                text: 'Rivers',
                img: rivers,
                value: 'rivers',
            },
            {
                type: 'with_img',
                text: 'Ponds',
                img: ponds,
                value: 'ponds',
            },
            {
                type: 'with_img',
                text: 'Creeks',
                img: creeks,
                value: 'creeks',
            },
            {
                type: 'with_img',
                text: 'Reservoirs',
                img: reservioirs,
                value: 'reservoirs',
            },
            {
                type: 'with_img',
                text: 'Swamps',
                img: swamps,
                value: 'swamps',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 3,
        nextStep: 4,
        prevStep: 2,
        progress: 45,
        analyticsKey: 'what_fish',
        title: 'What fish species do you usually target?',
        text: 'This information will help us understand your preferences.',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Largemouth Bass',
                img: larg,
                value: 'largemous_bass',
            },
            {
                type: 'with_img',
                text: 'Crappie',
                img: crappie,
                value: 'crappie',
            },
            {
                type: 'with_img',
                text: 'Bluegill',
                img: bluegill,
                value: 'bluegill',
            },
            {
                type: 'with_img',
                text: 'Smallmouth Bass',
                img: small,
                value: 'smallmouth_bass',
            },
            {
                type: 'with_img',
                text: 'Channel Catfish',
                img: channel,
                value: 'channel_catfish',
            },
            {
                type: 'with_img',
                text: 'Redfish',
                img: red,
                value: 'redfish',
            },
            {
                type: 'with_img',
                text: 'White Bass',
                img: whiteBass,
                value: 'white_bass',
            },
            {
                type: 'with_img',
                text: 'Walleye',
                img: walleye,
                value: 'walleye',
            },
            {
                type: 'with_img',
                text: 'Brown Trout',
                img: brown,
                value: 'brown_trout',
            },
            {
                type: 'with_img',
                text: 'Northern pike',
                img: north,
                value: 'northern_pike',
            },

            {
                type: 'with_img',
                text: 'Common Carp',
                img: commonCarp,
                value: 'common_carp',
            },
            {
                type: 'with_img',
                text: 'Yellow Perch',
                img: yellowPerch,
                value: 'yellow_perch',
            },
            {
                type: 'with_img',
                text: 'Bullhead Catfish',
                img: bullheadCatfish,
                value: 'bullhead_catfish',
            },
            {
                type: 'with_img',
                text: 'Striped bass',
                img: striped,
                value: 'striped_bass',
            },

            {
                type: 'with_img',
                text: 'Muskie',
                img: muskie,
                value: 'muskie',
            },
            {
                type: 'with_img',
                text: 'Panfish',
                img: panfish,
                value: 'panfish',
            },
            {
                type: 'with_img',
                text: 'Rainbow Trout',
                img: rainbowTrout,
                value: 'rainbow_trout',
            },
            {
                type: 'with_img',
                text: 'Sturgeon',
                img: sturgeon,
                value: 'sturgeon',
            },
            {
                type: 'with_img',
                text: 'Freshwater Drum',
                img: freshwaterDrum,
                value: 'freshwater_drum',
            },
            {
                type: 'with_img',
                text: 'Gar',
                img: gar,
                value: 'gar',
            },
            {
                type: 'with_img',
                text: 'Shad',
                img: shad,
                value: 'shad',
            },
            {
                type: 'with_img',
                text: 'Other freshwater fish',
                img: otherFresh,
                imgStyles: 'w-[93px] h-[88px] !min-h-[auto] !min-w-[auto]',
                value: 'other_freshwater_fish',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 4,
        nextStep: 5,
        prevStep: 3,
        progress: 60,
        analyticsKey: 'what_time',
        title: 'What time intervals you usually choose to plan your fishing trip?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'Early morning (from 5 a.m. till 9 a.m.)',
                value: 'early_morning',
            },
            {
                type: 'with_tick',
                text: 'Late morning (from 9 a.m. till 12 p.m.)',
                value: 'late_morning',
            },
            {
                type: 'with_tick',
                text: 'Afternoon (from 12 p.m. till 6 p.m.)',
                value: 'afternoon',
            },
            {
                type: 'with_tick',
                text: 'Late evening (from 6 p.m. till 10 p.m.)',
                value: 'late_evening',
            },
            {
                type: 'with_tick',
                text: 'Night time (from 10 p.m. till 5 a.m.)',
                value: 'night_time',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 5,
        nextStep: 6,
        prevStep: 4,
        progress: 75,
        analyticsKey: 'what_angling_method',
        title: 'What angling method do you usually use?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'Bottom fishing',
                value: 'bottom_fishing',
            },
            {
                type: 'with_tick',
                text: 'Fly-fishing',
                value: 'fly_fishing',
            },
            {
                type: 'with_tick',
                text: 'Spin fishing',
                value: 'spin_fishing',
            },
            {
                type: 'with_tick',
                text: 'Jigging',
                value: 'jigging',
            },
            {
                type: 'with_tick',
                text: 'Trolling',
                value: 'trolling',
            },
            {
                type: 'with_tick',
                text: 'Casting',
                value: 'casting',
            },
            {
                type: 'with_tick',
                text: 'Other method',
                value: 'other_method',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 1,
        step: 6,
        nextStep: 7,
        prevStep: 5,
        progress: 90,
        analyticsKey: 'what_kind_of_lures',
        title: 'What kind of fishing baits/lures do you use most often?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Flies',
                img: flies,
                value: 'flies',
            },
            {
                type: 'with_img',
                text: 'Jigs',
                img: jigs,
                value: 'jigs',
            },
            {
                type: 'with_img',
                text: 'Spinnerbaits & Buzzbaits',
                img: spinner,
                value: 'spinnerbaits_buzzbaits',
            },
            {
                type: 'with_img',
                text: 'Plugs',
                img: plugs,
                value: 'plugs',
            },
            {
                type: 'with_img',
                text: 'Spoons',
                img: spoons,
                value: 'spoons',
            },
            {
                type: 'with_img',
                text: 'Soft bait lures',
                img: soft,
                value: 'soft_bait_lures',
            },
            {
                type: 'with_img',
                text: 'Other lures',
                img: other,
                imgStyles: 'w-[93px] h-[88px] !min-h-[auto] !min-w-[auto]',
                value: 'other_lures',
            },
            {
                type: 'with_img',
                text: 'Insects',
                img: insects,
                value: 'insects',
            },
            {
                type: 'with_img',
                text: 'Minnows',
                img: minnows,
                value: 'minnows',
            },
            {
                type: 'with_img',
                text: 'Maggots',
                img: maggots,
                value: 'maggots',
            },
            {
                type: 'with_img',
                text: 'Worms',
                img: worms,
                value: 'worms',
            },
            {
                type: 'with_img',
                text: 'Other baits',
                img: baits,
                value: 'other_baits',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 1,
        step: 7,
        nextStep: 8,
        prevStep: 6,
        progress: 95,
        analyticsKey: 'in_which_layer_of_water',
        title: 'In which layer of water do you usually catch fish?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'I like to fish in shallow water, around 3-5 feet deep',
                value: '3_5_feet_deep',
            },
            {
                type: 'with_tick',
                text: 'It depends on my current target species, but I usually fish in a 10-15 feet deep water',
                value: '10_15_feet_deep',
            },
            {
                type: 'with_tick',
                text: 'I prefer to fish in deeper water, around 20-30 feet deep',
                value: '20_30_feet_deep',
            },
            {
                type: 'with_tick',
                text: 'I fish in water that is 25+ feet deep',
                value: '25_feet_deep',
            },
        ],
    },

    {
        type: 'screen_1',
        mainQuestion: 2,
        step: 8,
        nextStep: 9,
        progress: 0,
        analyticsKey: 'our_algorithms',
        withOutAnalyticsValue: true,
        title: 'Our algorithms are based on 21 parameters',
        text: "We'll literally show you the best spots to fish in your area based on real-time trends, which means you'll always fish in areas filled with fish, and be spared from long preparations for each trip.",
        help: 'Fishbox™ Team',
        img: screen1,
        buttonText: 'Sounds good',
        isButtonEnabled: true,
    },

    {
        type: 'question',
        step: 9,
        nextStep: 10,
        prevStep: 8,
        mainQuestion: 2,
        progress: 20,
        analyticsKey: 'what_describes_you_better',
        title: 'What describes you better?',
        text: 'This information will help us understand your experience.',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'I’m a beginner',
                value: 'beginner',
            },
            {
                type: 'radio',
                text: 'I’m an amateur',
                value: 'amateur',
            },
            {
                type: 'radio',
                text: 'I’m an experienced angler',
                value: 'experienced',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 2,
        step: 10,
        nextStep: 11,
        prevStep: 9,
        progress: 40,
        analyticsKey: 'who_do_you_go_fishing_with',
        title: 'Who do you usually go fishing with?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'I fish alone',
                value: 'alone',
            },
            {
                type: 'with_tick',
                text: 'With family members',
                value: 'family',
            },
            {
                type: 'with_tick',
                text: 'With friends',
                value: 'friends',
            },
            {
                type: 'with_tick',
                text: 'With a professional guide',
                value: 'professional_guide',
            },
            {
                type: 'with_tick',
                text: 'With coworkers or business associates',
                value: 'business_associates',
            },
        ],
    },

    {
        type: 'question',
        step: 11,
        nextStep: 12,
        prevStep: 10,
        mainQuestion: 2,
        progress: 60,
        analyticsKey: 'do_you_agree',
        title: 'Do you agree with the following:',
        desc: "“I don't like crowded fishing spots”",
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'Completely agree',
                value: 'completely_agree',
            },
            {
                type: 'radio',
                text: 'Agree',
                value: 'agree',
            },
            {
                type: 'radio',
                text: 'Neutral',
                value: 'neutral',
            },
            {
                type: 'radio',
                text: 'Disagree',
                value: 'disagree',
            },
            {
                type: 'radio',
                text: 'Totally disagree',
                value: 'totally_disagree',
            },
        ],
    },

    {
        type: 'question',
        step: 12,
        nextStep: 13,
        prevStep: 11,
        mainQuestion: 2,
        progress: 80,
        analyticsKey: 'how_often_disappointed',
        title: 'How often are you disappointed with your fishing trip?',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'Often',
                value: 'often',
            },
            {
                type: 'radio',
                text: 'Sometimes',
                value: 'sometimes',
            },
            {
                type: 'radio',
                text: 'Rarely',
                value: 'rarely',
            },
            {
                type: 'radio',
                text: 'Never',
                value: 'never',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 2,
        step: 13,
        nextStep: 14,
        prevStep: 12,
        progress: 90,
        analyticsKey: 'what_difficulties',
        title: 'What difficulties are you experiencing most often?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: "I don't know when fish is biting",
                value: 'fish_is_biting',
            },
            {
                type: 'with_tick',
                text: " I don't know spots where fish are active",
                value: 'spots',
            },
            {
                type: 'with_tick',
                text: "I don't know what fish are habitat in a particular spot",
                value: 'fish_are_habitat',
            },
            {
                type: 'with_tick',
                text: " I don't know what gear I need",
                value: 'what_gear',
            },
            {
                type: 'with_tick',
                text: "I don't know spot's rules and regulations",
                value: 'regulations',
            },
            {
                type: 'with_tick',
                text: 'Bad weather',
                value: 'bad_weather',
            },
            {
                type: 'with_tick',
                text: 'Fishing gear is a way too expensive',
                value: 'too_expensive',
            },
            {
                type: 'with_tick',
                text: "I don't have enough time 😭",
                value: 'enough_time',
            },
        ],
    },

    {
        type: 'screen_2',
        mainQuestion: 3,
        step: 14,
        nextStep: 15,
        progress: 0,
        img: screen2,
        analyticsKey: 'you_are_not_alone',
        withOutAnalyticsValue: true,
        buttonText: 'Continue',
        isButtonEnabled: true,
    },

    {
        type: 'state',
        mainQuestion: 3,
        step: 15,
        nextStep: 16,
        prevStep: 14,
        progress: 30,
        analyticsKey: 'your_state',
        buttonText: 'Continue',
        titleText: 'Choose your state:',
    },

    {
        type: 'city',
        mainQuestion: 3,
        step: 16,
        nextStep: 17,
        prevStep: 15,
        progress: 60,
        analyticsKey: 'your_city',
        img: city,
        buttonText: 'Continue',
    },

    {
        type: 'question',
        step: 17,
        nextStep: 18,
        prevStep: 16,
        mainQuestion: 3,
        progress: 90,
        analyticsKey: 'how_far',
        title: 'How far from the city are you ready to go on your fishing trip?',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'Up to 25 miles',
                value: '25_miles',
            },
            {
                type: 'radio',
                text: 'Up to 50 miles',
                value: '50_miles',
            },
            {
                type: 'radio',
                text: 'Up to 100 miles',
                value: '100_miles',
            },
            {
                type: 'radio',
                text: 'Up to 200 miles',
                value: '200_miles',
            },
            {
                type: 'radio',
                text: 'Up to 200+ miles',
                value: 'more_200_miles',
            },
        ],
    },

    {
        type: 'loading',
        step: 18,
        nextStep: 19,
        withoutButton: true,
        data: {
            eventFillName: 'analyzing answers',
            nextRoute: '/onboarding/PersonalizationD2',
            title: 'Analyzing your answers...',
            subtitle: 'Trusted by over 149,569 people',
            loadingArrays: [
                {
                    text: 'Analysis of water bodies nearby',
                    title: 'Analyzing your answers...',
                    color: '#5682F2',
                    steps: [
                        {
                            finish: 30,
                            timeout: 133,
                        },
                        {
                            finish: 70,
                            timeout: 70,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
                {
                    text: 'Calculation of biting activity',
                    color: '#4ABCE1',
                    title: 'Analyzing weather parameters...',
                    steps: [
                        {
                            finish: 30,
                            timeout: 133,
                        },
                        {
                            finish: 70,
                            timeout: 50,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
                {
                    text: 'Generating Your Personal Map',
                    color: '#39CAB9',
                    title: 'Looking for hotspots near you...',
                    steps: [
                        {
                            finish: 30,
                            timeout: 100,
                        },
                        {
                            finish: 70,
                            timeout: 50,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
            ],
            loadingArraysSecond: [
                {
                    text: 'Generating Your Personal Map',
                    color: '#39CAB9',
                    title: 'Looking for hotspots near you...',
                    titles: [
                        {
                            text: 'Analyzing weather parameters...',
                            percent: 0,
                        },
                        {
                            text: 'Looking for hotspots near you...',
                            percent: 64,
                        },
                        {
                            text: 'Your personal map is complete',
                            percent: 98,
                        },
                    ],

                    steps: [
                        {
                            finish: 30,
                            timeout: 200,
                        },
                        {
                            finish: 70,
                            timeout: 100,
                        },
                        {
                            finish: 100,
                            timeout: 66,
                        },
                    ],
                },
            ],
            loadingArraysThird: TWEAKS_LOADERS.analyze_loaders,
            loadingTextArray: [
                {
                    startPercent: 0,
                    finishPercent: 50,
                    text: 'Connecting to database',
                },
                {
                    startPercent: 51,
                    finishPercent: 99,
                    text: 'Preliminary processing',
                },
            ],
        },
    },

    {
        type: 'email',
        step: 19,
        nextStep: 20,
        analyticsKey: 'email',
        withoutButton: true,
        title: 'Where should we send your personalized hotspots & fishing map',
        desc: 'Enter your email to get started',
        buttonText: 'See my map',
    },

    {
        type: 'landing',
        step: 20,
    },
];

const stepsAu = [
    {
        type: 'first_screen',
        step: -1,
        mainQuestion: 1,
        progress: 20,
        withoutButton: true,
        noSet: true,
        screens: {
            1: {
                img: firstScreen,
                title: 'Find the best fishing spots near you',
                text: 'Answer a quick quiz to receive your personal hot spots map',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                prevStep: 0,
                analyticsKey: 'type_of_water',
                answers: [
                    {
                        type: 'button',
                        text: 'Fresh',
                        img: fresh,
                        value: 'fresh',
                    },
                    {
                        type: 'button',
                        text: 'Salt',
                        img: salt,
                        value: 'salt',
                    },
                ],
            },
            2: {
                img: firstScreen,
                imgDesc: '*828 top spots in US',
                title: 'Discover new fishing spots nearby tailored to your fishing preferences',
                nextStep: 1,
                prevStep: 0,
                loadingArrays: [
                    {
                        title: 'Loading the test',
                        color: '#0A364A',
                        colorLoader: '#5682F2',
                        steps: [
                            {
                                finish: 30,
                                timeout: 133,
                            },
                            {
                                finish: 70,
                                timeout: 70,
                            },
                            {
                                finish: 100,
                                timeout: 33,
                            },
                        ],
                    },
                ],
            },
            [FIRST_SCREEN_VARIANT_WATER_V1]: {
                img: firstScreen,
                title: 'Your Fishing Map is Almost Ready!',
                text: 'Just answer 3 quick questions to customize it.',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                mainQuestion: 1,
                prevStep: 0,
                progress: 20,
                analyticsKey: 'type_of_water',
                answerType: 'single',
                answers: [
                    {
                        type: 'with_img',
                        text: 'Fresh',
                        img: freshFS,
                        value: 'fresh',
                    },
                    {
                        type: 'with_img',
                        text: 'Salt',
                        img: saltFS,
                        value: 'salt',
                    },
                ],
            },
            [FIRST_SCREEN_VARIANT_WATER_V2]: {
                title: ' Find the best fishing spots near you',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                mainQuestion: 1,
                prevStep: 0,
                progress: 20,
                analyticsKey: 'type_of_water',
                answerType: 'single',
                answers: [
                    {
                        text: 'Fresh',
                        type: 'button',
                        value: 'fresh',
                    },
                    {
                        text: 'Salt',
                        type: 'button',
                        value: 'salt',
                    },
                ],
            },
        },
    },
    {
        type: 'question',
        step: 0,
        analyticsKey: 'type_of_water',
        nextStep: 1,
        mainQuestion: 1,
        progress: 0,
        title: 'Discover Your Perfect Fishing Map',
        text: 'Answer a quick customized quiz to receive your hotspots map and 7-day fishing forecast.',
        desc: 'Select the type of water you usually fish in:',
        answerType: 'single',
        answers: [
            {
                type: 'with_img',
                text: 'Fresh',
                img: fresh,
                value: 'fresh',
            },
            {
                type: 'with_img',
                text: 'Salt',
                img: salt,
                value: 'salt',
            },
            {
                type: 'radio',
                text: 'Both',
                value: 'both',
                textStyles: '!text-center',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 1,
        nextStep: 2,
        prevStep: 0,
        prevStepAlt: -1,
        progress: 20,
        analyticsKey: 'where_are_you_fishing',
        title: 'Where are you enjoying fishing most of all?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Boat or Kayak',
                img: boat,
                value: 'boat_or_kayak',
            },
            {
                type: 'with_img',
                text: 'Shore or Bank',
                img: off,
                value: 'shore_or_bank',
            },
            {
                type: 'with_img',
                text: 'Pier or Dock',
                img: pier,
                value: 'pier_or_dock',
            },
            {
                type: 'with_img',
                text: 'In water',
                img: wading,
                value: 'in_water',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 2,
        nextStep: 3,
        prevStep: 1,
        progress: 30,
        analyticsKey: 'what_waterbodies',
        title: 'What waterbodies you usually fish at?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Rivers',
                img: rivers,
                value: 'rivers',
            },
            {
                type: 'with_img',
                text: 'Lakes',
                img: lake,
                value: 'lakes',
            },
            {
                type: 'with_img',
                text: 'Creeks',
                img: creeks,
                value: 'creeks',
            },
            {
                type: 'with_img',
                text: 'Reservoirs',
                img: reservioirsAu,
                value: 'reservoirs',
            },
            {
                type: 'with_img',
                text: 'Estuaries',
                img: estuariesAu,
                value: 'estuaries',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 3,
        nextStep: 4,
        prevStep: 2,
        progress: 45,
        analyticsKey: 'what_fish',
        title: 'What fish species do you usually target?',
        text: 'This information will help us understand your preferences.',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Barramundi',
                img: baramundi,
                value: 'barramundi',
            },

            {
                type: 'with_img',
                text: 'Murray cod',
                img: murrayCodImg,
                value: 'murray_cod',
            },

            {
                type: 'with_img',
                text: 'Golden perch',
                img: goldenPerchImg,
                value: 'golden_perch',
            },

            {
                type: 'with_img',
                text: 'Dusky flathead',
                img: dusky,
                value: 'dusky_flathead',
            },

            {
                type: 'with_img',
                text: 'Australian bass',
                img: ausBass,
                value: 'australian_bass',
            },
            {
                type: 'with_img',
                text: 'Rainbow trout',
                img: rainbowTrout,
                value: 'rainbow_trout',
            },
            {
                type: 'with_img',
                text: 'Brown trout',
                img: brown,
                value: 'brown_trout',
            },
            {
                type: 'with_img',
                text: 'Surf bream',
                img: bream,
                value: 'surf_bream',
            },
            {
                type: 'with_img',
                text: 'Silver perch',
                img: silverPerchImg,
                value: 'silver_perch',
            },
            {
                type: 'with_img',
                text: 'Saratoga',
                img: saratogaImg,
                value: 'saratoga',
            },
            {
                type: 'with_img',
                text: 'Other freshwater fish',
                img: otherFresh,
                imgStyles: 'w-[93px] h-[88px] !min-h-[auto] !min-w-[auto]',
                value: 'other_freshwater_fish',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 4,
        nextStep: 5,
        prevStep: 3,
        progress: 60,
        analyticsKey: 'what_time',
        title: 'What time intervals you usually choose to plan your fishing trip?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'Early morning (from 5 a.m. till 9 a.m.)',
                value: 'early_morning',
            },
            {
                type: 'with_tick',
                text: 'Late morning (from 9 a.m. till 12 p.m.)',
                value: 'late_morning',
            },
            {
                type: 'with_tick',
                text: 'Afternoon (from 12 p.m. till 6 p.m.)',
                value: 'afternoon',
            },
            {
                type: 'with_tick',
                text: 'Late evening (from 6 p.m. till 10 p.m.)',
                value: 'late_evening',
            },
            {
                type: 'with_tick',
                text: 'Night time (from 10 p.m. till 5 a.m.)',
                value: 'night_time',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 5,
        nextStep: 6,
        prevStep: 4,
        progress: 75,
        analyticsKey: 'what_angling_method',
        title: 'What angling method do you usually use?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'Bottom fishing',
                value: 'bottom_fishing',
            },
            {
                type: 'with_tick',
                text: 'Casting',
                value: 'casting',
            },
            {
                type: 'with_tick',
                text: 'Spin fishing',
                value: 'spin_fishing',
            },
            {
                type: 'with_tick',
                text: 'Jigging',
                value: 'jigging',
            },
            {
                type: 'with_tick',
                text: 'Trolling',
                value: 'trolling',
            },
            {
                type: 'with_tick',
                text: 'Fly fishing',
                value: 'fly_fishing',
            },
            {
                type: 'with_tick',
                text: 'Other method',
                value: 'other_method',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 1,
        step: 6,
        nextStep: 7,
        prevStep: 5,
        progress: 90,
        analyticsKey: 'what_kind_of_lures',
        title: 'What kind of fishing baits/lures do you use most often?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Flies',
                img: flies,
                value: 'flies',
            },
            {
                type: 'with_img',
                text: 'Jigs',
                img: jigs,
                value: 'jigs',
            },
            {
                type: 'with_img',
                text: 'Spoons',
                img: spoons,
                value: 'spoons',
            },
            {
                type: 'with_img',
                text: 'Spinnerbaits',
                img: spinner,
                value: 'spinnerbaits',
            },
            {
                type: 'with_img',
                text: 'Soft plastics',
                img: soft,
                value: 'soft_plastics',
            },
            {
                type: 'with_img',
                text: 'Crankbaits',
                img: crankbaitsAu,
                value: 'crankbaits',
            },
            {
                type: 'with_img',
                text: 'Poppers',
                img: poppersAu,
                value: 'poppers',
            },
            {
                type: 'with_img',
                text: 'Other lures',
                img: other,
                imgStyles: 'w-[93px] h-[88px] !min-h-[auto] !min-w-[auto]',
                value: 'other_lures',
            },
            {
                type: 'with_img',
                text: 'Worms',
                img: worms,
                value: 'worms',
            },
            {
                type: 'with_img',
                text: 'Squid',
                img: squidAu,
                value: 'squid',
            },
            {
                type: 'with_img',
                text: 'Cut bait',
                img: cutBait,
                value: 'cut_bait',
            },

            {
                type: 'with_img',
                text: 'Fish bait',
                img: minnows,
                value: 'fish_bait',
            },
            {
                type: 'with_img',
                text: 'Yabbies',
                img: yabbies,
                value: 'yabbies',
            },
            {
                type: 'with_img',
                text: 'Corn or dough',
                img: cornOrDough,
                value: 'corn_or_dough',
            },
            {
                type: 'with_img',
                text: 'Other baits',
                img: otherAu,
                value: 'other_baits',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 1,
        step: 7,
        nextStep: 8,
        prevStep: 6,
        progress: 95,
        analyticsKey: 'in_which_layer_of_water',
        title: 'In which layer of water do you usually catch fish?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'I like to fish in shallow water, around 1-2 meters deep',
                value: '1_2_feet_deep',
            },
            {
                type: 'with_tick',
                text: 'It depends on my current target species, but I usually fish in a 3-5 meters deep water',
                value: '3_5_feet_deep',
            },
            {
                type: 'with_tick',
                text: 'I prefer to fish in deeper water, around 6-9 meters deep',
                value: '6_9_feet_deep',
            },
            {
                type: 'with_tick',
                text: 'I fish in water that is 10+ meters deep',
                value: '10_feet_deep',
            },
        ],
    },

    {
        type: 'screen_1',
        mainQuestion: 2,
        step: 8,
        nextStep: 9,
        progress: 0,
        analyticsKey: 'our_algorithms',
        withOutAnalyticsValue: true,
        title: 'Our algorithms are based on 21 parameters',
        text: "We'll literally show you the best spots to fish in your area based on real-time trends, which means you'll always fish in areas filled with fish, and be spared from long preparations for each trip.",
        help: 'Fishbox™ Team',
        img: screen1,
        buttonText: 'Sounds good',
        isButtonEnabled: true,
    },

    {
        type: 'question',
        step: 9,
        nextStep: 10,
        prevStep: 8,
        mainQuestion: 2,
        progress: 20,
        analyticsKey: 'what_describes_you_better',
        title: 'What describes you better?',
        text: 'This information will help us understand your experience.',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'I’m a beginner',
                value: 'beginner',
            },
            {
                type: 'radio',
                text: 'I’m an amateur',
                value: 'amateur',
            },
            {
                type: 'radio',
                text: 'I’m an experienced angler',
                value: 'experienced',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 2,
        step: 10,
        nextStep: 11,
        prevStep: 9,
        progress: 40,
        analyticsKey: 'who_do_you_go_fishing_with',
        title: 'Who do you usually go fishing with?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'I fish alone',
                value: 'alone',
            },
            {
                type: 'with_tick',
                text: 'With family members',
                value: 'family',
            },
            {
                type: 'with_tick',
                text: 'With friends',
                value: 'friends',
            },
            {
                type: 'with_tick',
                text: 'With a professional guide',
                value: 'professional_guide',
            },
            {
                type: 'with_tick',
                text: 'With coworkers or business associates',
                value: 'business_associates',
            },
        ],
    },

    {
        type: 'question',
        step: 11,
        nextStep: 12,
        prevStep: 10,
        mainQuestion: 2,
        progress: 60,
        analyticsKey: 'do_you_agree',
        title: 'Do you agree with the following:',
        desc: "“I don't like crowded fishing spots”",
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'Completely agree',
                value: 'completely_agree',
            },
            {
                type: 'radio',
                text: 'Agree',
                value: 'agree',
            },
            {
                type: 'radio',
                text: 'Neutral',
                value: 'neutral',
            },
            {
                type: 'radio',
                text: 'Disagree',
                value: 'disagree',
            },
            {
                type: 'radio',
                text: 'Totally disagree',
                value: 'totally_disagree',
            },
        ],
    },

    {
        type: 'question',
        step: 12,
        nextStep: 13,
        prevStep: 11,
        mainQuestion: 2,
        progress: 80,
        analyticsKey: 'how_often_disappointed',
        title: 'How often are you disappointed with your fishing trip?',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'Often',
                value: 'often',
            },
            {
                type: 'radio',
                text: 'Sometimes',
                value: 'sometimes',
            },
            {
                type: 'radio',
                text: 'Rarely',
                value: 'rarely',
            },
            {
                type: 'radio',
                text: 'Never',
                value: 'never',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 2,
        step: 13,
        nextStep: 14,
        prevStep: 12,
        progress: 90,
        analyticsKey: 'what_difficulties',
        title: 'What difficulties are you experiencing most often?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: "I don't know when fish is biting",
                value: 'fish_is_biting',
            },
            {
                type: 'with_tick',
                text: " I don't know spots where fish are active",
                value: 'spots',
            },
            {
                type: 'with_tick',
                text: "I don't know what fish are habitat in a particular spot",
                value: 'fish_are_habitat',
            },
            {
                type: 'with_tick',
                text: " I don't know what gear I need",
                value: 'what_gear',
            },
            {
                type: 'with_tick',
                text: "I don't know spot's rules and regulations",
                value: 'regulations',
            },
            {
                type: 'with_tick',
                text: 'Bad weather',
                value: 'bad_weather',
            },
            {
                type: 'with_tick',
                text: 'Fishing gear is a way too expensive',
                value: 'too_expensive',
            },
            {
                type: 'with_tick',
                text: "I don't have enough time 😭",
                value: 'enough_time',
            },
        ],
    },

    {
        type: 'screen_2',
        mainQuestion: 3,
        step: 14,
        nextStep: 15,
        progress: 0,
        img: screen2,
        analyticsKey: 'you_are_not_alone',
        withOutAnalyticsValue: true,
        buttonText: 'Continue',
        isButtonEnabled: true,
    },

    {
        type: 'state',
        mainQuestion: 3,
        step: 15,
        nextStep: 16,
        prevStep: 14,
        progress: 30,
        analyticsKey: 'your_state',
        buttonText: 'Continue',
        titleText: 'Choose your state or territory:',
    },

    {
        type: 'city',
        mainQuestion: 3,
        step: 16,
        nextStep: 17,
        prevStep: 15,
        progress: 60,
        analyticsKey: 'your_city',
        img: city,
        buttonText: 'Continue',
    },

    {
        type: 'question',
        step: 17,
        nextStep: 18,
        prevStep: 16,
        mainQuestion: 3,
        progress: 90,
        analyticsKey: 'how_far',
        title: 'How far from the city are you ready to go on your fishing trip?',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'Up to 40 kilometers',
                value: '40_kilometers',
            },
            {
                type: 'radio',
                text: 'Up to 80 kilometers',
                value: '80_kilometers',
            },
            {
                type: 'radio',
                text: 'Up to 160 kilometers',
                value: '160_kilometers',
            },
            {
                type: 'radio',
                text: 'Up to 320 kilometers',
                value: '320_kilometers',
            },
            {
                type: 'radio',
                text: 'Up to 320+ kilometers',
                value: 'more_320_kilometers',
            },
        ],
    },

    {
        type: 'loading',
        step: 18,
        nextStep: 19,
        withoutButton: true,
        data: {
            eventFillName: 'analyzing answers',
            nextRoute: '/onboarding/PersonalizationD2',
            title: 'Analyzing your answers...',
            subtitle: 'Trusted by over 149,569 people',
            loadingArrays: [
                {
                    text: 'Analysis of water bodies nearby',
                    title: 'Analyzing your answers...',
                    color: '#5682F2',
                    steps: [
                        {
                            finish: 30,
                            timeout: 133,
                        },
                        {
                            finish: 70,
                            timeout: 70,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
                {
                    text: 'Calculation of biting activity',
                    color: '#4ABCE1',
                    title: 'Analyzing weather parameters...',
                    steps: [
                        {
                            finish: 30,
                            timeout: 133,
                        },
                        {
                            finish: 70,
                            timeout: 50,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
                {
                    text: 'Generating Your Personal Map',
                    color: '#39CAB9',
                    title: 'Looking for hotspots near you...',
                    steps: [
                        {
                            finish: 30,
                            timeout: 100,
                        },
                        {
                            finish: 70,
                            timeout: 50,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
            ],
            loadingArraysSecond: [
                {
                    text: 'Generating Your Personal Map',
                    color: '#39CAB9',
                    title: 'Looking for hotspots near you...',
                    titles: [
                        {
                            text: 'Analyzing weather parameters...',
                            percent: 0,
                        },
                        {
                            text: 'Looking for hotspots near you...',
                            percent: 64,
                        },
                        {
                            text: 'Your personal map is complete',
                            percent: 98,
                        },
                    ],

                    steps: [
                        {
                            finish: 30,
                            timeout: 200,
                        },
                        {
                            finish: 70,
                            timeout: 100,
                        },
                        {
                            finish: 100,
                            timeout: 66,
                        },
                    ],
                },
            ],
            loadingArraysThird: TWEAKS_LOADERS.analyze_loaders,
            loadingTextArray: [
                {
                    startPercent: 0,
                    finishPercent: 50,
                    text: 'Connecting to database',
                },
                {
                    startPercent: 51,
                    finishPercent: 99,
                    text: 'Preliminary processing',
                },
            ],
        },
    },

    {
        type: 'email',
        step: 19,
        nextStep: 20,
        analyticsKey: 'email',
        withoutButton: true,
        title: 'Where should we send your personalized hotspots & fishing map',
        desc: 'Enter your email to get started',
        buttonText: 'See my map',
    },

    {
        type: 'landing',
        step: 20,
    },
];

const stepsAuSalt = [
    {
        type: 'first_screen',
        mainQuestion: 1,
        progress: 20,
        step: -1,
        withoutButton: true,
        noSet: true,
        screens: {
            1: {
                img: firstScreen,
                title: 'Find the best fishing spots near you',
                text: 'Answer a quick quiz to receive your personal hot spots map',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                prevStep: 0,
                analyticsKey: 'type_of_water',
                answers: [
                    {
                        type: 'button',
                        text: 'Fresh',
                        img: fresh,
                        value: 'fresh',
                    },
                    {
                        type: 'button',
                        text: 'Salt',
                        img: salt,
                        value: 'salt',
                    },
                ],
            },
            2: {
                img: firstScreen,
                imgDesc: '*828 top spots in US',
                title: 'Discover new fishing spots nearby tailored to your fishing preferences',
                nextStep: 1,
                prevStep: 0,
                loadingArrays: [
                    {
                        title: 'Loading the test',
                        color: '#0A364A',
                        colorLoader: '#5682F2',
                        steps: [
                            {
                                finish: 30,
                                timeout: 133,
                            },
                            {
                                finish: 70,
                                timeout: 70,
                            },
                            {
                                finish: 100,
                                timeout: 33,
                            },
                        ],
                    },
                ],
            },
            [FIRST_SCREEN_VARIANT_WATER_V1]: {
                img: firstScreen,
                title: 'Your Fishing Map is Almost Ready!',
                text: 'Just answer 3 quick questions to customize it.',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                mainQuestion: 1,
                prevStep: 0,
                progress: 20,
                analyticsKey: 'type_of_water',
                answerType: 'single',
                answers: [
                    {
                        type: 'with_img',
                        text: 'Fresh',
                        img: freshFS,
                        value: 'fresh',
                    },
                    {
                        type: 'with_img',
                        text: 'Salt',
                        img: saltFS,
                        value: 'salt',
                    },
                ],
            },
            [FIRST_SCREEN_VARIANT_WATER_V2]: {
                title: ' Find the best fishing spots near you',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                mainQuestion: 1,
                prevStep: 0,
                progress: 20,
                analyticsKey: 'type_of_water',
                answerType: 'single',
                answers: [
                    {
                        text: 'Fresh',
                        type: 'button',
                        value: 'fresh',
                    },
                    {
                        text: 'Salt',
                        type: 'button',
                        value: 'salt',
                    },
                ],
            },
        },
    },
    {
        type: 'question',
        step: 0,
        analyticsKey: 'type_of_water',
        nextStep: 1,
        mainQuestion: 1,
        progress: 0,
        title: 'Discover Your Perfect Fishing Map',
        text: 'Answer a quick customized quiz to receive your hotspots map and 7-day fishing forecast.',
        desc: 'Select the type of water you usually fish in:',
        answerType: 'single',
        answers: [
            {
                type: 'with_img',
                text: 'Fresh',
                img: fresh,
                value: 'fresh',
            },
            {
                type: 'with_img',
                text: 'Salt',
                img: salt,
                value: 'salt',
            },
            {
                type: 'radio',
                text: 'Both',
                value: 'both',
                textStyles: '!text-center',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 1,
        nextStep: 2,
        prevStep: 0,
        prevStepAlt: -1,
        progress: 20,
        analyticsKey: 'where_are_you_fishing',
        title: 'Where are you enjoying fishing most of all?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Boat or Kayak',
                img: boat,
                value: 'boat_or_kayak',
            },
            {
                type: 'with_img',
                text: 'Shore or Bank',
                img: off,
                value: 'shore_or_bank',
            },
            {
                type: 'with_img',
                text: 'Pier or Dock',
                img: pier,
                value: 'pier_or_dock',
            },
            {
                type: 'with_img',
                text: 'In water',
                img: wading,
                value: 'in_water',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 2,
        nextStep: 3,
        prevStep: 1,
        progress: 30,
        analyticsKey: 'what_waterbodies',
        title: 'What waterbodies you usually fish at?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Ocean',
                img: ocean,
                value: 'ocean',
            },
            {
                type: 'with_img',
                text: 'Lakes',
                img: lake,
                value: 'lakes',
            },
            {
                type: 'with_img',
                text: 'Beaches and Surf',
                img: beachesAu,
                value: 'beaches_and_surf',
            },
            {
                type: 'with_img',
                text: 'Estuaries',
                img: estuariesAu,
                value: 'estuaries',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 3,
        nextStep: 4,
        prevStep: 2,
        progress: 45,
        analyticsKey: 'what_fish',
        title: 'What fish species do you usually target?',
        text: 'This information will help us understand your preferences.',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Australasian snapper',
                img: austrSnapper,
                value: 'australasian_snapper',
            },
            {
                type: 'with_img',
                text: 'Yellowtail kingfish',
                img: yellowTailKingfish,
                value: 'yellowtail_kingfish',
            },
            {
                type: 'with_img',
                text: 'Australian salmon',
                img: ausSalmon,
                value: 'australian_salmon',
            },
            {
                type: 'with_img',
                text: 'Tailor',
                img: tailor,
                value: 'tailor',
            },
            {
                type: 'with_img',
                text: 'Black bream',
                img: blackBream,
                value: 'black_bream',
            },
            {
                type: 'with_img',
                text: 'Sand whiting',
                img: sandWhiting,
                value: 'sand_whiting',
            },
            {
                type: 'with_img',
                text: 'Mulloway',
                img: mulloway,
                value: 'mulloway',
            },
            {
                type: 'with_img',
                text: 'Yellowfin tuna',
                img: yellowfinTunaAu,
                value: 'yellowfin_tuna_au',
            },
            {
                type: 'with_img',
                text: 'Southern bluefin tuna',
                img: southBluefinTuna,
                value: 'southern_bluefin_tuna',
            },
            {
                type: 'with_img',
                text: 'Yellowfin bream',
                img: yellowfinBream,
                value: 'yellowfin_bream',
            },
            {
                type: 'with_img',
                text: 'Black marlin',
                img: blackMarlin,
                value: 'black_marlin',
            },
            {
                type: 'with_img',
                text: 'Striped marlin',
                img: stripedMarlin,
                value: 'striped_marlin',
            },
            {
                type: 'with_img',
                text: 'Rainbow trout',
                img: rainbowTrout,
                value: 'rainbow_trout',
            },
            {
                type: 'with_img',
                text: 'Brown trout',
                img: brown,
                value: 'brown_trout',
            },

            {
                type: 'with_img',
                text: 'Other saltwater fish',
                img: otherSalt,
                imgStyles: 'w-[93px] h-[88px] !min-h-[auto] !min-w-[auto]',
                value: 'other_saltwater_fish',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 4,
        nextStep: 5,
        prevStep: 3,
        progress: 60,
        analyticsKey: 'what_time',
        title: 'What time intervals you usually choose to plan your fishing trip?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'Early morning (from 5 a.m. till 9 a.m.)',
                value: 'early_morning',
            },
            {
                type: 'with_tick',
                text: 'Late morning (from 9 a.m. till 12 p.m.)',
                value: 'late_morning',
            },
            {
                type: 'with_tick',
                text: 'Afternoon (from 12 p.m. till 6 p.m.)',
                value: 'afternoon',
            },
            {
                type: 'with_tick',
                text: 'Late evening (from 6 p.m. till 10 p.m.)',
                value: 'late_evening',
            },
            {
                type: 'with_tick',
                text: 'Night time (from 10 p.m. till 5 a.m.)',
                value: 'night_time',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 5,
        nextStep: 6,
        prevStep: 4,
        progress: 75,
        analyticsKey: 'what_angling_method',
        title: 'What angling method do you usually use?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'Surfcasting',
                value: 'surfcasting',
            },
            {
                type: 'with_tick',
                text: 'Bottom fishing',
                value: 'bottom_fishing',
            },
            {
                type: 'with_tick',
                text: 'Fly fishing',
                value: 'fly_fishing',
            },
            {
                type: 'with_tick',
                text: 'Casting',
                value: 'casting',
            },
            {
                type: 'with_tick',
                text: 'Spin fishing',
                value: 'spin_fishing',
            },
            {
                type: 'with_tick',
                text: 'Jigging',
                value: 'jigging',
            },
            {
                type: 'with_tick',
                text: 'Trolling',
                value: 'trolling',
            },

            {
                type: 'with_tick',
                text: 'Other method',
                value: 'other_method',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 1,
        step: 6,
        nextStep: 7,
        prevStep: 5,
        progress: 90,
        analyticsKey: 'what_kind_of_lures',
        title: 'What kind of fishing baits/lures do you use most often?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Flies',
                img: flies,
                value: 'flies',
            },
            {
                type: 'with_img',
                text: 'Jigs',
                img: jigs,
                value: 'jigs',
            },
            {
                type: 'with_img',
                text: 'Spoons',
                img: spoons,
                value: 'spoons',
            },
            {
                type: 'with_img',
                text: 'Spinnerbaits',
                img: spinner,
                value: 'spinnerbaits',
            },
            {
                type: 'with_img',
                text: 'Soft plastics',
                img: soft,
                value: 'soft_plastics',
            },
            {
                type: 'with_img',
                text: 'Crankbaits',
                img: crankbaitsAu,
                value: 'crankbaits',
            },
            {
                type: 'with_img',
                text: 'Poppers',
                img: poppersAu,
                value: 'poppers',
            },
            {
                type: 'with_img',
                text: 'Other lures',
                img: other,
                imgStyles: 'w-[93px] h-[88px] !min-h-[auto] !min-w-[auto]',
                value: 'other_lures',
            },
            {
                type: 'with_img',
                text: 'Worms',
                img: worms,
                value: 'worms',
            },
            {
                type: 'with_img',
                text: 'Squid',
                img: squidAu,
                value: 'squid',
            },
            {
                type: 'with_img',
                text: 'Cut bait',
                img: cutBait,
                value: 'cut_bait',
            },

            {
                type: 'with_img',
                text: 'Fish bait',
                img: minnows,
                value: 'fish_bait',
            },
            {
                type: 'with_img',
                text: 'Yabbies',
                img: yabbies,
                value: 'yabbies',
            },
            {
                type: 'with_img',
                text: 'Corn or dough',
                img: cornOrDough,
                value: 'corn_or_dough',
            },
            {
                type: 'with_img',
                text: 'Other baits',
                img: otherAu,
                value: 'other_baits',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 1,
        step: 7,
        nextStep: 8,
        prevStep: 6,
        progress: 95,
        analyticsKey: 'in_which_layer_of_water',
        title: 'In which layer of water do you usually catch fish?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'I like to fish in shallow water, around 1-2 meters deep',
                value: '1_2_feet_deep',
            },
            {
                type: 'with_tick',
                text: 'It depends on my current target species, but I usually fish in a 3-5 meters deep water',
                value: '3_5_feet_deep',
            },
            {
                type: 'with_tick',
                text: 'I prefer to fish in deeper water, around 6-9 meters deep',
                value: '6_9_feet_deep',
            },
            {
                type: 'with_tick',
                text: 'I fish in water that is 10+ meters deep',
                value: '10_feet_deep',
            },
        ],
    },

    {
        type: 'screen_1',
        mainQuestion: 2,
        step: 8,
        nextStep: 9,
        progress: 0,
        analyticsKey: 'our_algorithms',
        withOutAnalyticsValue: true,
        title: 'Our algorithms are based on 21 parameters',
        text: "We'll literally show you the best spots to fish in your area based on real-time trends, which means you'll always fish in areas filled with fish, and be spared from long preparations for each trip.",
        help: 'Fishbox™ Team',
        img: screen1,
        buttonText: 'Sounds good',
        isButtonEnabled: true,
    },

    {
        type: 'question',
        step: 9,
        nextStep: 10,
        prevStep: 8,
        mainQuestion: 2,
        progress: 20,
        analyticsKey: 'what_describes_you_better',
        title: 'What describes you better?',
        text: 'This information will help us understand your experience.',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'I’m a beginner',
                value: 'beginner',
            },
            {
                type: 'radio',
                text: 'I’m an amateur',
                value: 'amateur',
            },
            {
                type: 'radio',
                text: 'I’m an experienced angler',
                value: 'experienced',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 2,
        step: 10,
        nextStep: 11,
        prevStep: 9,
        progress: 40,
        analyticsKey: 'who_do_you_go_fishing_with',
        title: 'Who do you usually go fishing with?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'I fish alone',
                value: 'alone',
            },
            {
                type: 'with_tick',
                text: 'With family members',
                value: 'family',
            },
            {
                type: 'with_tick',
                text: 'With friends',
                value: 'friends',
            },
            {
                type: 'with_tick',
                text: 'With a professional guide',
                value: 'professional_guide',
            },
            {
                type: 'with_tick',
                text: 'With coworkers or business associates',
                value: 'business_associates',
            },
        ],
    },

    {
        type: 'question',
        step: 11,
        nextStep: 12,
        prevStep: 10,
        mainQuestion: 2,
        progress: 60,
        analyticsKey: 'do_you_agree',
        title: 'Do you agree with the following:',
        desc: "“I don't like crowded fishing spots”",
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'Completely agree',
                value: 'completely_agree',
            },
            {
                type: 'radio',
                text: 'Agree',
                value: 'agree',
            },
            {
                type: 'radio',
                text: 'Neutral',
                value: 'neutral',
            },
            {
                type: 'radio',
                text: 'Disagree',
                value: 'disagree',
            },
            {
                type: 'radio',
                text: 'Totally disagree',
                value: 'totally_disagree',
            },
        ],
    },

    {
        type: 'question',
        step: 12,
        nextStep: 13,
        prevStep: 11,
        mainQuestion: 2,
        progress: 80,
        analyticsKey: 'how_often_disappointed',
        title: 'How often are you disappointed with your fishing trip?',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'Often',
                value: 'often',
            },
            {
                type: 'radio',
                text: 'Sometimes',
                value: 'sometimes',
            },
            {
                type: 'radio',
                text: 'Rarely',
                value: 'rarely',
            },
            {
                type: 'radio',
                text: 'Never',
                value: 'never',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 2,
        step: 13,
        nextStep: 14,
        prevStep: 12,
        progress: 90,
        analyticsKey: 'what_difficulties',
        title: 'What difficulties are you experiencing most often?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: "I don't know when fish is biting",
                value: 'fish_is_biting',
            },
            {
                type: 'with_tick',
                text: " I don't know spots where fish are active",
                value: 'spots',
            },
            {
                type: 'with_tick',
                text: "I don't know what fish are habitat in a particular spot",
                value: 'fish_are_habitat',
            },
            {
                type: 'with_tick',
                text: " I don't know what gear I need",
                value: 'what_gear',
            },
            {
                type: 'with_tick',
                text: "I don't know spot's rules and regulations",
                value: 'regulations',
            },
            {
                type: 'with_tick',
                text: 'Bad weather',
                value: 'bad_weather',
            },
            {
                type: 'with_tick',
                text: 'Fishing gear is a way too expensive',
                value: 'too_expensive',
            },
            {
                type: 'with_tick',
                text: "I don't have enough time 😭",
                value: 'enough_time',
            },
        ],
    },

    {
        type: 'screen_2',
        mainQuestion: 3,
        step: 14,
        nextStep: 15,
        progress: 0,
        img: screen2Salt,
        analyticsKey: 'you_are_not_alone',
        withOutAnalyticsValue: true,
        buttonText: 'Continue',
        isButtonEnabled: true,
    },

    {
        type: 'state',
        mainQuestion: 3,
        step: 15,
        nextStep: 16,
        prevStep: 14,
        progress: 30,
        analyticsKey: 'your_state',
        buttonText: 'Continue',
        titleText: 'Choose your state or territory:',
    },

    {
        type: 'city',
        mainQuestion: 3,
        step: 16,
        nextStep: 17,
        prevStep: 15,
        progress: 60,
        analyticsKey: 'your_city',
        img: city,
        buttonText: 'Continue',
    },

    {
        type: 'question',
        step: 17,
        nextStep: 18,
        prevStep: 16,
        mainQuestion: 3,
        progress: 90,
        analyticsKey: 'how_far',
        title: 'How far from the city are you ready to go on your fishing trip?',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'Up to 40 kilometers',
                value: '40_kilometers',
            },
            {
                type: 'radio',
                text: 'Up to 80 kilometers',
                value: '80_kilometers',
            },
            {
                type: 'radio',
                text: 'Up to 160 kilometers',
                value: '160_kilometers',
            },
            {
                type: 'radio',
                text: 'Up to 320 kilometers',
                value: '320_kilometers',
            },
            {
                type: 'radio',
                text: 'Up to 320+ kilometers',
                value: 'more_320_kilometers',
            },
        ],
    },

    {
        type: 'loading',
        step: 18,
        nextStep: 19,
        withoutButton: true,
        data: {
            eventFillName: 'analyzing answers',
            nextRoute: '/onboarding/PersonalizationD2',
            title: 'Analyzing your answers...',
            subtitle: 'Trusted by over 149,569 people',
            loadingArrays: [
                {
                    text: 'Analysis of water bodies nearby',
                    title: 'Analyzing your answers...',
                    color: '#5682F2',
                    steps: [
                        {
                            finish: 30,
                            timeout: 133,
                        },
                        {
                            finish: 70,
                            timeout: 70,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
                {
                    text: 'Calculation of biting activity',
                    color: '#4ABCE1',
                    title: 'Analyzing weather parameters...',
                    steps: [
                        {
                            finish: 30,
                            timeout: 133,
                        },
                        {
                            finish: 70,
                            timeout: 50,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
                {
                    text: 'Generating Your Personal Map',
                    color: '#39CAB9',
                    title: 'Looking for hotspots near you...',
                    steps: [
                        {
                            finish: 30,
                            timeout: 100,
                        },
                        {
                            finish: 70,
                            timeout: 50,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
            ],
            loadingArraysSecond: [
                {
                    text: 'Generating Your Personal Map',
                    color: '#39CAB9',
                    title: 'Looking for hotspots near you...',
                    titles: [
                        {
                            text: 'Analyzing weather parameters...',
                            percent: 0,
                        },
                        {
                            text: 'Looking for hotspots near you...',
                            percent: 64,
                        },
                        {
                            text: 'Your personal map is complete',
                            percent: 98,
                        },
                    ],

                    steps: [
                        {
                            finish: 30,
                            timeout: 200,
                        },
                        {
                            finish: 70,
                            timeout: 100,
                        },
                        {
                            finish: 100,
                            timeout: 66,
                        },
                    ],
                },
            ],
            loadingArraysThird: TWEAKS_LOADERS.analyze_loaders,
            loadingTextArray: [
                {
                    startPercent: 0,
                    finishPercent: 50,
                    text: 'Connecting to database',
                },
                {
                    startPercent: 51,
                    finishPercent: 99,
                    text: 'Preliminary processing',
                },
            ],
        },
    },

    {
        type: 'email',
        step: 19,
        nextStep: 20,
        analyticsKey: 'email',
        withoutButton: true,
        title: 'Where should we send your personalized hotspots & fishing map',
        desc: 'Enter your email to get started',
        buttonText: 'See my map',
    },

    {
        type: 'landing',
        step: 20,
    },
];

const stepsSalt = [
    {
        type: 'first_screen',
        mainQuestion: 1,
        progress: 20,
        step: -1,
        withoutButton: true,
        noSet: true,
        screens: {
            1: {
                img: firstScreen,
                title: 'Find the best fishing spots near you',
                text: 'Answer a quick quiz to receive your personal hot spots map',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                prevStep: 0,
                analyticsKey: 'type_of_water',
                answers: [
                    {
                        type: 'button',
                        text: 'Fresh',
                        img: fresh,
                        value: 'fresh',
                    },
                    {
                        type: 'button',
                        text: 'Salt',
                        img: salt,
                        value: 'salt',
                    },
                ],
            },
            2: {
                img: firstScreen,
                imgDesc: '*828 top spots in US',
                title: 'Discover new fishing spots nearby tailored to your fishing preferences',
                nextStep: 1,
                prevStep: 0,
                loadingArrays: [
                    {
                        title: 'Loading the test',
                        color: '#0A364A',
                        colorLoader: '#5682F2',
                        steps: [
                            {
                                finish: 30,
                                timeout: 133,
                            },
                            {
                                finish: 70,
                                timeout: 70,
                            },
                            {
                                finish: 100,
                                timeout: 33,
                            },
                        ],
                    },
                ],
            },
            [FIRST_SCREEN_VARIANT_WATER_V1]: {
                img: firstScreen,
                title: 'Your Fishing Map is Almost Ready!',
                text: 'Just answer 3 quick questions to customize it.',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                mainQuestion: 1,
                prevStep: 0,
                progress: 20,
                analyticsKey: 'type_of_water',
                answerType: 'single',
                answers: [
                    {
                        type: 'with_img',
                        text: 'Fresh',
                        img: freshFS,
                        value: 'fresh',
                    },
                    {
                        type: 'with_img',
                        text: 'Salt',
                        img: saltFS,
                        value: 'salt',
                    },
                ],
            },
            [FIRST_SCREEN_VARIANT_WATER_V2]: {
                title: ' Find the best fishing spots near you',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                mainQuestion: 1,
                prevStep: 0,
                progress: 20,
                analyticsKey: 'type_of_water',
                answerType: 'single',
                answers: [
                    {
                        text: 'Fresh',
                        type: 'button',
                        value: 'fresh',
                    },
                    {
                        text: 'Salt',
                        type: 'button',
                        value: 'salt',
                    },
                ],
            },
        },
    },
    {
        type: 'question',
        step: 0,
        analyticsKey: 'type_of_water',
        nextStep: 1,
        mainQuestion: 1,
        progress: 0,
        title: 'Discover Your Perfect Fishing Map',
        text: 'Answer a quick customized quiz to receive your hotspots map and 7-day fishing forecast.',
        desc: 'Select the type of water you usually fish in:',
        answerType: 'single',
        answers: [
            {
                type: 'with_img',
                text: 'Fresh',
                img: fresh,
                value: 'fresh',
            },
            {
                type: 'with_img',
                text: 'Salt',
                img: salt,
                value: 'salt',
            },
            {
                type: 'radio',
                text: 'Both',
                value: 'both',
                textStyles: '!text-center',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 1,
        nextStep: 2,
        prevStep: 0,
        prevStepAlt: -1,
        progress: 20,
        analyticsKey: 'where_are_you_fishing',
        title: 'Where are you enjoying fishing most of all?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Boat',
                img: boat,
                value: 'boat',
            },
            {
                type: 'with_img',
                text: 'Bank',
                img: off,
                value: 'bank',
            },
            {
                type: 'with_img',
                text: 'Pier or dock',
                img: pier,
                value: 'pier_or_dock',
            },
            {
                type: 'with_img',
                text: 'Wading position',
                img: wading,
                value: 'wading_position',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 2,
        nextStep: 3,
        prevStep: 1,
        progress: 30,
        analyticsKey: 'what_waterbodies',
        title: 'What waterbodies you usually fish at?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Bays',
                img: bays,
                value: 'bays',
            },
            {
                type: 'with_img',
                text: 'Ocean',
                img: ocean,
                value: 'ocean',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 3,
        nextStep: 4,
        prevStep: 2,
        progress: 45,
        analyticsKey: 'what_fish',
        title: 'What fish species do you usually target?',
        text: 'This information will help us understand your preferences.',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Striped bass',
                img: striped,
                value: 'striped_bass',
            },
            {
                type: 'with_img',
                text: 'Redfish',
                img: red,
                value: 'redfish',
            },
            {
                type: 'with_img',
                text: 'Common Snook',
                img: common,
                value: 'common_snook',
            },
            {
                type: 'with_img',
                text: 'Red snapper',
                img: snapper,
                value: 'red_snapper',
            },
            {
                type: 'with_img',
                text: 'Grouper',
                img: grouper,
                value: 'grouper',
            },
            {
                type: 'with_img',
                text: 'Flounder',
                img: flounder,
                value: 'flounder',
            },
            {
                type: 'with_img',
                text: 'Tuna',
                img: tuna,
                value: 'tuna',
            },
            {
                type: 'with_img',
                text: 'Bluefish',
                img: bluefish,
                value: 'bluefish',
            },
            {
                type: 'with_img',
                text: 'Tarpon',
                img: tarpon,
                value: 'tarpon',
            },
            {
                type: 'with_img',
                text: 'Cobia',
                img: cobia,
                value: 'cobia',
            },
            {
                type: 'with_img',
                text: 'Mahi-mahi',
                img: mahi,
                value: 'mahi_mahi',
            },
            {
                type: 'with_img',
                text: 'Marlin',
                img: marlin,
                value: 'marlin',
            },

            {
                type: 'with_img',
                text: 'Salmon',
                img: salmon,
                value: 'salmon',
            },
            {
                type: 'with_img',
                text: 'Other saltwater fish',
                img: otherSalt,
                imgStyles: 'w-[93px] h-[88px] !min-h-[auto] !min-w-[auto]',
                value: 'other_saltwater_fish',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 4,
        nextStep: 5,
        prevStep: 3,
        progress: 60,
        analyticsKey: 'what_time',
        title: 'What time intervals you usually choose to plan your fishing trip?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'Early morning (from 5 a.m. till 9 a.m.)',
                value: 'early_morning',
            },
            {
                type: 'with_tick',
                text: 'Late morning (from 9 a.m. till 12 p.m.)',
                value: 'late_morning',
            },
            {
                type: 'with_tick',
                text: 'Afternoon (from 12 p.m. till 6 p.m.)',
                value: 'afternoon',
            },
            {
                type: 'with_tick',
                text: 'Late evening (from 6 p.m. till 10 p.m.)',
                value: 'late_evening',
            },
            {
                type: 'with_tick',
                text: 'Night time (from 10 p.m. till 5 a.m.)',
                value: 'night_time',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 5,
        nextStep: 6,
        prevStep: 4,
        progress: 75,
        analyticsKey: 'what_angling_method',
        title: 'What angling method do you usually use?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'Bottom fishing',
                value: 'bottom_fishing',
            },
            {
                type: 'with_tick',
                text: 'Fly-fishing',
                value: 'fly_fishing',
            },
            {
                type: 'with_tick',
                text: 'Spin fishing',
                value: 'spin_fishing',
            },
            {
                type: 'with_tick',
                text: 'Jigging',
                value: 'jigging',
            },
            {
                type: 'with_tick',
                text: 'Trolling',
                value: 'trolling',
            },
            {
                type: 'with_tick',
                text: 'Casting',
                value: 'casting',
            },
            {
                type: 'with_tick',
                text: 'Other method',
                value: 'other_method',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 1,
        step: 6,
        nextStep: 7,
        prevStep: 5,
        progress: 90,
        analyticsKey: 'what_kind_of_lures',
        title: 'What kind of fishing baits/lures do you use most often?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Live Shrimp',
                img: shrimp,
                value: 'shrimp',
            },
            {
                type: 'with_img',
                text: 'Minnows',
                img: minnows,
                value: 'minnows',
            },
            {
                type: 'with_img',
                text: 'Squid',
                img: squid,
                value: 'squid',
            },
            {
                type: 'with_img',
                text: 'Other baits',
                img: othSalt,
                value: 'other_baits',
            },
            {
                type: 'with_img',
                text: 'Plugs',
                img: plugs,
                value: 'plugs',
            },
            {
                type: 'with_img',
                text: 'Jigs',
                img: jigs,
                value: 'jigs',
            },
            {
                type: 'with_img',
                text: 'Spoons',
                img: spoons,
                value: 'spoons',
            },
            {
                type: 'with_img',
                text: 'Soft bait lures',
                img: soft,
                value: 'soft_bait_lures',
            },
            {
                type: 'with_img',
                text: 'Flies',
                img: flies,
                value: 'flies',
            },
            {
                type: 'with_img',
                text: 'Other lures',
                img: other,
                imgStyles: 'w-[93px] h-[88px] !min-h-[auto] !min-w-[auto]',
                value: 'other_lures',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 1,
        step: 7,
        nextStep: 8,
        prevStep: 6,
        progress: 95,
        analyticsKey: 'in_which_layer_of_water',
        title: 'In which layer of water do you usually catch fish?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'I like to fish in shallow water, around 3-5 feet deep',
                value: '3_5_feet_deep',
            },
            {
                type: 'with_tick',
                text: 'It depends on my current target species, but I usually fish in a 10-15 feet deep water',
                value: '10_15_feet_deep',
            },
            {
                type: 'with_tick',
                text: 'I prefer to fish in deeper water, around 20-30 feet deep',
                value: '20_30_feet_deep',
            },
            {
                type: 'with_tick',
                text: 'I fish in water that is 25+ feet deep',
                value: '25_feet_deep',
            },
        ],
    },

    {
        type: 'screen_1',
        mainQuestion: 2,
        step: 8,
        nextStep: 9,
        progress: 0,
        analyticsKey: 'our_algorithms',
        withOutAnalyticsValue: true,
        title: 'Our algorithms are based on 21 parameters',
        text: "We'll literally show you the best spots to fish in your area based on real-time trends, which means you'll always fish in areas filled with fish, and be spared from long preparations for each trip.",
        help: 'Fishbox™ Team',
        img: screen1,
        buttonText: 'Sounds good',
        isButtonEnabled: true,
    },

    {
        type: 'question',
        step: 9,
        nextStep: 10,
        prevStep: 8,
        mainQuestion: 2,
        progress: 20,
        analyticsKey: 'what_describes_you_better',
        title: 'What describes you better?',
        text: 'This information will help us understand your experience.',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'I’m a beginner',
                value: 'beginner',
            },
            {
                type: 'radio',
                text: 'I’m an amateur',
                value: 'amateur',
            },
            {
                type: 'radio',
                text: 'I’m an experienced angler',
                value: 'experienced',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 2,
        step: 10,
        nextStep: 11,
        prevStep: 9,
        progress: 40,
        analyticsKey: 'who_do_you_go_fishing_with',
        title: 'Who do you usually go fishing with?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'I fish alone',
                value: 'alone',
            },
            {
                type: 'with_tick',
                text: 'With family members',
                value: 'family',
            },
            {
                type: 'with_tick',
                text: 'With friends',
                value: 'friends',
            },
            {
                type: 'with_tick',
                text: 'With a professional guide',
                value: 'professional_guide',
            },
            {
                type: 'with_tick',
                text: 'With coworkers or business associates',
                value: 'business_associates',
            },
        ],
    },

    {
        type: 'question',
        step: 11,
        nextStep: 12,
        prevStep: 10,
        mainQuestion: 2,
        progress: 60,
        analyticsKey: 'do_you_agree',
        title: 'Do you agree with the following:',
        desc: "“I don't like crowded fishing spots”",
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'Completely agree',
                value: 'completely_agree',
            },
            {
                type: 'radio',
                text: 'Agree',
                value: 'agree',
            },
            {
                type: 'radio',
                text: 'Neutral',
                value: 'neutral',
            },
            {
                type: 'radio',
                text: 'Disagree',
                value: 'disagree',
            },
            {
                type: 'radio',
                text: 'Totally disagree',
                value: 'totally_disagree',
            },
        ],
    },

    {
        type: 'question',
        step: 12,
        nextStep: 13,
        prevStep: 11,
        mainQuestion: 2,
        progress: 80,
        analyticsKey: 'how_often_disappointed',
        title: 'How often are you disappointed with your fishing trip?',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'Often',
                value: 'often',
            },
            {
                type: 'radio',
                text: 'Sometimes',
                value: 'sometimes',
            },
            {
                type: 'radio',
                text: 'Rarely',
                value: 'rarely',
            },
            {
                type: 'radio',
                text: 'Never',
                value: 'never',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 2,
        step: 13,
        nextStep: 14,
        prevStep: 12,
        progress: 90,
        analyticsKey: 'what_difficulties',
        title: 'What difficulties are you experiencing most often?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: "I don't know when fish is biting",
                value: 'fish_is_biting',
            },
            {
                type: 'with_tick',
                text: " I don't know spots where fish are active",
                value: 'spots',
            },
            {
                type: 'with_tick',
                text: "I don't know what fish are habitat in a particular spot",
                value: 'fish_are_habitat',
            },
            {
                type: 'with_tick',
                text: "I don't know what gear I need",
                value: 'what_gear',
            },
            {
                type: 'with_tick',
                text: "I don't know spot's rules and regulations",
                value: 'regulations',
            },
            {
                type: 'with_tick',
                text: 'Bad weather',
                value: 'bad_weather',
            },
            {
                type: 'with_tick',
                text: 'Fishing gear is a way too expensive',
                value: 'too_expensive',
            },
            {
                type: 'with_tick',
                text: "I don't have enough time 😭",
                value: 'enough_time',
            },
        ],
    },

    {
        type: 'screen_2',
        mainQuestion: 3,
        step: 14,
        nextStep: 15,
        progress: 0,
        img: screen2Salt,
        analyticsKey: 'you_are_not_alone',
        withOutAnalyticsValue: true,
        buttonText: 'Continue',
        isButtonEnabled: true,
    },

    {
        type: 'state',
        mainQuestion: 3,
        step: 15,
        nextStep: 16,
        prevStep: 14,
        progress: 30,
        analyticsKey: 'your_state',
        buttonText: 'Continue',
        titleText: 'Choose your state:',
    },

    {
        type: 'city',
        mainQuestion: 3,
        step: 16,
        nextStep: 17,
        prevStep: 15,
        progress: 60,
        analyticsKey: 'your_city',
        img: city,
        buttonText: 'Continue',
    },

    {
        type: 'question',
        step: 17,
        nextStep: 18,
        prevStep: 16,
        mainQuestion: 3,
        progress: 90,
        analyticsKey: 'how_far',
        title: 'How far from the city are you ready to go on your fishing trip?',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'Up to 25 miles',
                value: '25_miles',
            },
            {
                type: 'radio',
                text: 'Up to 50 miles',
                value: '50_miles',
            },
            {
                type: 'radio',
                text: 'Up to 100 miles',
                value: '100_miles',
            },
            {
                type: 'radio',
                text: 'Up to 200 miles',
                value: '200_miles',
            },
            {
                type: 'radio',
                text: 'Up to 200+ miles',
                value: 'more_200_miles',
            },
        ],
    },

    {
        type: 'loading',
        step: 18,
        nextStep: 19,
        withoutButton: true,
        data: {
            eventFillName: 'analyzing answers',
            nextRoute: '/onboarding/PersonalizationD2',
            title: 'Analyzing your answers...',
            subtitle: 'Trusted by over 149,569 people',
            loadingArrays: [
                {
                    text: 'Analysis of water bodies nearby',
                    title: 'Analyzing your answers...',
                    color: '#5682F2',
                    steps: [
                        {
                            finish: 30,
                            timeout: 133,
                        },
                        {
                            finish: 70,
                            timeout: 70,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
                {
                    text: 'Calculation of biting activity',
                    color: '#4ABCE1',
                    title: 'Analyzing weather parameters...',
                    steps: [
                        {
                            finish: 30,
                            timeout: 133,
                        },
                        {
                            finish: 70,
                            timeout: 50,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
                {
                    text: 'Generating Your Personal Map',
                    color: '#39CAB9',
                    title: 'Looking for hotspots near you...',
                    steps: [
                        {
                            finish: 30,
                            timeout: 100,
                        },
                        {
                            finish: 70,
                            timeout: 50,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
            ],
            loadingArraysSecond: [
                {
                    text: 'Generating Your Personal Map',
                    color: '#39CAB9',
                    title: 'Looking for hotspots near you...',
                    titles: [
                        {
                            text: 'Analyzing weather parameters...',
                            percent: 0,
                        },
                        {
                            text: 'Looking for hotspots near you...',
                            percent: 64,
                        },
                        {
                            text: 'Your personal map is complete',
                            percent: 98,
                        },
                    ],

                    steps: [
                        {
                            finish: 30,
                            timeout: 200,
                        },
                        {
                            finish: 70,
                            timeout: 100,
                        },
                        {
                            finish: 100,
                            timeout: 66,
                        },
                    ],
                },
            ],
            loadingArraysThird: TWEAKS_LOADERS.analyze_loaders,
            loadingTextArray: [
                {
                    startPercent: 0,
                    finishPercent: 50,
                    text: 'Connecting to database',
                },
                {
                    startPercent: 51,
                    finishPercent: 99,
                    text: 'Preliminary processing',
                },
            ],
        },
    },

    {
        type: 'email',
        step: 19,
        nextStep: 20,
        analyticsKey: 'email',
        withoutButton: true,
        title: 'Where should we send your personalized hotspots & fishing map',
        desc: 'Enter your email to get started',
        buttonText: 'See my map',
    },

    {
        type: 'landing',
        step: 20,
    },
];

const stepsJp = [
    {
        type: 'first_screen',
        mainQuestion: 1,
        progress: 20,
        step: -1,
        withoutButton: true,
        noSet: true,
        screens: {
            1: {
                img: firstScreenJp,
                title: 'あなた専用の釣り場と予報',
                text: 'クイズに答えて、あなた専用の釣り場マップと最適な釣りの時間帯を入手しましょう',
                question: 'いつも釣りをする環境を選択してください:',
                nextStep: 1,
                prevStep: 0,
                analyticsKey: 'type_of_water',
                answers: [
                    {
                        type: 'button',
                        text: '淡水',
                        img: fresh,
                        value: 'fresh',
                    },
                    {
                        type: 'button',
                        text: '海水',
                        img: salt,
                        value: 'salt',
                    },
                ],
            },
            2: {
                img: firstScreenJp,
                imgDesc: '*828 top spots in US',
                title: 'Discover new fishing spots nearby tailored to your fishing preferences',
                nextStep: 1,
                prevStep: 0,
                loadingArrays: [
                    {
                        title: 'Loading the test',
                        color: '#0A364A',
                        colorLoader: '#5682F2',
                        steps: [
                            {
                                finish: 30,
                                timeout: 133,
                            },
                            {
                                finish: 70,
                                timeout: 70,
                            },
                            {
                                finish: 100,
                                timeout: 33,
                            },
                        ],
                    },
                ],
            },
            [FIRST_SCREEN_VARIANT_WATER_V1]: {
                img: firstScreen,
                title: 'Your Fishing Map is Almost Ready!',
                text: 'Just answer 3 quick questions to customize it.',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                mainQuestion: 1,
                prevStep: 0,
                progress: 20,
                analyticsKey: 'type_of_water',
                answerType: 'single',
                answers: [
                    {
                        type: 'with_img',
                        text: 'Fresh',
                        img: freshFS,
                        value: 'fresh',
                    },
                    {
                        type: 'with_img',
                        text: 'Salt',
                        img: saltFS,
                        value: 'salt',
                    },
                ],
            },
            [FIRST_SCREEN_VARIANT_WATER_V2]: {
                title: ' Find the best fishing spots near you',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                mainQuestion: 1,
                prevStep: 0,
                progress: 20,
                analyticsKey: 'type_of_water',
                answerType: 'single',
                answers: [
                    {
                        text: 'Fresh',
                        type: 'button',
                        value: 'fresh',
                    },
                    {
                        text: 'Salt',
                        type: 'button',
                        value: 'salt',
                    },
                ],
            },
        },
    },
    {
        type: 'question',
        step: 0,
        analyticsKey: 'type_of_water',
        nextStep: 1,
        mainQuestion: 1,
        progress: 0,
        title: 'Discover Your Perfect Fishing Map',
        text: 'Answer a quick customized quiz to receive your hotspots map and 7-day fishing forecast.',
        desc: 'Select the type of water you usually fish in:',
        answerType: 'single',
        answers: [
            {
                type: 'with_img',
                text: 'Fresh',
                img: fresh,
                value: 'fresh',
            },
            {
                type: 'with_img',
                text: 'Salt',
                img: salt,
                value: 'salt',
            },
            {
                type: 'radio',
                text: 'Both',
                value: 'both',
                textStyles: '!text-center',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 1,
        nextStep: 2,
        prevStep: 0,
        prevStepAlt: -1,
        progress: 15,
        analyticsKey: 'where_are_you_fishing',
        title: '一番好きな釣り場は？',
        help: '複数選択できます。',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: '岸壁や堤防',
                img: jpShore,
                value: 'bank',
            },
            {
                type: 'with_img',
                text: '船またはカヤック',
                img: jpBoat,
                value: 'boat',
            },

            {
                type: 'with_img',
                text: '氷上',
                img: jpIce,
                value: 'ice',
            },
            {
                type: 'with_img',
                text: '水中',
                img: jpWater,
                value: 'in_water',
            },
            {
                type: 'with_img',
                text: '防波堤や桟橋',
                img: jpPier,
                value: 'pier_or_dock',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 2,
        nextStep: 3,
        prevStep: 1,
        progress: 30,
        analyticsKey: 'what_waterbodies',
        title: 'いつもどのような水域で釣りをしていますか？',
        help: '複数選択できます。',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: '湖',
                img: jpLakes,
                value: 'lakes',
            },
            {
                type: 'with_img',
                text: '川',
                img: jpRivers,
                value: 'rivers',
            },
            {
                type: 'with_img',
                text: '池',
                img: jpPonds,
                value: 'ponds',
            },
            {
                type: 'with_img',
                text: '貯水池',
                img: jpReservoirs,
                value: 'reservoirs',
            },
            {
                type: 'with_img',
                text: '小川',
                img: jpStreams,
                value: 'streams',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 3,
        nextStep: 4,
        prevStep: 2,
        progress: 45,
        analyticsKey: 'what_fish',
        title: 'いつもどんな魚をねらっていますか？',
        text: 'この情報は、あなたの好みを理解するのに役立ちます。',
        help: '複数選択できます。',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'ラージマウスバス',
                img: larg,
                value: 'largemous_bass',
            },
            {
                type: 'with_img',
                text: 'スモールマウスバス',
                img: small,
                value: 'smallmouth_bass',
            },
            {
                type: 'with_img',
                text: 'アメリカナマズ',
                img: channel,
                value: 'channel_catfish',
            },
            {
                type: 'with_img',
                text: 'ブラウントラウト',
                img: brown,
                value: 'brown_trout',
            },

            {
                type: 'with_img',
                text: 'カワマス',
                img: brookTrout,
                value: 'brook_trout',
            },
            {
                type: 'with_img',
                text: 'コイ',
                img: commonCarp,
                value: 'common_carp',
            },
            {
                type: 'with_img',
                text: 'ニジマス',
                img: rainbowTrout,
                value: 'rainbow_trout',
            },
            {
                type: 'with_img',
                text: 'アユ',
                img: auy,
                value: 'ayu',
            },

            {
                type: 'with_img',
                text: 'フナ',
                img: crucianCarp,
                value: 'crucian_carp',
            },
            {
                type: 'with_img',
                text: 'ウナギ',
                img: jpEel,
                value: 'japanese_eel',
            },
            {
                type: 'with_img',
                text: 'その他の淡水魚',
                img: otherFresh,
                imgStyles: 'w-[93px] h-[88px] !min-h-[auto] !min-w-[auto]',
                value: 'other_freshwater_fish',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 4,
        nextStep: 5,
        prevStep: 3,
        progress: 60,
        analyticsKey: 'what_time',
        title: 'いつも釣りに行く時間帯',
        help: '複数選択できます。',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: '早朝 (午前 5 時～午前 9 時)',
                value: 'early_morning',
            },
            {
                type: 'with_tick',
                text: '深夜 (午前 9 時～午後 12 時)',
                value: 'late_morning',
            },
            {
                type: 'with_tick',
                text: '午後 (午後 12 時～午後 6 時)',
                value: 'afternoon',
            },
            {
                type: 'with_tick',
                text: '夕方 (午後 6 時～午後 10 時)',
                value: 'late_evening',
            },
            {
                type: 'with_tick',
                text: '夜間 (午後 10 時～午前 5 時)',
                value: 'night_time',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 5,
        nextStep: 6,
        prevStep: 4,
        progress: 75,
        analyticsKey: 'what_angling_method',
        title: 'いつもどのような釣り方をしますか？',
        help: '複数選択できます。',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: '底釣り',
                value: 'bottom_fishing',
            },
            {
                type: 'with_tick',
                text: 'フライフィッシング',
                value: 'casting',
            },
            {
                type: 'with_tick',
                text: 'ジギング',
                value: 'spin_fishing',
            },
            {
                type: 'with_tick',
                text: 'トローリング',
                value: 'jigging',
            },
            {
                type: 'with_tick',
                text: 'キャスティング',
                value: 'trolling',
            },
            {
                type: 'with_tick',
                text: '投げ釣り',
                value: 'fly_fishing',
            },
            {
                type: 'with_tick',
                text: '手釣り',
                value: 'other_method',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 1,
        step: 6,
        nextStep: 7,
        prevStep: 5,
        progress: 90,
        analyticsKey: 'what_kind_of_lures',
        title: '最もよく使うエサやルアーは？',
        help: '複数選択できます。',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'フライ',
                img: flies,
                value: 'flies',
            },
            {
                type: 'with_img',
                text: 'ジグ',
                img: jigs,
                value: 'jigs',
            },
            {
                type: 'with_img',
                text: 'プラグ',
                img: plugs,
                value: 'plugs',
            },
            {
                type: 'with_img',
                text: 'スプーン',
                img: spoons,
                value: 'spoons',
            },
            {
                type: 'with_img',
                text: 'スピナーベイトとバズベイト',
                img: spinner,
                value: 'spinnerbaits_buzzbaits',
            },
            {
                type: 'with_img',
                text: 'ソフトベイトルアー',
                img: soft,
                value: 'soft_bait_lures',
            },
            {
                type: 'with_img',
                text: 'クランクベイト',
                img: crankBaits,
                value: 'crankbaits',
            },
            {
                type: 'with_img',
                text: 'その他のルア',
                img: other,
                imgStyles: 'w-[93px] h-[88px] !min-h-[auto] !min-w-[auto]',
                value: 'other_lures',
            },
            {
                type: 'with_img',
                text: '昆虫',
                img: insects,
                value: 'insects',
            },
            {
                type: 'with_img',
                text: 'ワーム',
                img: worms,
                value: 'worms',
            },
            {
                type: 'with_img',
                text: 'ボイリーとスイートコーン',
                img: boilies,
                value: 'boilies_sweetcorn',
            },
            {
                type: 'with_img',
                text: 'その他の餌',
                img: baits,
                value: 'other_baits',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 1,
        step: 7,
        nextStep: 8,
        prevStep: 6,
        progress: 95,
        analyticsKey: 'in_which_layer_of_water',
        title: '釣りの水深は？',
        help: '複数選択できます。',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: '水深 1～2 メートル',
                value: '1_2_feet_deep',
            },
            {
                type: 'with_tick',
                text: '水深 3～5 メートル',
                value: '3_5_feet_deep',
            },
            {
                type: 'with_tick',
                text: '水深 6～9 メートル',
                value: '6_9_feet_deep',
            },
            {
                type: 'with_tick',
                text: '水深 10 メートル以上',
                value: '10_feet_deep',
            },
        ],
    },

    {
        type: 'screen_1',
        mainQuestion: 2,
        step: 8,
        nextStep: 9,
        progress: 0,
        analyticsKey: 'our_algorithms',
        withOutAnalyticsValue: true,
        title: '当社のアルゴリズムは 21 のパラメータに基づいています',
        text: ' リアルタイムの傾向に基づいて、あなたの地域で釣りに最適な場所をご案内します。常に多くの魚がいる場所で釣りができ、毎回、短時間で釣りの準備ができます。',
        help: 'Fishbox™ Team',
        img: screen1,
        buttonText: 'いいですね',
        isButtonEnabled: true,
    },

    {
        type: 'question',
        step: 9,
        nextStep: 10,
        prevStep: 8,
        mainQuestion: 2,
        progress: 20,
        analyticsKey: 'what_describes_you_better',
        title: '最もよく当てはまるのを選択してください?',
        text: ' この情報は、お客様の体験を把握するのに役立ちます',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: '初心者',
                value: 'beginner',
            },
            {
                type: 'radio',
                text: 'アマチュア',
                value: 'amateur',
            },
            {
                type: 'radio',
                text: '経験豊富',
                value: 'experienced',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 2,
        step: 10,
        nextStep: 11,
        prevStep: 9,
        progress: 40,
        analyticsKey: 'who_do_you_go_fishing_with',
        title: 'いつも誰と釣りに行きますか？',
        help: '複数選択できます。',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: '一人',
                value: 'alone',
            },
            {
                type: 'with_tick',
                text: '家族',
                value: 'family',
            },
            {
                type: 'with_tick',
                text: '友達',
                value: 'friends',
            },
            {
                type: 'with_tick',
                text: 'プロのガイド',
                value: 'professional_guide',
            },
            {
                type: 'with_tick',
                text: '仕事仲間',
                value: 'business_associates',
            },
        ],
    },

    {
        type: 'question',
        step: 11,
        nextStep: 12,
        prevStep: 10,
        mainQuestion: 2,
        progress: 60,
        analyticsKey: 'do_you_agree',
        title: '次のことに共感しますか？',
        desc: '混雑した釣り場は好きではない',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: '完全に共感する',
                value: 'completely_agree',
            },
            {
                type: 'radio',
                text: '共感する',
                value: 'agree',
            },
            {
                type: 'radio',
                text: 'どちらでもない',
                value: 'neutral',
            },
            {
                type: 'radio',
                text: '共感する',
                value: 'disagree',
            },
            {
                type: 'radio',
                text: '完全に共感する',
                value: 'totally_disagree',
            },
        ],
    },

    {
        type: 'question',
        step: 12,
        nextStep: 13,
        prevStep: 11,
        mainQuestion: 2,
        progress: 80,
        analyticsKey: 'how_often_disappointed',
        title: '釣り旅行でがっかりしたことはどのくらいの頻度でありますか？',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'よくある',
                value: 'often',
            },
            {
                type: 'radio',
                text: 'ときどきある',
                value: 'sometimes',
            },
            {
                type: 'radio',
                text: 'ほとんどない',
                value: 'rarely',
            },
            {
                type: 'radio',
                text: '一切ない',
                value: 'never',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 2,
        step: 13,
        nextStep: 14,
        prevStep: 12,
        progress: 90,
        analyticsKey: 'what_difficulties',
        title: 'あなたにとって釣りで一番困っていることは何ですか?',
        help: '複数選択できます。',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'いつ釣りをすればよいか分からない',
                value: 'I don’t know when to fish',
            },
            {
                type: 'with_tick',
                text: '魚の活性が高い場所が分からない',
                value: 'I don’t know where fish are active',
            },
            {
                type: 'with_tick',
                text: '必要な道具が分からない',
                value: 'I don’t know what gear I need',
            },
            {
                type: 'with_tick',
                text: '釣り場のルールや規制が分からない',
                value: "'I don't know spot rules and regulations on my location'",
            },
            {
                type: 'with_tick',
                text: '悪天候',
                value: 'bad_weather',
            },

            {
                type: 'with_tick',
                text: '釣り道具が高価すぎる',
                value: 'too_expensive',
            },
            {
                type: 'with_tick',
                text: '釣りをする時間が足りない',
                value: 'enough_time',
            },
        ],
    },

    {
        type: 'screen_2',
        mainQuestion: 3,
        step: 14,
        nextStep: 15,
        progress: 0,
        img: screen2Jp,
        analyticsKey: 'you_are_not_alone',
        withOutAnalyticsValue: true,
        buttonText: '続ける',
        isButtonEnabled: true,
        dynamicText:
            'あきらめないで、続けて質問に答えてください。そうすれば、次回、デカい魚やお気に入りのスポットが見つかるでしょう。」',
    },

    {
        type: 'state',
        mainQuestion: 3,
        step: 15,
        nextStep: 16,
        prevStep: 14,
        progress: 30,
        analyticsKey: 'your_state',
        buttonText: '続ける',
        titleText: '都道府県を選択してください',
    },

    {
        type: 'city',
        mainQuestion: 3,
        step: 16,
        nextStep: 17,
        prevStep: 15,
        progress: 60,
        analyticsKey: 'your_city',
        img: city,
        buttonText: '続ける',
    },

    {
        type: 'question',
        step: 17,
        nextStep: 18,
        prevStep: 16,
        mainQuestion: 3,
        progress: 90,
        analyticsKey: 'how_far',
        title: '現在地からどのくらい離れた場所で釣りをしますか?',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: '最大 40 km',
                value: '40_kilometers',
            },
            {
                type: 'radio',
                text: '最大 80 km',
                value: '80_kilometers',
            },
            {
                type: 'radio',
                text: '最大 160 km',
                value: '160_kilometers',
            },
            {
                type: 'radio',
                text: '最大 320 km',
                value: '320_kilometers',
            },
            {
                type: 'radio',
                text: '320 km 以上',
                value: 'more_320_kilometers',
            },
        ],
    },

    {
        type: 'loading',
        step: 18,
        nextStep: 19,
        withoutButton: true,
        slideImg: '',
        data: {
            eventFillName: 'analyzing answers',
            nextRoute: '/onboarding/PersonalizationD2',
            title: '回答を分析しています...',
            subtitle: 'Trusted by over 149,569 people',
            loadingArrays: [
                {
                    text: '近くの水域を分析',
                    title: '回答を分析しています...',
                    color: '#5682F2',
                    steps: [
                        {
                            finish: 30,
                            timeout: 133,
                        },
                        {
                            finish: 70,
                            timeout: 70,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
                {
                    text: '食いつき活動を計算',
                    color: '#4ABCE1',
                    title: '気象パラメータを分析しています...',
                    steps: [
                        {
                            finish: 30,
                            timeout: 133,
                        },
                        {
                            finish: 70,
                            timeout: 50,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
                {
                    text: 'あなた専用のマップを作成しています',
                    color: '#39CAB9',
                    title: 'お近くのホットスポットを探しています..',
                    steps: [
                        {
                            finish: 30,
                            timeout: 100,
                        },
                        {
                            finish: 70,
                            timeout: 50,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
            ],
            loadingArraysSecond: [
                {
                    text: 'Generating Your Personal Map',
                    color: '#39CAB9',
                    title: 'Looking for hotspots near you...',
                    titles: [
                        {
                            text: 'Analyzing weather parameters...',
                            percent: 0,
                        },
                        {
                            text: 'Looking for hotspots near you...',
                            percent: 64,
                        },
                        {
                            text: 'Your personal map is complete',
                            percent: 98,
                        },
                    ],

                    steps: [
                        {
                            finish: 30,
                            timeout: 200,
                        },
                        {
                            finish: 70,
                            timeout: 100,
                        },
                        {
                            finish: 100,
                            timeout: 66,
                        },
                    ],
                },
            ],
            loadingArraysThird: TWEAKS_LOADERS.analyze_loaders,
            loadingTextArray: [
                {
                    startPercent: 0,
                    finishPercent: 50,
                    text: 'Connecting to database',
                },
                {
                    startPercent: 51,
                    finishPercent: 99,
                    text: 'Preliminary processing',
                },
            ],
        },
    },

    {
        type: 'email',
        step: 19,
        nextStep: 20,
        analyticsKey: 'email',
        withoutButton: true,
        title: 'あなた専用の釣りスポットと予報の送信先',
        desc: 'Enter your email to get started',
        buttonText: '私の地図を見る',
    },

    {
        type: 'landing',
        step: 20,
    },
];

const stepsJpSalt = [
    {
        type: 'first_screen',
        mainQuestion: 1,
        progress: 20,
        step: -1,
        withoutButton: true,
        noSet: true,
        screens: {
            1: {
                img: firstScreenJp,
                title: 'あなた専用の釣り場と予報',
                text: 'クイズに答えて、あなた専用の釣り場マップと最適な釣りの時間帯を入手しましょう',
                question: 'いつも釣りをする環境を選択してください:',
                nextStep: 1,
                prevStep: 0,
                analyticsKey: 'type_of_water',
                answers: [
                    {
                        type: 'button',
                        text: '淡水',
                        img: fresh,
                        value: 'fresh',
                    },
                    {
                        type: 'button',
                        text: '海水',
                        img: salt,
                        value: 'salt',
                    },
                ],
            },
            2: {
                img: firstScreenJp,
                imgDesc: '*828 top spots in US',
                title: 'Discover new fishing spots nearby tailored to your fishing preferences',
                nextStep: 1,
                prevStep: 0,
                loadingArrays: [
                    {
                        title: 'Loading the test',
                        color: '#0A364A',
                        colorLoader: '#5682F2',
                        steps: [
                            {
                                finish: 30,
                                timeout: 133,
                            },
                            {
                                finish: 70,
                                timeout: 70,
                            },
                            {
                                finish: 100,
                                timeout: 33,
                            },
                        ],
                    },
                ],
            },
            [FIRST_SCREEN_VARIANT_WATER_V1]: {
                img: firstScreen,
                title: 'Your Fishing Map is Almost Ready!',
                text: 'Just answer 3 quick questions to customize it.',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                mainQuestion: 1,
                prevStep: 0,
                progress: 20,
                analyticsKey: 'type_of_water',
                answerType: 'single',
                answers: [
                    {
                        type: 'with_img',
                        text: 'Fresh',
                        img: freshFS,
                        value: 'fresh',
                    },
                    {
                        type: 'with_img',
                        text: 'Salt',
                        img: saltFS,
                        value: 'salt',
                    },
                ],
            },
            [FIRST_SCREEN_VARIANT_WATER_V2]: {
                title: ' Find the best fishing spots near you',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                mainQuestion: 1,
                prevStep: 0,
                progress: 20,
                analyticsKey: 'type_of_water',
                answerType: 'single',
                answers: [
                    {
                        text: 'Fresh',
                        type: 'button',
                        value: 'fresh',
                    },
                    {
                        text: 'Salt',
                        type: 'button',
                        value: 'salt',
                    },
                ],
            },
        },
    },
    {
        type: 'question',
        step: 0,
        analyticsKey: 'type_of_water',
        nextStep: 1,
        mainQuestion: 1,
        progress: 0,
        title: 'Discover Your Perfect Fishing Map',
        text: 'Answer a quick customized quiz to receive your hotspots map and 7-day fishing forecast.',
        desc: 'Select the type of water you usually fish in:',
        answerType: 'single',
        answers: [
            {
                type: 'with_img',
                text: 'Fresh',
                img: fresh,
                value: 'fresh',
            },
            {
                type: 'with_img',
                text: 'Salt',
                img: salt,
                value: 'salt',
            },
            {
                type: 'radio',
                text: 'Both',
                value: 'both',
                textStyles: '!text-center',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 1,
        nextStep: 2,
        prevStep: 0,
        prevStepAlt: -1,
        progress: 15,
        analyticsKey: 'where_are_you_fishing',
        title: '一番好きな釣り場は？',
        help: '複数選択できます。',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: '岸壁や堤防',
                img: jpShoreSalt,
                value: 'bank',
            },
            {
                type: 'with_img',
                text: '船またはカヤック',
                img: jpBoatSalt,
                value: 'boat',
            },

            {
                type: 'with_img',
                text: '氷上',
                img: jpIce,
                value: 'ice',
            },
            {
                type: 'with_img',
                text: '水中',
                img: jpWater,
                value: 'in_water',
            },
            {
                type: 'with_img',
                text: '防波堤や桟橋',
                img: jpPierSalt,
                value: 'pier_or_dock',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 2,
        nextStep: 3,
        prevStep: 1,
        progress: 30,
        analyticsKey: 'what_waterbodies',
        title: 'いつもどのような水域で釣りをしていますか？',
        help: '複数選択できます。',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: '湖',
                img: jpLakes,
                value: 'lakes',
            },
            {
                type: 'with_img',
                text: '湾',
                img: jpBay,
                value: 'bay',
            },
            {
                type: 'with_img',
                text: '大洋',
                img: jpOcean,
                value: 'ocean',
            },
            {
                type: 'with_img',
                text: '海',
                img: jpSea,
                value: 'sea',
            },
            {
                type: 'with_img',
                text: '河口',
                img: jpEstuary,
                value: 'estuary',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 3,
        nextStep: 4,
        prevStep: 2,
        progress: 45,
        analyticsKey: 'what_fish',
        title: 'いつもどんな魚をねらっていますか？',
        text: 'この情報は、あなたの好みを理解するのに役立ちます。',
        help: '複数選択できます。',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'スズキ',
                img: jpSeabass,
                value: 'japaneses_seabass',
            },
            {
                type: 'with_img',
                text: 'アメリカナマズ',
                img: channel,
                value: 'channel_catfish',
            },
            {
                type: 'with_img',
                text: 'ブラウントラウト',
                img: brown,
                value: 'brown_trout',
            },
            {
                type: 'with_img',
                text: 'ヒラメ',
                img: flounder,
                value: 'flounder',
            },
            {
                type: 'with_img',
                text: 'ハタ',
                img: grouper,
                value: 'grouper',
            },
            {
                type: 'with_img',
                text: 'ニジマス',
                img: rainbowTrout,
                value: 'rainbow_trout',
            },
            {
                type: 'with_img',
                text: 'ウナギ',
                img: jpEel,
                value: 'japanese_eel',
            },
            {
                type: 'with_img',
                text: 'マダイ',
                img: redSeabream,
                value: 'red_seabream',
            },
            {
                type: 'with_img',
                text: 'キハダマグロ',
                img: yellowfinTuna,
                value: 'yellowfin_tuna',
            },
            {
                type: 'with_img',
                text: 'メバチマグロ',
                img: bigeyeTunaImg,
                value: 'bigeye_tuna',
            },
            {
                type: 'with_img',
                text: 'サワラ',
                img: jpMackrele,
                value: 'japanese_spanish_mackrel',
            },

            {
                type: 'with_img',
                text: 'ボラ',
                img: boraImg,
                value: 'bora',
            },
            {
                type: 'with_img',
                text: 'ハマチ',
                img: jpYellowtail,
                value: 'japanese_yellowtail',
            },

            {
                type: 'with_img',
                text: 'シロザケ',
                img: chumSalmonImg,
                value: 'chum_salmon',
            },

            {
                type: 'with_img',
                text: 'その他の海水魚',
                img: otherSalt,
                imgStyles: 'w-[93px] h-[88px] !min-h-[auto] !min-w-[auto]',
                value: 'other_saltwater_fish',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 4,
        nextStep: 5,
        prevStep: 3,
        progress: 60,
        analyticsKey: 'what_time',
        title: 'いつも釣りに行く時間帯',
        help: '複数選択できます。',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: '早朝 (午前 5 時～午前 9 時)',
                value: 'early_morning',
            },
            {
                type: 'with_tick',
                text: '深夜 (午前 9 時～午後 12 時)',
                value: 'late_morning',
            },
            {
                type: 'with_tick',
                text: '午後 (午後 12 時～午後 6 時)',
                value: 'afternoon',
            },
            {
                type: 'with_tick',
                text: '夕方 (午後 6 時～午後 10 時)',
                value: 'late_evening',
            },
            {
                type: 'with_tick',
                text: '夜間 (午後 10 時～午前 5 時)',
                value: 'night_time',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 5,
        nextStep: 6,
        prevStep: 4,
        progress: 75,
        analyticsKey: 'what_angling_method',
        title: 'いつもどのような釣り方をしますか？',
        help: '複数選択できます。',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: '底釣り',
                value: 'bottom_fishing',
            },
            {
                type: 'with_tick',
                text: 'フライフィッシング',
                value: 'casting',
            },
            {
                type: 'with_tick',
                text: 'ジギング',
                value: 'spin_fishing',
            },
            {
                type: 'with_tick',
                text: 'トローリング',
                value: 'jigging',
            },
            {
                type: 'with_tick',
                text: 'キャスティング',
                value: 'trolling',
            },
            {
                type: 'with_tick',
                text: '投げ釣り',
                value: 'fly_fishing',
            },
            {
                type: 'with_tick',
                text: '手釣り',
                value: 'other_method',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 1,
        step: 6,
        nextStep: 7,
        prevStep: 5,
        progress: 90,
        analyticsKey: 'what_kind_of_lures',
        title: '最もよく使うエサやルアーは？',
        help: '複数選択できます。',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'フライ',
                img: flies,
                value: 'flies',
            },
            {
                type: 'with_img',
                text: 'ジグ',
                img: jigs,
                value: 'jigs',
            },
            {
                type: 'with_img',
                text: 'プラグ',
                img: plugs,
                value: 'plugs',
            },
            {
                type: 'with_img',
                text: 'スプーン',
                img: spoons,
                value: 'spoons',
            },
            {
                type: 'with_img',
                text: 'スピナーベイトとバズベイト',
                img: spinner,
                value: 'spinnerbaits_buzzbaits',
            },
            {
                type: 'with_img',
                text: 'ソフトベイトルアー',
                img: soft,
                value: 'soft_bait_lures',
            },
            {
                type: 'with_img',
                text: 'ポッパーとサーフェスルアー',
                img: poppersImg,
                value: 'poppers',
            },
            {
                type: 'with_img',
                text: 'その他のルア',
                img: other,
                imgStyles: 'object-fit-contain',
                value: 'other_lures',
            },
            {
                type: 'with_img',
                text: '魚餌',
                img: fishBait,
                imgStyles: '!object-contain',
                value: 'fish_bait',
            },
            {
                type: 'with_img',
                text: '貝類',
                img: shellFish,
                imgStyles: '!object-contain',
                value: 'shell_fish',
            },

            {
                type: 'with_img',
                text: 'その他の餌',
                img: othSalt,
                value: 'other_salt',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 1,
        step: 7,
        nextStep: 8,
        prevStep: 6,
        progress: 95,
        analyticsKey: 'in_which_layer_of_water',
        title: '釣りの水深は？',
        help: '複数選択できます。',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: '水深 1～2 メートル',
                value: '1_2_feet_deep',
            },
            {
                type: 'with_tick',
                text: '水深 3～5 メートル',
                value: '3_5_feet_deep',
            },
            {
                type: 'with_tick',
                text: '水深 6～9 メートル',
                value: '6_9_feet_deep',
            },
            {
                type: 'with_tick',
                text: '水深 10 メートル以上',
                value: '10_feet_deep',
            },
        ],
    },

    {
        type: 'screen_1',
        mainQuestion: 2,
        step: 8,
        nextStep: 9,
        progress: 0,
        analyticsKey: 'our_algorithms',
        withOutAnalyticsValue: true,
        title: '当社のアルゴリズムは 21 のパラメータに基づいています',
        text: ' リアルタイムの傾向に基づいて、あなたの地域で釣りに最適な場所をご案内します。常に多くの魚がいる場所で釣りができ、毎回、短時間で釣りの準備ができます。',
        help: 'Fishbox™ Team',
        img: screen1,
        buttonText: 'いいですね',
        isButtonEnabled: true,
    },

    {
        type: 'question',
        step: 9,
        nextStep: 10,
        prevStep: 8,
        mainQuestion: 2,
        progress: 20,
        analyticsKey: 'what_describes_you_better',
        title: '最もよく当てはまるのを選択してください?',
        text: ' この情報は、お客様の体験を把握するのに役立ちます',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: '初心者',
                value: 'beginner',
            },
            {
                type: 'radio',
                text: 'アマチュア',
                value: 'amateur',
            },
            {
                type: 'radio',
                text: '経験豊富',
                value: 'experienced',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 2,
        step: 10,
        nextStep: 11,
        prevStep: 9,
        progress: 40,
        analyticsKey: 'who_do_you_go_fishing_with',
        title: 'いつも誰と釣りに行きますか？',
        help: '複数選択できます。',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: '一人',
                value: 'alone',
            },
            {
                type: 'with_tick',
                text: '家族',
                value: 'family',
            },
            {
                type: 'with_tick',
                text: '友達',
                value: 'friends',
            },
            {
                type: 'with_tick',
                text: 'プロのガイド',
                value: 'professional_guide',
            },
            {
                type: 'with_tick',
                text: '仕事仲間',
                value: 'business_associates',
            },
        ],
    },

    {
        type: 'question',
        step: 11,
        nextStep: 12,
        prevStep: 10,
        mainQuestion: 2,
        progress: 60,
        analyticsKey: 'do_you_agree',
        title: '次のことに共感しますか？',
        desc: '混雑した釣り場は好きではない',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: '完全に共感する',
                value: 'completely_agree',
            },
            {
                type: 'radio',
                text: '共感する',
                value: 'agree',
            },
            {
                type: 'radio',
                text: 'どちらでもない',
                value: 'neutral',
            },
            {
                type: 'radio',
                text: '共感する',
                value: 'disagree',
            },
            {
                type: 'radio',
                text: '完全に共感する',
                value: 'totally_disagree',
            },
        ],
    },

    {
        type: 'question',
        step: 12,
        nextStep: 13,
        prevStep: 11,
        mainQuestion: 2,
        progress: 80,
        analyticsKey: 'how_often_disappointed',
        title: '釣り旅行でがっかりしたことはどのくらいの頻度でありますか？',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'よくある',
                value: 'often',
            },
            {
                type: 'radio',
                text: 'ときどきある',
                value: 'sometimes',
            },
            {
                type: 'radio',
                text: 'ほとんどない',
                value: 'rarely',
            },
            {
                type: 'radio',
                text: '一切ない',
                value: 'never',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 2,
        step: 13,
        nextStep: 14,
        prevStep: 12,
        progress: 90,
        analyticsKey: 'what_difficulties',
        title: 'あなたにとって釣りで一番困っていることは何ですか?',
        help: '複数選択できます。',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'いつ釣りをすればよいか分からない',
                value: 'I don’t know when to fish',
            },
            {
                type: 'with_tick',
                text: '魚の活性が高い場所が分からない',
                value: 'I don’t know where fish are active',
            },
            {
                type: 'with_tick',
                text: '必要な道具が分からない',
                value: 'I don’t know what gear I need',
            },
            {
                type: 'with_tick',
                text: '釣り場のルールや規制が分からない',
                value: "'I don't know spot rules and regulations on my location'",
            },
            {
                type: 'with_tick',
                text: '悪天候',
                value: 'bad_weather',
            },

            {
                type: 'with_tick',
                text: '釣り道具が高価すぎる',
                value: 'too_expensive',
            },
            {
                type: 'with_tick',
                text: '釣りをする時間が足りない',
                value: 'enough_time',
            },
        ],
    },

    {
        type: 'screen_2',
        mainQuestion: 3,
        step: 14,
        nextStep: 15,
        progress: 0,
        img: screen2Jp,
        saltImg: screen2JpSalt,
        analyticsKey: 'you_are_not_alone',
        withOutAnalyticsValue: true,
        buttonText: '続ける',
        isButtonEnabled: true,
        dynamicText:
            'あきらめないで、続けて質問に答えてください。そうすれば、次回、デカい魚やお気に入りのスポットが見つかるでしょう。」',
    },

    {
        type: 'state',
        mainQuestion: 3,
        step: 15,
        nextStep: 16,
        prevStep: 14,
        progress: 30,
        analyticsKey: 'your_state',
        buttonText: '続ける',
        titleText: '都道府県を選択してください',
    },

    {
        type: 'city',
        mainQuestion: 3,
        step: 16,
        nextStep: 17,
        prevStep: 15,
        progress: 60,
        analyticsKey: 'your_city',
        img: city,
        buttonText: '続ける',
    },

    {
        type: 'question',
        step: 17,
        nextStep: 18,
        prevStep: 16,
        mainQuestion: 3,
        progress: 90,
        analyticsKey: 'how_far',
        title: '現在地からどのくらい離れた場所で釣りをしますか?',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: '最大 40 km',
                value: '40_kilometers',
            },
            {
                type: 'radio',
                text: '最大 80 km',
                value: '80_kilometers',
            },
            {
                type: 'radio',
                text: '最大 160 km',
                value: '160_kilometers',
            },
            {
                type: 'radio',
                text: '最大 320 km',
                value: '320_kilometers',
            },
            {
                type: 'radio',
                text: '320 km 以上',
                value: 'more_320_kilometers',
            },
        ],
    },

    {
        type: 'loading',
        step: 18,
        nextStep: 19,
        withoutButton: true,
        slideImg: '',
        data: {
            eventFillName: 'analyzing answers',
            nextRoute: '/onboarding/PersonalizationD2',
            title: '回答を分析しています...',
            subtitle: 'Trusted by over 149,569 people',
            loadingArrays: [
                {
                    text: '近くの水域を分析',
                    title: '回答を分析しています...',
                    color: '#5682F2',
                    steps: [
                        {
                            finish: 30,
                            timeout: 133,
                        },
                        {
                            finish: 70,
                            timeout: 70,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
                {
                    text: '食いつき活動を計算',
                    color: '#4ABCE1',
                    title: '気象パラメータを分析しています...',
                    steps: [
                        {
                            finish: 30,
                            timeout: 133,
                        },
                        {
                            finish: 70,
                            timeout: 50,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
                {
                    text: 'あなた専用のマップを作成しています',
                    color: '#39CAB9',
                    title: 'お近くのホットスポットを探しています..',
                    steps: [
                        {
                            finish: 30,
                            timeout: 100,
                        },
                        {
                            finish: 70,
                            timeout: 50,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
            ],
            loadingArraysSecond: [
                {
                    text: 'Generating Your Personal Map',
                    color: '#39CAB9',
                    title: 'Looking for hotspots near you...',
                    titles: [
                        {
                            text: 'Analyzing weather parameters...',
                            percent: 0,
                        },
                        {
                            text: 'Looking for hotspots near you...',
                            percent: 64,
                        },
                        {
                            text: 'Your personal map is complete',
                            percent: 98,
                        },
                    ],

                    steps: [
                        {
                            finish: 30,
                            timeout: 200,
                        },
                        {
                            finish: 70,
                            timeout: 100,
                        },
                        {
                            finish: 100,
                            timeout: 66,
                        },
                    ],
                },
            ],
            loadingArraysThird: TWEAKS_LOADERS.analyze_loaders,
            loadingTextArray: [
                {
                    startPercent: 0,
                    finishPercent: 50,
                    text: 'Connecting to database',
                },
                {
                    startPercent: 51,
                    finishPercent: 99,
                    text: 'Preliminary processing',
                },
            ],
        },
    },

    {
        type: 'email',
        step: 19,
        nextStep: 20,
        analyticsKey: 'email',
        withoutButton: true,
        title: 'あなた専用の釣りスポットと予報の送信先',
        desc: 'Enter your email to get started',
        buttonText: '私の地図を見る',
    },

    {
        type: 'landing',
        step: 20,
    },
];

const stepsCa = [
    {
        type: 'first_screen',
        mainQuestion: 1,
        progress: 20,
        step: -1,
        withoutButton: true,
        noSet: true,
        screens: {
            1: {
                img: firstScreen,
                title: 'Find the best fishing spots near you',
                text: 'Answer a quick quiz to receive your personal hot spots map',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                prevStep: 0,
                analyticsKey: 'type_of_water',
                answers: [
                    {
                        type: 'button',
                        text: 'Fresh',
                        img: fresh,
                        value: 'fresh',
                    },
                    {
                        type: 'button',
                        text: 'Salt',
                        img: salt,
                        value: 'salt',
                    },
                ],
            },
            2: {
                img: firstScreen,
                imgDesc: '*828 top spots in US',
                title: 'Discover new fishing spots nearby tailored to your fishing preferences',
                nextStep: 1,
                prevStep: 0,
                loadingArrays: [
                    {
                        title: 'Loading the test',
                        color: '#0A364A',
                        colorLoader: '#5682F2',
                        steps: [
                            {
                                finish: 30,
                                timeout: 133,
                            },
                            {
                                finish: 70,
                                timeout: 70,
                            },
                            {
                                finish: 100,
                                timeout: 33,
                            },
                        ],
                    },
                ],
            },
            [FIRST_SCREEN_VARIANT_WATER_V1]: {
                img: firstScreen,
                title: 'Your Fishing Map is Almost Ready!',
                text: 'Just answer 3 quick questions to customize it.',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                mainQuestion: 1,
                prevStep: 0,
                progress: 20,
                analyticsKey: 'type_of_water',
                answerType: 'single',
                answers: [
                    {
                        type: 'with_img',
                        text: 'Fresh',
                        img: freshFS,
                        value: 'fresh',
                    },
                    {
                        type: 'with_img',
                        text: 'Salt',
                        img: saltFS,
                        value: 'salt',
                    },
                ],
            },
            [FIRST_SCREEN_VARIANT_WATER_V2]: {
                title: ' Find the best fishing spots near you',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                mainQuestion: 1,
                prevStep: 0,
                progress: 20,
                analyticsKey: 'type_of_water',
                answerType: 'single',
                answers: [
                    {
                        text: 'Fresh',
                        type: 'button',
                        value: 'fresh',
                    },
                    {
                        text: 'Salt',
                        type: 'button',
                        value: 'salt',
                    },
                ],
            },
        },
    },
    {
        type: 'question',
        step: 0,
        analyticsKey: 'type_of_water',
        nextStep: 1,
        mainQuestion: 1,
        progress: 0,
        title: 'Discover Your Perfect Fishing Map',
        text: 'Answer a quick customized quiz to receive your hotspots map and 7-day fishing forecast.',
        desc: 'Select the type of water you usually fish in:',
        answerType: 'single',
        answers: [
            {
                type: 'with_img',
                text: 'Fresh us',
                img: fresh,
                value: 'fresh',
            },
            {
                type: 'with_img',
                text: 'Salt',
                img: salt,
                value: 'salt',
            },
            {
                type: 'radio',
                text: 'Both',
                value: 'both',
                textStyles: '!text-center',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 1,
        nextStep: 2,
        prevStep: 0,
        prevStepAlt: -1,
        progress: 20,
        analyticsKey: 'where_are_you_fishing',
        title: 'Where are you enjoying fishing most of all?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Boat or Kayak',
                img: boat,
                value: 'boat_or_kayak',
            },
            {
                type: 'with_img',
                text: 'Shore or Bank',
                img: off,
                value: 'shore_or_bank',
            },
            {
                type: 'with_img',
                text: 'Pier or dock',
                img: pier,
                value: 'pier_or_dock',
            },
            {
                type: 'with_img',
                text: 'In water',
                img: wading,
                value: 'in_water',
            },
            {
                type: 'with_img',
                text: 'Ice',
                img: caIce,
                value: 'ice',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 2,
        nextStep: 3,
        prevStep: 1,
        progress: 30,
        analyticsKey: 'what_waterbodies',
        title: 'What waterbodies you usually fish at?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Lakes',
                img: lake,
                value: 'lakes',
            },
            {
                type: 'with_img',
                text: 'Rivers',
                img: rivers,
                value: 'rivers',
            },
            {
                type: 'with_img',
                text: 'Ponds',
                img: ponds,
                value: 'ponds',
            },
            {
                type: 'with_img',
                text: 'Creeks',
                img: creeks,
                value: 'creeks',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 3,
        nextStep: 4,
        prevStep: 2,
        progress: 45,
        analyticsKey: 'what_fish',
        title: 'What fish species do you usually target?',
        text: 'This information will help us understand your preferences.',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Northern pike',
                img: north,
                value: 'northern_pike',
            },
            {
                type: 'with_img',
                text: 'Chinook Salmon ',
                img: chinookSalmon,
                value: 'chinook_salmon',
            },
            {
                type: 'with_img',
                text: 'Largemouth Bass',
                img: larg,
                value: 'largemous_bass',
            },
            {
                type: 'with_img',
                text: 'Rainbow Trout',
                img: rainbowTrout,
                value: 'rainbow_trout',
            },
            {
                type: 'with_img',
                text: 'Catfish',
                img: catfishCa,
                value: 'catfish',
            },
            {
                type: 'with_img',
                text: 'Atlantic Salmon',
                img: atlSalmon,
                value: 'atlantic_salmon',
            },
            {
                type: 'with_img',
                text: 'Common Carp',
                img: commonCarp,
                value: 'common_carp',
            },
            {
                type: 'with_img',
                text: 'Walleye',
                img: walleye,
                value: 'walleye',
            },
            {
                type: 'with_img',
                text: 'Yellow Perch',
                img: yellowPerch,
                value: 'yellow_perch',
            },
            {
                type: 'with_img',
                text: 'Lake Trout',
                img: lakeTrout,
                value: 'lake_trout',
            },
            {
                type: 'with_img',
                text: 'Smallmouth Bass',
                img: small,
                value: 'smallmouth_bass',
            },
            {
                type: 'with_img',
                text: 'Crappie',
                img: crappie,
                value: 'crappie',
            },
            {
                type: 'with_img',
                text: 'Muskie',
                img: muskie,
                value: 'muskie',
            },
            {
                type: 'with_img',
                text: 'Other freshwater fish',
                img: otherFresh,
                imgStyles: 'w-[93px] h-[88px] !min-h-[auto] !min-w-[auto]',
                value: 'other_freshwater_fish',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 4,
        nextStep: 5,
        prevStep: 3,
        progress: 60,
        analyticsKey: 'what_time',
        title: 'What time intervals you usually choose to plan your fishing trip?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'Early morning (from 5 a.m. till 9 a.m.)',
                value: 'early_morning',
            },
            {
                type: 'with_tick',
                text: 'Late morning (from 9 a.m. till 12 p.m.)',
                value: 'late_morning',
            },
            {
                type: 'with_tick',
                text: 'Afternoon (from 12 p.m. till 6 p.m.)',
                value: 'afternoon',
            },
            {
                type: 'with_tick',
                text: 'Late evening (from 6 p.m. till 10 p.m.)',
                value: 'late_evening',
            },
            {
                type: 'with_tick',
                text: 'Night time (from 10 p.m. till 5 a.m.)',
                value: 'night_time',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 5,
        nextStep: 6,
        prevStep: 4,
        progress: 75,
        analyticsKey: 'what_angling_method',
        title: 'What angling method do you usually use?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'Bottom fishing',
                value: 'bottom_fishing',
            },
            {
                type: 'with_tick',
                text: 'Fly-fishing',
                value: 'fly_fishing',
            },
            {
                type: 'with_tick',
                text: 'Spin fishing',
                value: 'spin_fishing',
            },
            {
                type: 'with_tick',
                text: 'Jigging',
                value: 'jigging',
            },
            {
                type: 'with_tick',
                text: 'Trolling',
                value: 'trolling',
            },
            {
                type: 'with_tick',
                text: 'Casting',
                value: 'casting',
            },
            {
                type: 'with_tick',
                text: 'Other method',
                value: 'other_method',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 1,
        step: 6,
        nextStep: 7,
        prevStep: 5,
        progress: 90,
        analyticsKey: 'what_kind_of_lures',
        title: 'What kind of fishing baits/lures do you use most often?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Flies',
                img: flies,
                value: 'flies',
            },
            {
                type: 'with_img',
                text: 'Jigs',
                img: jigs,
                value: 'jigs',
            },
            {
                type: 'with_img',
                text: 'Spinnerbaits & Buzzbaits',
                img: spinner,
                value: 'spinnerbaits_buzzbaits',
            },
            {
                type: 'with_img',
                text: 'Plugs',
                img: plugs,
                value: 'plugs',
            },
            {
                type: 'with_img',
                text: 'Spoons',
                img: spoons,
                value: 'spoons',
            },
            {
                type: 'with_img',
                text: 'Soft bait lures',
                img: soft,
                value: 'soft_bait_lures',
            },
            {
                type: 'with_img',
                text: 'Other lures',
                img: other,
                imgStyles: 'w-[93px] h-[88px] !min-h-[auto] !min-w-[auto]',
                value: 'other_lures',
            },
            {
                type: 'with_img',
                text: 'Insects',
                img: insects,
                value: 'insects',
            },
            {
                type: 'with_img',
                text: 'Minnows',
                img: minnows,
                value: 'minnows',
            },
            {
                type: 'with_img',
                text: 'Maggots',
                img: maggots,
                value: 'maggots',
            },
            {
                type: 'with_img',
                text: 'Worms',
                img: worms,
                value: 'worms',
            },
            {
                type: 'with_img',
                text: 'Other baits',
                img: baits,
                value: 'other_baits',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 1,
        step: 7,
        nextStep: 8,
        prevStep: 6,
        progress: 95,
        analyticsKey: 'in_which_layer_of_water',
        title: 'In which layer of water do you usually catch fish?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'I like to fish in shallow water, around 1-2 meters deep',
                value: '1_2_meters_deep',
            },
            {
                type: 'with_tick',
                text: 'It depends on my current target species, but I usually fish in a 3-5 meters deep water',
                value: '3_5_meters_deep',
            },
            {
                type: 'with_tick',
                text: 'I prefer to fish in deeper water, around 6-9 meters deep',
                value: '6_9_meters_deep',
            },
            {
                type: 'with_tick',
                text: 'I fish in water that is 10+ meters deep',
                value: '10+_meters_deep',
            },
        ],
    },

    {
        type: 'screen_1',
        mainQuestion: 2,
        step: 8,
        nextStep: 9,
        progress: 0,
        analyticsKey: 'our_algorithms',
        withOutAnalyticsValue: true,
        title: 'Our algorithms are based on 21 parameters',
        text: "We'll literally show you the best spots to fish in your area based on real-time trends, which means you'll always fish in areas filled with fish, and be spared from long preparations for each trip.",
        help: 'Fishbox™ Team',
        img: screen1,
        buttonText: 'Sounds good',
        isButtonEnabled: true,
    },

    {
        type: 'question',
        step: 9,
        nextStep: 10,
        prevStep: 8,
        mainQuestion: 2,
        progress: 20,
        analyticsKey: 'what_describes_you_better',
        title: 'What describes you better?',
        text: 'This information will help us understand your experience.',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'I’m a beginner',
                value: 'beginner',
            },
            {
                type: 'radio',
                text: 'I’m an amateur',
                value: 'amateur',
            },
            {
                type: 'radio',
                text: 'I’m an experienced angler',
                value: 'experienced',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 2,
        step: 10,
        nextStep: 11,
        prevStep: 9,
        progress: 40,
        analyticsKey: 'who_do_you_go_fishing_with',
        title: 'Who do you usually go fishing with?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'I fish alone',
                value: 'alone',
            },
            {
                type: 'with_tick',
                text: 'With family members',
                value: 'family',
            },
            {
                type: 'with_tick',
                text: 'With friends',
                value: 'friends',
            },
            {
                type: 'with_tick',
                text: 'With a professional guide',
                value: 'professional_guide',
            },
            {
                type: 'with_tick',
                text: 'With coworkers or business associates',
                value: 'business_associates',
            },
        ],
    },

    {
        type: 'question',
        step: 11,
        nextStep: 12,
        prevStep: 10,
        mainQuestion: 2,
        progress: 60,
        analyticsKey: 'do_you_agree',
        title: 'Do you agree with the following:',
        desc: "“I don't like crowded fishing spots”",
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'Completely agree',
                value: 'completely_agree',
            },
            {
                type: 'radio',
                text: 'Agree',
                value: 'agree',
            },
            {
                type: 'radio',
                text: 'Neutral',
                value: 'neutral',
            },
            {
                type: 'radio',
                text: 'Disagree',
                value: 'disagree',
            },
            {
                type: 'radio',
                text: 'Totally disagree',
                value: 'totally_disagree',
            },
        ],
    },

    {
        type: 'question',
        step: 12,
        nextStep: 13,
        prevStep: 11,
        mainQuestion: 2,
        progress: 80,
        analyticsKey: 'how_often_disappointed',
        title: 'How often are you disappointed with your fishing trip?',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'Often',
                value: 'often',
            },
            {
                type: 'radio',
                text: 'Sometimes',
                value: 'sometimes',
            },
            {
                type: 'radio',
                text: 'Rarely',
                value: 'rarely',
            },
            {
                type: 'radio',
                text: 'Never',
                value: 'never',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 2,
        step: 13,
        nextStep: 14,
        prevStep: 12,
        progress: 90,
        analyticsKey: 'what_difficulties',
        title: 'What difficulties are you experiencing most often?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: "I don't know when fish is biting",
                value: 'fish_is_biting',
            },
            {
                type: 'with_tick',
                text: " I don't know spots where fish are active",
                value: 'spots',
            },
            {
                type: 'with_tick',
                text: "I don't know what fish are habitat in a particular spot",
                value: 'fish_are_habitat',
            },
            {
                type: 'with_tick',
                text: " I don't know what gear I need",
                value: 'what_gear',
            },
            {
                type: 'with_tick',
                text: "I don't know spot's rules and regulations",
                value: 'regulations',
            },
            {
                type: 'with_tick',
                text: 'Bad weather',
                value: 'bad_weather',
            },
            {
                type: 'with_tick',
                text: 'Fishing gear is a way too expensive',
                value: 'too_expensive',
            },
            {
                type: 'with_tick',
                text: "I don't have enough time 😭",
                value: 'enough_time',
            },
        ],
    },

    {
        type: 'screen_2',
        mainQuestion: 3,
        step: 14,
        nextStep: 15,
        progress: 0,
        img: screen2,
        analyticsKey: 'you_are_not_alone',
        withOutAnalyticsValue: true,
        buttonText: 'Continue',
        isButtonEnabled: true,
    },

    {
        type: 'state',
        mainQuestion: 3,
        step: 15,
        nextStep: 16,
        prevStep: 14,
        progress: 30,
        analyticsKey: 'your_state',
        buttonText: 'Continue',
        titleText: 'Choose your province or territory:',
    },

    {
        type: 'city',
        mainQuestion: 3,
        step: 16,
        nextStep: 17,
        prevStep: 15,
        progress: 60,
        analyticsKey: 'your_city',
        img: city,
        buttonText: 'Continue',
    },

    {
        type: 'question',
        step: 17,
        nextStep: 18,
        prevStep: 16,
        mainQuestion: 3,
        progress: 90,
        analyticsKey: 'how_far',
        title: 'How far from the city are you ready to go on your fishing trip?',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'Up to 40 kilometers',
                value: '40_kilometers',
            },
            {
                type: 'radio',
                text: 'Up to 80 kilometers',
                value: '80_kilometers',
            },
            {
                type: 'radio',
                text: 'Up to 160 kilometers',
                value: '160_kilometers',
            },
            {
                type: 'radio',
                text: 'Up to 320 kilometers',
                value: '320_kilometers',
            },
            {
                type: 'radio',
                text: 'Up to 320+ kilometers',
                value: 'more_320_kilometers',
            },
        ],
    },

    {
        type: 'loading',
        step: 18,
        nextStep: 19,
        withoutButton: true,
        data: {
            eventFillName: 'analyzing answers',
            nextRoute: '/onboarding/PersonalizationD2',
            title: 'Analyzing your answers...',
            subtitle: 'Trusted by over 149,569 people',
            loadingArrays: [
                {
                    text: 'Analysis of water bodies nearby',
                    title: 'Analyzing your answers...',
                    color: '#5682F2',
                    steps: [
                        {
                            finish: 30,
                            timeout: 133,
                        },
                        {
                            finish: 70,
                            timeout: 70,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
                {
                    text: 'Calculation of biting activity',
                    color: '#4ABCE1',
                    title: 'Analyzing weather parameters...',
                    steps: [
                        {
                            finish: 30,
                            timeout: 133,
                        },
                        {
                            finish: 70,
                            timeout: 50,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
                {
                    text: 'Generating Your Personal Map',
                    color: '#39CAB9',
                    title: 'Looking for hotspots near you...',
                    steps: [
                        {
                            finish: 30,
                            timeout: 100,
                        },
                        {
                            finish: 70,
                            timeout: 50,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
            ],
            loadingArraysSecond: [
                {
                    text: 'Generating Your Personal Map',
                    color: '#39CAB9',
                    title: 'Looking for hotspots near you...',
                    titles: [
                        {
                            text: 'Analyzing weather parameters...',
                            percent: 0,
                        },
                        {
                            text: 'Looking for hotspots near you...',
                            percent: 64,
                        },
                        {
                            text: 'Your personal map is complete',
                            percent: 98,
                        },
                    ],

                    steps: [
                        {
                            finish: 30,
                            timeout: 200,
                        },
                        {
                            finish: 70,
                            timeout: 100,
                        },
                        {
                            finish: 100,
                            timeout: 66,
                        },
                    ],
                },
            ],
            loadingArraysThird: TWEAKS_LOADERS.analyze_loaders,
            loadingTextArray: [
                {
                    startPercent: 0,
                    finishPercent: 50,
                    text: 'Connecting to database',
                },
                {
                    startPercent: 51,
                    finishPercent: 99,
                    text: 'Preliminary processing',
                },
            ],
        },
    },

    {
        type: 'email',
        step: 19,
        nextStep: 20,
        analyticsKey: 'email',
        withoutButton: true,
        title: 'Where should we send your personalized hotspots & fishing map',
        desc: 'Enter your email to get started',
        buttonText: 'See my map',
        defaultFreshFish: 'northern_pike',
        defaultSaltFish: 'northern_pike',
    },

    {
        type: 'landing',
        step: 20,
    },
];

const stepsCaSalt = [
    {
        type: 'first_screen',
        mainQuestion: 1,
        progress: 20,
        step: -1,
        withoutButton: true,
        noSet: true,
        screens: {
            1: {
                img: firstScreen,
                title: 'Find the best fishing spots near you',
                text: 'Answer a quick quiz to receive your personal hot spots map',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                prevStep: 0,
                analyticsKey: 'type_of_water',
                answers: [
                    {
                        type: 'button',
                        text: 'Fresh',
                        img: fresh,
                        value: 'fresh',
                    },
                    {
                        type: 'button',
                        text: 'Salt',
                        img: salt,
                        value: 'salt',
                    },
                ],
            },
            2: {
                img: firstScreen,
                imgDesc: '*828 top spots in US',
                title: 'Discover new fishing spots nearby tailored to your fishing preferences',
                nextStep: 1,
                prevStep: 0,
                loadingArrays: [
                    {
                        title: 'Loading the test',
                        color: '#0A364A',
                        colorLoader: '#5682F2',
                        steps: [
                            {
                                finish: 30,
                                timeout: 133,
                            },
                            {
                                finish: 70,
                                timeout: 70,
                            },
                            {
                                finish: 100,
                                timeout: 33,
                            },
                        ],
                    },
                ],
            },
            [FIRST_SCREEN_VARIANT_WATER_V1]: {
                img: firstScreen,
                title: 'Your Fishing Map is Almost Ready!',
                text: 'Just answer 3 quick questions to customize it.',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                mainQuestion: 1,
                prevStep: 0,
                progress: 20,
                analyticsKey: 'type_of_water',
                answerType: 'single',
                answers: [
                    {
                        type: 'with_img',
                        text: 'Fresh',
                        img: freshFS,
                        value: 'fresh',
                    },
                    {
                        type: 'with_img',
                        text: 'Salt',
                        img: saltFS,
                        value: 'salt',
                    },
                ],
            },
            [FIRST_SCREEN_VARIANT_WATER_V2]: {
                title: ' Find the best fishing spots near you',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                mainQuestion: 1,
                prevStep: 0,
                progress: 20,
                analyticsKey: 'type_of_water',
                answerType: 'single',
                answers: [
                    {
                        text: 'Fresh',
                        type: 'button',
                        value: 'fresh',
                    },
                    {
                        text: 'Salt',
                        type: 'button',
                        value: 'salt',
                    },
                ],
            },
        },
    },
    {
        type: 'question',
        step: 0,
        analyticsKey: 'type_of_water',
        nextStep: 1,
        mainQuestion: 1,
        progress: 0,
        title: 'Discover Your Perfect Fishing Map',
        text: 'Answer a quick customized quiz to receive your hotspots map and 7-day fishing forecast.',
        desc: 'Select the type of water you usually fish in:',
        answerType: 'single',
        answers: [
            {
                type: 'with_img',
                text: 'Fresh us',
                img: fresh,
                value: 'fresh',
            },
            {
                type: 'with_img',
                text: 'Salt',
                img: salt,
                value: 'salt',
            },
            {
                type: 'radio',
                text: 'Both',
                value: 'both',
                textStyles: '!text-center',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 1,
        nextStep: 2,
        prevStep: 0,
        prevStepAlt: -1,
        progress: 20,
        analyticsKey: 'where_are_you_fishing',
        title: 'Where are you enjoying fishing most of all?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Boat or Kayak',
                img: boat,
                value: 'boat_or_kayak',
            },
            {
                type: 'with_img',
                text: 'Shore or Bank',
                img: off,
                value: 'shore_or_bank',
            },
            {
                type: 'with_img',
                text: 'Pier or dock',
                img: pier,
                value: 'pier_or_dock',
            },
            {
                type: 'with_img',
                text: 'In water',
                img: wading,
                value: 'in_water',
            },
            {
                type: 'with_img',
                text: 'Ice',
                img: caIce,
                value: 'ice',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 2,
        nextStep: 3,
        prevStep: 1,
        progress: 30,
        analyticsKey: 'what_waterbodies',
        title: 'What waterbodies you usually fish at?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Lakes',
                img: lake,
                value: 'lakes',
            },
            {
                type: 'with_img',
                text: 'Bays',
                img: bays,
                value: 'bays',
            },
            {
                type: 'with_img',
                text: 'Ocean',
                img: ocean,
                value: 'ocean',
            },
            {
                type: 'with_img',
                text: 'Sea',
                img: caSee,
                value: 'sea',
            },
            {
                type: 'with_img',
                text: 'Estuaries',
                img: caEstuaries,
                value: 'estuaries',
            },
            {
                type: 'with_img',
                text: 'Gulfs',
                img: caGulfs,
                value: 'gulfs',
            },
            {
                type: 'with_img',
                text: 'Inlets',
                img: caInlets,
                value: 'inlets',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 3,
        nextStep: 4,
        prevStep: 2,
        progress: 45,
        analyticsKey: 'what_fish',
        title: 'What fish species do you usually target?',
        text: 'This information will help us understand your preferences.',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Northern pike',
                img: north,
                value: 'northern_pike',
            },
            {
                type: 'with_img',
                text: 'Chinook Salmon ',
                img: chinookSalmon,
                value: 'chinook_salmon',
            },
            {
                type: 'with_img',
                text: 'Rainbow Trout',
                img: rainbowTrout,
                value: 'rainbow_trout',
            },
            {
                type: 'with_img',
                text: 'Catfish',
                img: catfishCa,
                value: 'catfish',
            },
            {
                type: 'with_img',
                text: 'Atlantic Salmon',
                img: atlSalmon,
                value: 'atlantic_salmon',
            },
            {
                type: 'with_img',
                text: 'Mackerel',
                img: mackerel,
                value: 'mackerel',
            },
            {
                type: 'with_img',
                text: 'Cod',
                img: cod,
                value: 'cod',
            },

            {
                type: 'with_img',
                text: 'Striped bass',
                img: striped,
                value: 'striped_bass',
            },
            {
                type: 'with_img',
                text: 'Pacific Halibut',
                img: pacificHalibut,
                value: 'pacific_halibut',
            },
            {
                type: 'with_img',
                text: 'Bluefin Tuna',
                img: bluefinTuna,
                value: 'bluefin_tuna',
            },
            {
                type: 'with_img',
                text: 'Haddock',
                img: haddock,
                value: 'haddock',
            },
            {
                type: 'with_img',
                text: 'Lingcod',
                img: lingcod,
                value: 'lingcod',
            },
            {
                type: 'with_img',
                text: 'Rockfish',
                img: rockfish,
                value: 'rockfish',
            },
            {
                type: 'with_img',
                text: 'Flounder',
                img: flounder,
                value: 'flounder',
            },
            {
                type: 'with_img',
                text: 'Other saltwater fish',
                img: otherSalt,
                imgStyles: 'w-[93px] h-[88px] !min-h-[auto] !min-w-[auto]',
                value: 'other_saltwater_fish',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 4,
        nextStep: 5,
        prevStep: 3,
        progress: 60,
        analyticsKey: 'what_time',
        title: 'What time intervals you usually choose to plan your fishing trip?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'Early morning (from 5 a.m. till 9 a.m.)',
                value: 'early_morning',
            },
            {
                type: 'with_tick',
                text: 'Late morning (from 9 a.m. till 12 p.m.)',
                value: 'late_morning',
            },
            {
                type: 'with_tick',
                text: 'Afternoon (from 12 p.m. till 6 p.m.)',
                value: 'afternoon',
            },
            {
                type: 'with_tick',
                text: 'Late evening (from 6 p.m. till 10 p.m.)',
                value: 'late_evening',
            },
            {
                type: 'with_tick',
                text: 'Night time (from 10 p.m. till 5 a.m.)',
                value: 'night_time',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 5,
        nextStep: 6,
        prevStep: 4,
        progress: 75,
        analyticsKey: 'what_angling_method',
        title: 'What angling method do you usually use?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'Bottom fishing',
                value: 'bottom_fishing',
            },
            {
                type: 'with_tick',
                text: 'Fly-fishing',
                value: 'fly_fishing',
            },
            {
                type: 'with_tick',
                text: 'Spin fishing',
                value: 'spin_fishing',
            },
            {
                type: 'with_tick',
                text: 'Jigging',
                value: 'jigging',
            },
            {
                type: 'with_tick',
                text: 'Trolling',
                value: 'trolling',
            },
            {
                type: 'with_tick',
                text: 'Casting',
                value: 'casting',
            },
            {
                type: 'with_tick',
                text: 'Other method',
                value: 'other_method',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 1,
        step: 6,
        nextStep: 7,
        prevStep: 5,
        progress: 90,
        analyticsKey: 'what_kind_of_lures',
        title: 'What kind of fishing baits/lures do you use most often?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Flies',
                img: flies,
                value: 'flies',
            },
            {
                type: 'with_img',
                text: 'Jigs',
                img: jigs,
                value: 'jigs',
            },
            {
                type: 'with_img',
                text: 'Spinnerbaits & Buzzbaits',
                img: spinner,
                value: 'spinnerbaits_buzzbaits',
            },
            {
                type: 'with_img',
                text: 'Plugs',
                img: plugs,
                value: 'plugs',
            },
            {
                type: 'with_img',
                text: 'Spoons',
                img: spoons,
                value: 'spoons',
            },
            {
                type: 'with_img',
                text: 'Soft bait lures',
                img: soft,
                value: 'soft_bait_lures',
            },
            {
                type: 'with_img',
                text: 'Other lures',
                img: other,
                imgStyles: 'w-[93px] h-[88px] !min-h-[auto] !min-w-[auto]',
                value: 'other_lures',
            },
            {
                type: 'with_img',
                text: 'Fish bait',
                img: fishBait,
                imgStyles: '!object-contain',
                value: 'fish_bait',
            },
            {
                type: 'with_img',
                text: 'Shellfish',
                img: shellFish,
                imgStyles: '!object-contain',
                value: 'shellfish',
            },
            {
                type: 'with_img',
                text: 'Other baits',
                img: othSalt,
                value: 'other_baits',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 1,
        step: 7,
        nextStep: 8,
        prevStep: 6,
        progress: 95,
        analyticsKey: 'in_which_layer_of_water',
        title: 'In which layer of water do you usually catch fish?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'I like to fish in shallow water, around 1-2 meters deep',
                value: '1_2_meters_deep',
            },
            {
                type: 'with_tick',
                text: 'It depends on my current target species, but I usually fish in a 3-5 meters deep water',
                value: '3_5_meters_deep',
            },
            {
                type: 'with_tick',
                text: 'I prefer to fish in deeper water, around 6-9 meters deep',
                value: '6_9_meters_deep',
            },
            {
                type: 'with_tick',
                text: 'I fish in water that is 10+ meters deep',
                value: '10+_meters_deep',
            },
        ],
    },

    {
        type: 'screen_1',
        mainQuestion: 2,
        step: 8,
        nextStep: 9,
        progress: 0,
        analyticsKey: 'our_algorithms',
        withOutAnalyticsValue: true,
        title: 'Our algorithms are based on 21 parameters',
        text: "We'll literally show you the best spots to fish in your area based on real-time trends, which means you'll always fish in areas filled with fish, and be spared from long preparations for each trip.",
        help: 'Fishbox™ Team',
        img: screen1,
        buttonText: 'Sounds good',
        isButtonEnabled: true,
    },

    {
        type: 'question',
        step: 9,
        nextStep: 10,
        prevStep: 8,
        mainQuestion: 2,
        progress: 20,
        analyticsKey: 'what_describes_you_better',
        title: 'What describes you better?',
        text: 'This information will help us understand your experience.',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'I’m a beginner',
                value: 'beginner',
            },
            {
                type: 'radio',
                text: 'I’m an amateur',
                value: 'amateur',
            },
            {
                type: 'radio',
                text: 'I’m an experienced angler',
                value: 'experienced',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 2,
        step: 10,
        nextStep: 11,
        prevStep: 9,
        progress: 40,
        analyticsKey: 'who_do_you_go_fishing_with',
        title: 'Who do you usually go fishing with?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'I fish alone',
                value: 'alone',
            },
            {
                type: 'with_tick',
                text: 'With family members',
                value: 'family',
            },
            {
                type: 'with_tick',
                text: 'With friends',
                value: 'friends',
            },
            {
                type: 'with_tick',
                text: 'With a professional guide',
                value: 'professional_guide',
            },
            {
                type: 'with_tick',
                text: 'With coworkers or business associates',
                value: 'business_associates',
            },
        ],
    },

    {
        type: 'question',
        step: 11,
        nextStep: 12,
        prevStep: 10,
        mainQuestion: 2,
        progress: 60,
        analyticsKey: 'do_you_agree',
        title: 'Do you agree with the following:',
        desc: "“I don't like crowded fishing spots”",
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'Completely agree',
                value: 'completely_agree',
            },
            {
                type: 'radio',
                text: 'Agree',
                value: 'agree',
            },
            {
                type: 'radio',
                text: 'Neutral',
                value: 'neutral',
            },
            {
                type: 'radio',
                text: 'Disagree',
                value: 'disagree',
            },
            {
                type: 'radio',
                text: 'Totally disagree',
                value: 'totally_disagree',
            },
        ],
    },

    {
        type: 'question',
        step: 12,
        nextStep: 13,
        prevStep: 11,
        mainQuestion: 2,
        progress: 80,
        analyticsKey: 'how_often_disappointed',
        title: 'How often are you disappointed with your fishing trip?',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'Often',
                value: 'often',
            },
            {
                type: 'radio',
                text: 'Sometimes',
                value: 'sometimes',
            },
            {
                type: 'radio',
                text: 'Rarely',
                value: 'rarely',
            },
            {
                type: 'radio',
                text: 'Never',
                value: 'never',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 2,
        step: 13,
        nextStep: 14,
        prevStep: 12,
        progress: 90,
        analyticsKey: 'what_difficulties',
        title: 'What difficulties are you experiencing most often?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: "I don't know when fish is biting",
                value: 'fish_is_biting',
            },
            {
                type: 'with_tick',
                text: " I don't know spots where fish are active",
                value: 'spots',
            },
            {
                type: 'with_tick',
                text: "I don't know what fish are habitat in a particular spot",
                value: 'fish_are_habitat',
            },
            {
                type: 'with_tick',
                text: " I don't know what gear I need",
                value: 'what_gear',
            },
            {
                type: 'with_tick',
                text: "I don't know spot's rules and regulations",
                value: 'regulations',
            },
            {
                type: 'with_tick',
                text: 'Bad weather',
                value: 'bad_weather',
            },
            {
                type: 'with_tick',
                text: 'Fishing gear is a way too expensive',
                value: 'too_expensive',
            },
            {
                type: 'with_tick',
                text: "I don't have enough time 😭",
                value: 'enough_time',
            },
        ],
    },

    {
        type: 'screen_2',
        mainQuestion: 3,
        step: 14,
        nextStep: 15,
        progress: 0,
        img: screen2CaSalt,
        analyticsKey: 'you_are_not_alone',
        withOutAnalyticsValue: true,
        buttonText: 'Continue',
        isButtonEnabled: true,
    },

    {
        type: 'state',
        mainQuestion: 3,
        step: 15,
        nextStep: 16,
        prevStep: 14,
        progress: 30,
        analyticsKey: 'your_state',
        buttonText: 'Continue',
        titleText: 'Choose your province or territory:',
    },

    {
        type: 'city',
        mainQuestion: 3,
        step: 16,
        nextStep: 17,
        prevStep: 15,
        progress: 60,
        analyticsKey: 'your_city',
        img: city,
        buttonText: 'Continue',
    },

    {
        type: 'question',
        step: 17,
        nextStep: 18,
        prevStep: 16,
        mainQuestion: 3,
        progress: 90,
        analyticsKey: 'how_far',
        title: 'How far from the city are you ready to go on your fishing trip?',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'Up to 40 kilometers',
                value: '40_kilometers',
            },
            {
                type: 'radio',
                text: 'Up to 80 kilometers',
                value: '80_kilometers',
            },
            {
                type: 'radio',
                text: 'Up to 160 kilometers',
                value: '160_kilometers',
            },
            {
                type: 'radio',
                text: 'Up to 320 kilometers',
                value: '320_kilometers',
            },
            {
                type: 'radio',
                text: 'Up to 320+ kilometers',
                value: 'more_320_kilometers',
            },
        ],
    },

    {
        type: 'loading',
        step: 18,
        nextStep: 19,
        withoutButton: true,
        data: {
            eventFillName: 'analyzing answers',
            nextRoute: '/onboarding/PersonalizationD2',
            title: 'Analyzing your answers...',
            subtitle: 'Trusted by over 149,569 people',
            loadingArrays: [
                {
                    text: 'Analysis of water bodies nearby',
                    title: 'Analyzing your answers...',
                    color: '#5682F2',
                    steps: [
                        {
                            finish: 30,
                            timeout: 133,
                        },
                        {
                            finish: 70,
                            timeout: 70,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
                {
                    text: 'Calculation of biting activity',
                    color: '#4ABCE1',
                    title: 'Analyzing weather parameters...',
                    steps: [
                        {
                            finish: 30,
                            timeout: 133,
                        },
                        {
                            finish: 70,
                            timeout: 50,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
                {
                    text: 'Generating Your Personal Map',
                    color: '#39CAB9',
                    title: 'Looking for hotspots near you...',
                    steps: [
                        {
                            finish: 30,
                            timeout: 100,
                        },
                        {
                            finish: 70,
                            timeout: 50,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
            ],
            loadingArraysSecond: [
                {
                    text: 'Generating Your Personal Map',
                    color: '#39CAB9',
                    title: 'Looking for hotspots near you...',
                    titles: [
                        {
                            text: 'Analyzing weather parameters...',
                            percent: 0,
                        },
                        {
                            text: 'Looking for hotspots near you...',
                            percent: 64,
                        },
                        {
                            text: 'Your personal map is complete',
                            percent: 98,
                        },
                    ],

                    steps: [
                        {
                            finish: 30,
                            timeout: 200,
                        },
                        {
                            finish: 70,
                            timeout: 100,
                        },
                        {
                            finish: 100,
                            timeout: 66,
                        },
                    ],
                },
            ],
            loadingArraysThird: TWEAKS_LOADERS.analyze_loaders,
            loadingTextArray: [
                {
                    startPercent: 0,
                    finishPercent: 50,
                    text: 'Connecting to database',
                },
                {
                    startPercent: 51,
                    finishPercent: 99,
                    text: 'Preliminary processing',
                },
            ],
        },
    },

    {
        type: 'email',
        step: 19,
        nextStep: 20,
        analyticsKey: 'email',
        withoutButton: true,
        title: 'Where should we send your personalized hotspots & fishing map',
        desc: 'Enter your email to get started',
        buttonText: 'See my map',
        defaultFreshFish: 'northern_pike',
        defaultSaltFish: 'northern_pike',
    },

    {
        type: 'landing',
        step: 20,
    },
];

const stepsUk = [
    {
        type: 'first_screen',
        mainQuestion: 1,
        progress: 20,
        step: -1,
        withoutButton: true,
        noSet: true,
        screens: {
            1: {
                img: firstScreen,
                title: 'Find the best fishing spots near you',
                text: 'Answer a quick quiz to receive your personal hot spots map',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                prevStep: 0,
                analyticsKey: 'type_of_water',
                answers: [
                    {
                        type: 'button',
                        text: 'Fresh',
                        img: fresh,
                        value: 'fresh',
                    },
                    {
                        type: 'button',
                        text: 'Salt',
                        img: salt,
                        value: 'salt',
                    },
                ],
            },
            2: {
                img: firstScreen,
                imgDesc: '*828 top spots in US',
                title: 'Discover new fishing spots nearby tailored to your fishing preferences',
                nextStep: 1,
                prevStep: 0,
                loadingArrays: [
                    {
                        title: 'Loading the test',
                        color: '#0A364A',
                        colorLoader: '#5682F2',
                        steps: [
                            {
                                finish: 30,
                                timeout: 133,
                            },
                            {
                                finish: 70,
                                timeout: 70,
                            },
                            {
                                finish: 100,
                                timeout: 33,
                            },
                        ],
                    },
                ],
            },
            [FIRST_SCREEN_VARIANT_WATER_V1]: {
                img: firstScreen,
                title: 'Your Fishing Map is Almost Ready!',
                text: 'Just answer 3 quick questions to customize it.',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                mainQuestion: 1,
                prevStep: 0,
                progress: 20,
                analyticsKey: 'type_of_water',
                answerType: 'single',
                answers: [
                    {
                        type: 'with_img',
                        text: 'Fresh',
                        img: freshFS,
                        value: 'fresh',
                    },
                    {
                        type: 'with_img',
                        text: 'Salt',
                        img: saltFS,
                        value: 'salt',
                    },
                ],
            },
            [FIRST_SCREEN_VARIANT_WATER_V2]: {
                title: ' Find the best fishing spots near you',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                mainQuestion: 1,
                prevStep: 0,
                progress: 20,
                analyticsKey: 'type_of_water',
                answerType: 'single',
                answers: [
                    {
                        text: 'Fresh',
                        type: 'button',
                        value: 'fresh',
                    },
                    {
                        text: 'Salt',
                        type: 'button',
                        value: 'salt',
                    },
                ],
            },
        },
    },
    {
        type: 'question',
        step: 0,
        analyticsKey: 'type_of_water',
        nextStep: 1,
        mainQuestion: 1,
        progress: 0,
        title: 'Discover Your Perfect Fishing Map',
        text: 'Answer a quick customized quiz to receive your hotspots map and 7-day fishing forecast.',
        desc: 'Select the type of water you usually fish in:',
        answerType: 'single',
        answers: [
            {
                type: 'with_img',
                text: 'Fresh us',
                img: fresh,
                value: 'fresh',
            },
            {
                type: 'with_img',
                text: 'Salt',
                img: salt,
                value: 'salt',
            },
            {
                type: 'radio',
                text: 'Both',
                value: 'both',
                textStyles: '!text-center',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 1,
        nextStep: 2,
        prevStep: 0,
        prevStepAlt: -1,
        progress: 20,
        analyticsKey: 'where_are_you_fishing',
        title: 'Where are you enjoying fishing most of all?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Boat or Kayak',
                img: boat,
                value: 'boat_or_kayak',
            },
            {
                type: 'with_img',
                text: 'Shore or Bank',
                img: off,
                value: 'shore_or_bank',
            },
            {
                type: 'with_img',
                text: 'Pier or dock',
                img: pier,
                value: 'pier_or_dock',
            },
            {
                type: 'with_img',
                text: 'In water',
                img: wading,
                value: 'in_water',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 2,
        nextStep: 3,
        prevStep: 1,
        progress: 30,
        analyticsKey: 'what_waterbodies',
        title: 'What waterbodies you usually fish at?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Lakes and Lochs',
                img: lake,
                value: 'lakes',
            },
            {
                type: 'with_img',
                text: 'Rivers',
                img: caRivers,
                value: 'rivers',
            },
            {
                type: 'with_img',
                text: 'Ponds',
                img: ponds,
                value: 'ponds',
            },
            {
                type: 'with_img',
                text: 'Canals',
                img: caCanals,
                value: 'canals',
            },
            {
                type: 'with_img',
                text: 'Reservoirs',
                img: reservioirs,
                value: 'reservoirs',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 3,
        nextStep: 4,
        prevStep: 2,
        progress: 45,
        analyticsKey: 'what_fish',
        title: 'What fish species do you usually target?',
        text: 'This information will help us understand your preferences.',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Common Carp',
                img: commonCarp,
                value: 'common_carp',
            },
            {
                type: 'with_img',
                text: 'Northern pike',
                img: north,
                value: 'northern_pike',
            },
            {
                type: 'with_img',
                text: 'Roach',
                img: roach,
                value: 'roach',
            },
            {
                type: 'with_img',
                text: 'European Perch',
                img: europeanPerch,
                value: 'european_perch',
            },
            {
                type: 'with_img',
                text: 'Brown Trout',
                img: brown,
                value: 'brown_trout',
            },
            {
                type: 'with_img',
                text: 'Bream',
                img: breamUk,
                value: 'bream',
            },
            {
                type: 'with_img',
                text: 'Chub',
                img: chub,
                value: 'chub',
            },
            {
                type: 'with_img',
                text: 'Tench',
                img: tench,
                value: 'tench',
            },
            {
                type: 'with_img',
                text: 'Barbel',
                img: barbel,
                value: 'barbel',
            },
            {
                type: 'with_img',
                text: 'Atlantic Salmon',
                img: atlSalmon,
                value: 'atlantic_salmon',
            },
            {
                type: 'with_img',
                text: 'Other freshwater fish',
                img: otherFresh,
                imgStyles: 'w-[93px] h-[88px] !min-h-[auto] !min-w-[auto]',
                value: 'other_freshwater_fish',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 4,
        nextStep: 5,
        prevStep: 3,
        progress: 60,
        analyticsKey: 'what_time',
        title: 'What time intervals you usually choose to plan your fishing trip?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'Early morning (from 5:00 till 9:00)',
                value: 'early_morning',
            },
            {
                type: 'with_tick',
                text: 'Late morning (from 9:00 till 12:00)',
                value: 'late_morning',
            },
            {
                type: 'with_tick',
                text: 'Afternoon (from 12:00 till 18:00)',
                value: 'afternoon',
            },
            {
                type: 'with_tick',
                text: 'Late evening (from 18:00 till 22:00)',
                value: 'late_evening',
            },
            {
                type: 'with_tick',
                text: 'Night time (from 22:00 till 5:00)',
                value: 'night_time',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 5,
        nextStep: 6,
        prevStep: 4,
        progress: 75,
        analyticsKey: 'what_angling_method',
        title: 'What angling method do you usually use?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'Fly-fishing',
                value: 'fly_fishing',
            },
            {
                type: 'with_tick',
                text: 'Pole fisihing',
                value: 'pole_fishing',
            },
            {
                type: 'with_tick',
                text: 'Feeder fishing',
                value: 'feeder_fishing',
            },
            {
                type: 'with_tick',
                text: 'Float Fishing',
                value: 'float_fishing',
            },
            {
                type: 'with_tick',
                text: 'Ledgering',
                value: 'ledgering',
            },
            {
                type: 'with_tick',
                text: 'Other method',
                value: 'other_method',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 1,
        step: 6,
        nextStep: 7,
        prevStep: 5,
        progress: 90,
        analyticsKey: 'what_kind_of_lures',
        title: 'What kind of fishing baits/lures do you use most often?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Flies',
                img: flies,
                value: 'flies',
            },
            {
                type: 'with_img',
                text: 'Jigs',
                img: jigs,
                value: 'jigs',
            },
            {
                type: 'with_img',
                text: 'Spinnerbaits & Buzzbaits',
                img: spinner,
                value: 'spinnerbaits_buzzbaits',
            },
            {
                type: 'with_img',
                text: 'Plugs',
                img: plugs,
                value: 'plugs',
            },
            {
                type: 'with_img',
                text: 'Spoons',
                img: spoons,
                value: 'spoons',
            },
            {
                type: 'with_img',
                text: 'Soft bait lures',
                img: soft,
                value: 'soft_bait_lures',
            },
            {
                type: 'with_img',
                text: 'Other lures',
                img: other,
                imgStyles: 'w-[93px] h-[88px] !min-h-[auto] !min-w-[auto]',
                value: 'other_lures',
            },
            {
                type: 'with_img',
                text: 'Boilies and Sweetcorn',
                img: boilies,
                value: 'boiles_and_sweetcorn',
            },
            {
                type: 'with_img',
                text: 'Worms',
                img: worms,
                value: 'worms',
            },
            {
                type: 'with_img',
                text: 'Maggots',
                img: maggots,
                value: 'maggots',
            },

            {
                type: 'with_img',
                text: 'Other baits',
                img: baits,
                value: 'other_baits',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 1,
        step: 7,
        nextStep: 8,
        prevStep: 6,
        progress: 95,
        analyticsKey: 'in_which_layer_of_water',
        title: 'In which layer of water do you usually catch fish?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'I like to fish in shallow water, around 3-5 feet deep',
                value: '3_5_feet_deep',
            },
            {
                type: 'with_tick',
                text: 'It depends on my current target species, but I usually fish in a 10-15 feet deep water',
                value: '10_15_feet_deep',
            },
            {
                type: 'with_tick',
                text: 'I prefer to fish in deeper water, around 20-30 feet deep',
                value: '20_30_feet_deep',
            },
            {
                type: 'with_tick',
                text: 'I fish in water that is 25+ feet deep',
                value: '25_feet_deep',
            },
        ],
    },

    {
        type: 'screen_1',
        mainQuestion: 2,
        step: 8,
        nextStep: 9,
        progress: 0,
        analyticsKey: 'our_algorithms',
        withOutAnalyticsValue: true,
        title: 'Our algorithms are based on 21 parameters',
        text: "We'll literally show you the best spots to fish in your area based on real-time trends, which means you'll always fish in areas filled with fish, and be spared from long preparations for each trip.",
        help: 'Fishbox™ Team',
        img: screen1,
        buttonText: 'Sounds good',
        isButtonEnabled: true,
    },

    {
        type: 'question',
        step: 9,
        nextStep: 10,
        prevStep: 8,
        mainQuestion: 2,
        progress: 20,
        analyticsKey: 'what_describes_you_better',
        title: 'What describes you better?',
        text: 'This information will help us understand your experience.',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'I’m a beginner',
                value: 'beginner',
            },
            {
                type: 'radio',
                text: 'I’m an amateur',
                value: 'amateur',
            },
            {
                type: 'radio',
                text: 'I’m an experienced angler',
                value: 'experienced',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 2,
        step: 10,
        nextStep: 11,
        prevStep: 9,
        progress: 40,
        analyticsKey: 'who_do_you_go_fishing_with',
        title: 'Who do you usually go fishing with?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'I fish alone',
                value: 'alone',
            },
            {
                type: 'with_tick',
                text: 'With family members',
                value: 'family',
            },
            {
                type: 'with_tick',
                text: 'With friends',
                value: 'friends',
            },
            {
                type: 'with_tick',
                text: 'With a professional guide',
                value: 'professional_guide',
            },
            {
                type: 'with_tick',
                text: 'With coworkers or business associates',
                value: 'business_associates',
            },
        ],
    },

    {
        type: 'question',
        step: 11,
        nextStep: 12,
        prevStep: 10,
        mainQuestion: 2,
        progress: 60,
        analyticsKey: 'do_you_agree',
        title: 'Do you agree with the following:',
        desc: "“I don't like crowded fishing spots”",
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'Completely agree',
                value: 'completely_agree',
            },
            {
                type: 'radio',
                text: 'Agree',
                value: 'agree',
            },
            {
                type: 'radio',
                text: 'Neutral',
                value: 'neutral',
            },
            {
                type: 'radio',
                text: 'Disagree',
                value: 'disagree',
            },
            {
                type: 'radio',
                text: 'Totally disagree',
                value: 'totally_disagree',
            },
        ],
    },

    {
        type: 'question',
        step: 12,
        nextStep: 13,
        prevStep: 11,
        mainQuestion: 2,
        progress: 80,
        analyticsKey: 'how_often_disappointed',
        title: 'How often are you disappointed with your fishing trip?',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'Often',
                value: 'often',
            },
            {
                type: 'radio',
                text: 'Sometimes',
                value: 'sometimes',
            },
            {
                type: 'radio',
                text: 'Rarely',
                value: 'rarely',
            },
            {
                type: 'radio',
                text: 'Never',
                value: 'never',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 2,
        step: 13,
        nextStep: 14,
        prevStep: 12,
        progress: 90,
        analyticsKey: 'what_difficulties',
        title: 'What difficulties are you experiencing most often?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: "I don't know when fish is biting",
                value: 'fish_is_biting',
            },
            {
                type: 'with_tick',
                text: " I don't know spots where fish are active",
                value: 'spots',
            },
            {
                type: 'with_tick',
                text: "I don't know what fish are habitat in a particular spot",
                value: 'fish_are_habitat',
            },
            {
                type: 'with_tick',
                text: " I don't know what gear I need",
                value: 'what_gear',
            },
            {
                type: 'with_tick',
                text: "I don't know spot's rules and regulations",
                value: 'regulations',
            },
            {
                type: 'with_tick',
                text: 'Bad weather',
                value: 'bad_weather',
            },
            {
                type: 'with_tick',
                text: 'Fishing gear is a way too expensive',
                value: 'too_expensive',
            },
            {
                type: 'with_tick',
                text: "I don't have enough time 😭",
                value: 'enough_time',
            },
        ],
    },

    {
        type: 'screen_2',
        mainQuestion: 3,
        step: 14,
        nextStep: 15,
        progress: 0,
        img: screen2,
        analyticsKey: 'you_are_not_alone',
        withOutAnalyticsValue: true,
        buttonText: 'Continue',
        isButtonEnabled: true,
    },

    {
        type: 'state',
        mainQuestion: 3,
        step: 15,
        nextStep: 16,
        prevStep: 14,
        progress: 30,
        analyticsKey: 'your_state',
        buttonText: 'Continue',
        titleText: 'Choose your part of the UK:',
    },

    {
        type: 'city',
        mainQuestion: 3,
        step: 16,
        nextStep: 17,
        prevStep: 15,
        progress: 60,
        analyticsKey: 'your_city',
        img: city,
        buttonText: 'Continue',
    },

    {
        type: 'question',
        step: 17,
        nextStep: 18,
        prevStep: 16,
        mainQuestion: 3,
        progress: 90,
        analyticsKey: 'how_far',
        title: 'How far from the city are you ready to go on your fishing trip?',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'Up to 25 miles',
                value: '25_miles',
            },
            {
                type: 'radio',
                text: 'Up to 50 miles',
                value: '50_miles',
            },
            {
                type: 'radio',
                text: 'Up to 100 miles',
                value: '100_miles',
            },
            {
                type: 'radio',
                text: 'Up to 200 miles',
                value: '200_miles',
            },
            {
                type: 'radio',
                text: 'Up to 200+ miles',
                value: 'more_200_miles',
            },
        ],
    },

    {
        type: 'loading',
        step: 18,
        nextStep: 19,
        withoutButton: true,
        data: {
            eventFillName: 'analyzing answers',
            nextRoute: '/onboarding/PersonalizationD2',
            title: 'Analyzing your answers...',
            subtitle: 'Trusted by over 149,569 people',
            loadingArrays: [
                {
                    text: 'Analysis of water bodies nearby',
                    title: 'Analyzing your answers...',
                    color: '#5682F2',
                    steps: [
                        {
                            finish: 30,
                            timeout: 133,
                        },
                        {
                            finish: 70,
                            timeout: 70,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
                {
                    text: 'Calculation of biting activity',
                    color: '#4ABCE1',
                    title: 'Analyzing weather parameters...',
                    steps: [
                        {
                            finish: 30,
                            timeout: 133,
                        },
                        {
                            finish: 70,
                            timeout: 50,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
                {
                    text: 'Generating Your Personal Map',
                    color: '#39CAB9',
                    title: 'Looking for hotspots near you...',
                    steps: [
                        {
                            finish: 30,
                            timeout: 100,
                        },
                        {
                            finish: 70,
                            timeout: 50,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
            ],
            loadingArraysSecond: [
                {
                    text: 'Generating Your Personal Map',
                    color: '#39CAB9',
                    title: 'Looking for hotspots near you...',
                    titles: [
                        {
                            text: 'Analyzing weather parameters...',
                            percent: 0,
                        },
                        {
                            text: 'Looking for hotspots near you...',
                            percent: 64,
                        },
                        {
                            text: 'Your personal map is complete',
                            percent: 98,
                        },
                    ],

                    steps: [
                        {
                            finish: 30,
                            timeout: 200,
                        },
                        {
                            finish: 70,
                            timeout: 100,
                        },
                        {
                            finish: 100,
                            timeout: 66,
                        },
                    ],
                },
            ],
            loadingArraysThird: TWEAKS_LOADERS.analyze_loaders,
            loadingTextArray: [
                {
                    startPercent: 0,
                    finishPercent: 50,
                    text: 'Connecting to database',
                },
                {
                    startPercent: 51,
                    finishPercent: 99,
                    text: 'Preliminary processing',
                },
            ],
        },
    },

    {
        type: 'email',
        step: 19,
        nextStep: 20,
        analyticsKey: 'email',
        withoutButton: true,
        title: 'Where should we send your personalized hotspots & fishing map',
        desc: 'Enter your email to get started',
        buttonText: 'See my map',
        defaultFreshFish: 'common_carp',
        defaultSaltFish: 'cod',
    },

    {
        type: 'landing',
        step: 20,
    },
];

const stepsUkSalt = [
    {
        type: 'first_screen',
        mainQuestion: 1,
        progress: 20,
        step: -1,
        withoutButton: true,
        noSet: true,
        screens: {
            1: {
                img: firstScreen,
                title: 'Find the best fishing spots near you',
                text: 'Answer a quick quiz to receive your personal hot spots map',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                prevStep: 0,
                analyticsKey: 'type_of_water',
                answers: [
                    {
                        type: 'button',
                        text: 'Fresh',
                        img: fresh,
                        value: 'fresh',
                    },
                    {
                        type: 'button',
                        text: 'Salt',
                        img: salt,
                        value: 'salt',
                    },
                ],
            },
            2: {
                img: firstScreen,
                imgDesc: '*828 top spots in US',
                title: 'Discover new fishing spots nearby tailored to your fishing preferences',
                nextStep: 1,
                prevStep: 0,
                loadingArrays: [
                    {
                        title: 'Loading the test',
                        color: '#0A364A',
                        colorLoader: '#5682F2',
                        steps: [
                            {
                                finish: 30,
                                timeout: 133,
                            },
                            {
                                finish: 70,
                                timeout: 70,
                            },
                            {
                                finish: 100,
                                timeout: 33,
                            },
                        ],
                    },
                ],
            },
            [FIRST_SCREEN_VARIANT_WATER_V1]: {
                img: firstScreen,
                title: 'Your Fishing Map is Almost Ready!',
                text: 'Just answer 3 quick questions to customize it.',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                mainQuestion: 1,
                prevStep: 0,
                progress: 20,
                analyticsKey: 'type_of_water',
                answerType: 'single',
                answers: [
                    {
                        type: 'with_img',
                        text: 'Fresh',
                        img: freshFS,
                        value: 'fresh',
                    },
                    {
                        type: 'with_img',
                        text: 'Salt',
                        img: saltFS,
                        value: 'salt',
                    },
                ],
            },
            [FIRST_SCREEN_VARIANT_WATER_V2]: {
                title: ' Find the best fishing spots near you',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                mainQuestion: 1,
                prevStep: 0,
                progress: 20,
                analyticsKey: 'type_of_water',
                answerType: 'single',
                answers: [
                    {
                        text: 'Fresh',
                        type: 'button',
                        value: 'fresh',
                    },
                    {
                        text: 'Salt',
                        type: 'button',
                        value: 'salt',
                    },
                ],
            },
        },
    },
    {
        type: 'question',
        step: 0,
        analyticsKey: 'type_of_water',
        nextStep: 1,
        mainQuestion: 1,
        progress: 0,
        title: 'Discover Your Perfect Fishing Map',
        text: 'Answer a quick customized quiz to receive your hotspots map and 7-day fishing forecast.',
        desc: 'Select the type of water you usually fish in:',
        answerType: 'single',
        answers: [
            {
                type: 'with_img',
                text: 'Fresh us',
                img: fresh,
                value: 'fresh',
            },
            {
                type: 'with_img',
                text: 'Salt',
                img: salt,
                value: 'salt',
            },
            {
                type: 'radio',
                text: 'Both',
                value: 'both',
                textStyles: '!text-center',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 1,
        nextStep: 2,
        prevStep: 0,
        prevStepAlt: -1,
        progress: 20,
        analyticsKey: 'where_are_you_fishing',
        title: 'Where are you enjoying fishing most of all?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Boat or Kayak',
                img: boat,
                value: 'boat_or_kayak',
            },
            {
                type: 'with_img',
                text: 'Shore or Bank',
                img: off,
                value: 'shore_or_bank',
            },
            {
                type: 'with_img',
                text: 'Pier or dock',
                img: pier,
                value: 'pier_or_dock',
            },
            {
                type: 'with_img',
                text: 'In water',
                img: wading,
                value: 'in_water',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 2,
        nextStep: 3,
        prevStep: 1,
        progress: 30,
        analyticsKey: 'what_waterbodies',
        title: 'What waterbodies you usually fish at?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Sea',
                img: caSee,
                value: 'sea',
            },
            {
                type: 'with_img',
                text: 'Estuaries',
                img: ukEsturies,
                value: 'estuaries',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 3,
        nextStep: 4,
        prevStep: 2,
        progress: 45,
        analyticsKey: 'what_fish',
        title: 'What fish species do you usually target?',
        text: 'This information will help us understand your preferences.',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Cod',
                img: cod,
                value: 'cod',
            },
            {
                type: 'with_img',
                text: 'Northern pike',
                img: north,
                value: 'northern_pike',
            },
            {
                type: 'with_img',
                text: 'Roach',
                img: roach,
                value: 'roach',
            },

            {
                type: 'with_img',
                text: 'Brown Trout',
                img: brown,
                value: 'brown_trout',
            },
            {
                type: 'with_img',
                text: 'Tench',
                img: tench,
                value: 'tench',
            },
            {
                type: 'with_img',
                text: 'Atlantic Salmon',
                img: atlSalmon,
                value: 'atlantic_salmon',
            },
            {
                type: 'with_img',
                text: 'Mackerel',
                img: mackerel,
                value: 'mackerel',
            },
            {
                type: 'with_img',
                text: 'Pollock',
                img: pollock,
                value: 'pollock',
            },
            {
                type: 'with_img',
                text: 'European Flounder',
                img: europeanFlounder,
                imgStyles: '!object-contain',
                value: 'european_flounder',
            },
            {
                type: 'with_img',
                text: 'Ray',
                img: ray,
                imgStyles: '!object-contain',
                value: 'ray',
            },
            {
                type: 'with_img',
                text: 'Haddock',
                img: haddock,
                value: 'haddock',
            },
            {
                type: 'with_img',
                text: 'Bass',
                img: bass,
                value: 'bass',
            },
            {
                type: 'with_img',
                text: 'Whiting',
                img: whiting,
                value: 'whiting',
            },
            {
                type: 'with_img',
                text: 'Dogfish',
                img: dogfish,
                value: 'dogfish',
            },
            {
                type: 'with_img',
                text: 'Conger Eel',
                img: congerEel,
                value: 'conger_eel',
            },
            {
                type: 'with_img',
                text: 'Other saltwater fish',
                img: otherSalt,
                imgStyles: 'w-[93px] h-[88px] !min-h-[auto] !min-w-[auto]',
                value: 'other_saltwater_fish',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 4,
        nextStep: 5,
        prevStep: 3,
        progress: 60,
        analyticsKey: 'what_time',
        title: 'What time intervals you usually choose to plan your fishing trip?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'Early morning (from 5:00 till 9:00)',
                value: 'early_morning',
            },
            {
                type: 'with_tick',
                text: 'Late morning (from 9:00 till 12:00)',
                value: 'late_morning',
            },
            {
                type: 'with_tick',
                text: 'Afternoon (from 12:00 till 18:00)',
                value: 'afternoon',
            },
            {
                type: 'with_tick',
                text: 'Late evening (from 18:00 till 22:00)',
                value: 'late_evening',
            },
            {
                type: 'with_tick',
                text: 'Night time (from 22:00 till 5:00)',
                value: 'night_time',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 5,
        nextStep: 6,
        prevStep: 4,
        progress: 75,
        analyticsKey: 'what_angling_method',
        title: 'What angling method do you usually use?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'Bottom fishing',
                value: 'bottom_fishing',
            },
            {
                type: 'with_tick',
                text: 'Jigging',
                value: 'jigging',
            },
            {
                type: 'with_tick',
                text: 'Trolling',
                value: 'trolling',
            },
            {
                type: 'with_tick',
                text: 'Casting',
                value: 'casting',
            },
            {
                type: 'with_tick',
                text: 'Other method',
                value: 'other_method',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 1,
        step: 6,
        nextStep: 7,
        prevStep: 5,
        progress: 90,
        analyticsKey: 'what_kind_of_lures',
        title: 'What kind of fishing baits/lures do you use most often?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Flies',
                img: flies,
                value: 'flies',
            },
            {
                type: 'with_img',
                text: 'Jigs',
                img: jigs,
                value: 'jigs',
            },
            {
                type: 'with_img',
                text: 'Spinnerbaits & Buzzbaits',
                img: spinner,
                value: 'spinnerbaits_buzzbaits',
            },
            {
                type: 'with_img',
                text: 'Plugs',
                img: plugs,
                value: 'plugs',
            },
            {
                type: 'with_img',
                text: 'Spoons',
                img: spoons,
                value: 'spoons',
            },
            {
                type: 'with_img',
                text: 'Soft bait lures',
                img: soft,
                value: 'soft_bait_lures',
            },
            {
                type: 'with_img',
                text: 'Other lures',
                img: other,
                imgStyles: 'w-[93px] h-[88px] !min-h-[auto] !min-w-[auto]',
                value: 'other_lures',
            },
            {
                type: 'with_img',
                text: 'Cut bait',
                img: fishBait,
                imgStyles: '!object-contain',
                value: 'cut_bait',
            },

            {
                type: 'with_img',
                text: 'Other baits',
                imgStyles: '!object-contain',
                img: othSalt,
                value: 'other_baits',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 1,
        step: 7,
        nextStep: 8,
        prevStep: 6,
        progress: 95,
        analyticsKey: 'in_which_layer_of_water',
        title: 'In which layer of water do you usually catch fish?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'I like to fish in shallow water, around 3-5 feet deep',
                value: '3_5_feet_deep',
            },
            {
                type: 'with_tick',
                text: 'It depends on my current target species, but I usually fish in a 10-15 feet deep water',
                value: '10_15_feet_deep',
            },
            {
                type: 'with_tick',
                text: 'I prefer to fish in deeper water, around 20-30 feet deep',
                value: '20_30_feet_deep',
            },
            {
                type: 'with_tick',
                text: 'I fish in water that is 25+ feet deep',
                value: '25_feet_deep',
            },
        ],
    },

    {
        type: 'screen_1',
        mainQuestion: 2,
        step: 8,
        nextStep: 9,
        progress: 0,
        analyticsKey: 'our_algorithms',
        withOutAnalyticsValue: true,
        title: 'Our algorithms are based on 21 parameters',
        text: "We'll literally show you the best spots to fish in your area based on real-time trends, which means you'll always fish in areas filled with fish, and be spared from long preparations for each trip.",
        help: 'Fishbox™ Team',
        img: screen1,
        buttonText: 'Sounds good',
        isButtonEnabled: true,
    },

    {
        type: 'question',
        step: 9,
        nextStep: 10,
        prevStep: 8,
        mainQuestion: 2,
        progress: 20,
        analyticsKey: 'what_describes_you_better',
        title: 'What describes you better?',
        text: 'This information will help us understand your experience.',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'I’m a beginner',
                value: 'beginner',
            },
            {
                type: 'radio',
                text: 'I’m an amateur',
                value: 'amateur',
            },
            {
                type: 'radio',
                text: 'I’m an experienced angler',
                value: 'experienced',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 2,
        step: 10,
        nextStep: 11,
        prevStep: 9,
        progress: 40,
        analyticsKey: 'who_do_you_go_fishing_with',
        title: 'Who do you usually go fishing with?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'I fish alone',
                value: 'alone',
            },
            {
                type: 'with_tick',
                text: 'With family members',
                value: 'family',
            },
            {
                type: 'with_tick',
                text: 'With friends',
                value: 'friends',
            },
            {
                type: 'with_tick',
                text: 'With a professional guide',
                value: 'professional_guide',
            },
            {
                type: 'with_tick',
                text: 'With coworkers or business associates',
                value: 'business_associates',
            },
        ],
    },

    {
        type: 'question',
        step: 11,
        nextStep: 12,
        prevStep: 10,
        mainQuestion: 2,
        progress: 60,
        analyticsKey: 'do_you_agree',
        title: 'Do you agree with the following:',
        desc: "“I don't like crowded fishing spots”",
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'Completely agree',
                value: 'completely_agree',
            },
            {
                type: 'radio',
                text: 'Agree',
                value: 'agree',
            },
            {
                type: 'radio',
                text: 'Neutral',
                value: 'neutral',
            },
            {
                type: 'radio',
                text: 'Disagree',
                value: 'disagree',
            },
            {
                type: 'radio',
                text: 'Totally disagree',
                value: 'totally_disagree',
            },
        ],
    },

    {
        type: 'question',
        step: 12,
        nextStep: 13,
        prevStep: 11,
        mainQuestion: 2,
        progress: 80,
        analyticsKey: 'how_often_disappointed',
        title: 'How often are you disappointed with your fishing trip?',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'Often',
                value: 'often',
            },
            {
                type: 'radio',
                text: 'Sometimes',
                value: 'sometimes',
            },
            {
                type: 'radio',
                text: 'Rarely',
                value: 'rarely',
            },
            {
                type: 'radio',
                text: 'Never',
                value: 'never',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 2,
        step: 13,
        nextStep: 14,
        prevStep: 12,
        progress: 90,
        analyticsKey: 'what_difficulties',
        title: 'What difficulties are you experiencing most often?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: "I don't know when fish is biting",
                value: 'fish_is_biting',
            },
            {
                type: 'with_tick',
                text: " I don't know spots where fish are active",
                value: 'spots',
            },
            {
                type: 'with_tick',
                text: "I don't know what fish are habitat in a particular spot",
                value: 'fish_are_habitat',
            },
            {
                type: 'with_tick',
                text: " I don't know what gear I need",
                value: 'what_gear',
            },
            {
                type: 'with_tick',
                text: "I don't know spot's rules and regulations",
                value: 'regulations',
            },
            {
                type: 'with_tick',
                text: 'Bad weather',
                value: 'bad_weather',
            },
            {
                type: 'with_tick',
                text: 'Fishing gear is a way too expensive',
                value: 'too_expensive',
            },
            {
                type: 'with_tick',
                text: "I don't have enough time 😭",
                value: 'enough_time',
            },
        ],
    },

    {
        type: 'screen_2',
        mainQuestion: 3,
        step: 14,
        nextStep: 15,
        progress: 0,
        img: screen2CaSalt,
        analyticsKey: 'you_are_not_alone',
        withOutAnalyticsValue: true,
        buttonText: 'Continue',
        isButtonEnabled: true,
    },

    {
        type: 'state',
        mainQuestion: 3,
        step: 15,
        nextStep: 16,
        prevStep: 14,
        progress: 30,
        analyticsKey: 'your_state',
        buttonText: 'Continue',
        titleText: 'Choose your part of the UK:',
    },

    {
        type: 'city',
        mainQuestion: 3,
        step: 16,
        nextStep: 17,
        prevStep: 15,
        progress: 60,
        analyticsKey: 'your_city',
        img: city,
        buttonText: 'Continue',
    },

    {
        type: 'question',
        step: 17,
        nextStep: 18,
        prevStep: 16,
        mainQuestion: 3,
        progress: 90,
        analyticsKey: 'how_far',
        title: 'How far from the city are you ready to go on your fishing trip?',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'Up to 25 miles',
                value: '25_miles',
            },
            {
                type: 'radio',
                text: 'Up to 50 miles',
                value: '50_miles',
            },
            {
                type: 'radio',
                text: 'Up to 100 miles',
                value: '100_miles',
            },
            {
                type: 'radio',
                text: 'Up to 200 miles',
                value: '200_miles',
            },
            {
                type: 'radio',
                text: 'Up to 200+ miles',
                value: 'more_200_miles',
            },
        ],
    },

    {
        type: 'loading',
        step: 18,
        nextStep: 19,
        withoutButton: true,
        data: {
            eventFillName: 'analyzing answers',
            nextRoute: '/onboarding/PersonalizationD2',
            title: 'Analyzing your answers...',
            subtitle: 'Trusted by over 149,569 people',
            loadingArrays: [
                {
                    text: 'Analysis of water bodies nearby',
                    title: 'Analyzing your answers...',
                    color: '#5682F2',
                    steps: [
                        {
                            finish: 30,
                            timeout: 133,
                        },
                        {
                            finish: 70,
                            timeout: 70,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
                {
                    text: 'Calculation of biting activity',
                    color: '#4ABCE1',
                    title: 'Analyzing weather parameters...',
                    steps: [
                        {
                            finish: 30,
                            timeout: 133,
                        },
                        {
                            finish: 70,
                            timeout: 50,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
                {
                    text: 'Generating Your Personal Map',
                    color: '#39CAB9',
                    title: 'Looking for hotspots near you...',
                    steps: [
                        {
                            finish: 30,
                            timeout: 100,
                        },
                        {
                            finish: 70,
                            timeout: 50,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
            ],
            loadingArraysSecond: [
                {
                    text: 'Generating Your Personal Map',
                    color: '#39CAB9',
                    title: 'Looking for hotspots near you...',
                    titles: [
                        {
                            text: 'Analyzing weather parameters...',
                            percent: 0,
                        },
                        {
                            text: 'Looking for hotspots near you...',
                            percent: 64,
                        },
                        {
                            text: 'Your personal map is complete',
                            percent: 98,
                        },
                    ],

                    steps: [
                        {
                            finish: 30,
                            timeout: 200,
                        },
                        {
                            finish: 70,
                            timeout: 100,
                        },
                        {
                            finish: 100,
                            timeout: 66,
                        },
                    ],
                },
            ],
            loadingArraysThird: TWEAKS_LOADERS.analyze_loaders,
            loadingTextArray: [
                {
                    startPercent: 0,
                    finishPercent: 50,
                    text: 'Connecting to database',
                },
                {
                    startPercent: 51,
                    finishPercent: 99,
                    text: 'Preliminary processing',
                },
            ],
        },
    },

    {
        type: 'email',
        step: 19,
        nextStep: 20,
        analyticsKey: 'email',
        withoutButton: true,
        title: 'Where should we send your personalized hotspots & fishing map',
        desc: 'Enter your email to get started',
        buttonText: 'See my map',
        defaultFreshFish: 'common_carp',
        defaultSaltFish: 'cod',
    },

    {
        type: 'landing',
        step: 20,
    },
];

const stepsLures = [
    {
        type: 'first_screen',
        mainQuestion: 1,
        progress: 20,
        step: -1,
        withoutButton: true,
        noSet: true,
        screens: {
            1: {
                img: firstScreenLures,
                title: 'Explore Hot Spots & Best Lures!',
                text: 'Answer a quick quiz to receive your personal hot spots map',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                prevStep: 0,
                analyticsKey: 'type_of_water',
                answers: [
                    {
                        type: 'button',
                        text: 'Fresh',
                        img: fresh,
                        value: 'fresh',
                    },
                    {
                        type: 'button',
                        text: 'Salt',
                        img: salt,
                        value: 'salt',
                    },
                ],
            },
            2: {
                img: firstScreen,
                imgDesc: '*828 top spots in US',
                title: 'Discover new fishing spots nearby tailored to your fishing preferences',
                nextStep: 1,
                prevStep: 0,
                loadingArrays: [
                    {
                        title: 'Loading the test',
                        color: '#0A364A',
                        colorLoader: '#5682F2',
                        steps: [
                            {
                                finish: 30,
                                timeout: 133,
                            },
                            {
                                finish: 70,
                                timeout: 70,
                            },
                            {
                                finish: 100,
                                timeout: 33,
                            },
                        ],
                    },
                ],
            },
            [FIRST_SCREEN_VARIANT_WATER_V1]: {
                img: firstScreen,
                title: 'Your Fishing Map is Almost Ready!',
                text: 'Just answer 3 quick questions to customize it.',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                mainQuestion: 1,
                prevStep: 0,
                progress: 20,
                analyticsKey: 'type_of_water',
                answerType: 'single',
                answers: [
                    {
                        type: 'with_img',
                        text: 'Fresh',
                        img: freshFS,
                        value: 'fresh',
                    },
                    {
                        type: 'with_img',
                        text: 'Salt',
                        img: saltFS,
                        value: 'salt',
                    },
                ],
            },
            [FIRST_SCREEN_VARIANT_WATER_V2]: {
                title: ' Find the best fishing spots near you',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                mainQuestion: 1,
                prevStep: 0,
                progress: 20,
                analyticsKey: 'type_of_water',
                answerType: 'single',
                answers: [
                    {
                        text: 'Fresh',
                        type: 'button',
                        value: 'fresh',
                    },
                    {
                        text: 'Salt',
                        type: 'button',
                        value: 'salt',
                    },
                ],
            },
        },
    },
    {
        type: 'question',
        step: 0,
        analyticsKey: 'type_of_water',
        nextStep: 1,
        mainQuestion: 1,
        progress: 0,
        title: 'Discover Your Perfect Fishing Map',
        text: 'Answer a quick customized quiz to receive your hotspots map and 7-day fishing forecast.',
        desc: 'Select the type of water you usually fish in:',
        answerType: 'single',
        answers: [
            {
                type: 'with_img',
                text: 'Fresh',
                img: fresh,
                value: 'fresh',
            },
            {
                type: 'with_img',
                text: 'Salt',
                img: salt,
                value: 'salt',
            },
            {
                type: 'radio',
                text: 'Both',
                value: 'both',
                textStyles: '!text-center',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 1,
        nextStep: 2,
        prevStep: 0,
        prevStepAlt: -1,
        progress: 20,
        analyticsKey: 'where_are_you_fishing',
        title: 'Where are you enjoying fishing most of all?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Boat or Kayak',
                img: boat,
                value: 'boat',
            },
            {
                type: 'with_img',
                text: 'Shore or Bank',
                img: off,
                value: 'bank',
            },
            {
                type: 'with_img',
                text: 'Pier or dock',
                img: pier,
                value: 'pier_or_dock',
            },
            {
                type: 'with_img',
                text: 'Wading position',
                img: wading,
                value: 'wading_position',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 2,
        nextStep: 3,
        prevStep: 1,
        progress: 30,
        analyticsKey: 'what_waterbodies',
        title: 'What waterbodies you usually fish at?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Lakes',
                img: lake,
                value: 'lakes',
            },
            {
                type: 'with_img',
                text: 'Rivers',
                img: rivers,
                value: 'rivers',
            },
            {
                type: 'with_img',
                text: 'Ponds',
                img: ponds,
                value: 'ponds',
            },
            {
                type: 'with_img',
                text: 'Creeks',
                img: creeks,
                value: 'creeks',
            },
            {
                type: 'with_img',
                text: 'Bays',
                img: bays,
                value: 'bays',
            },
            {
                type: 'with_img',
                text: 'Ocean',
                img: ocean,
                value: 'ocean',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 3,
        nextStep: 4,
        prevStep: 2,
        progress: 45,
        analyticsKey: 'what_fish',
        title: 'What fish species do you usually target?',
        text: 'This information will help us understand your preferences.',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Largemouth Bass',
                img: larg,
                value: 'largemous_bass',
            },
            {
                type: 'with_img',
                text: 'Crappie',
                img: crappie,
                value: 'crappie',
            },
            {
                type: 'with_img',
                text: 'Bluegill',
                img: bluegill,
                value: 'bluegill',
            },
            {
                type: 'with_img',
                text: 'Common Snook',
                img: common,
                value: 'common_snook',
            },
            {
                type: 'with_img',
                text: 'Smallmouth Bass',
                img: small,
                value: 'smallmouth_bass',
            },
            {
                type: 'with_img',
                text: 'Channel Catfish',
                img: channel,
                value: 'channel_catfish',
            },
            {
                type: 'with_img',
                text: 'Redfish',
                img: red,
                value: 'redfish',
            },
            {
                type: 'with_img',
                text: 'Walleye',
                img: walleye,
                value: 'walleye',
            },
            {
                type: 'with_img',
                text: 'Brown Trout',
                img: brown,
                value: 'brown_trout',
            },
            {
                type: 'with_img',
                text: 'Northern pike',
                img: north,
                value: 'northern_pike',
            },
            {
                type: 'with_img',
                text: 'Muskie',
                img: muskie,
                value: 'muskie',
            },
            {
                type: 'with_img',
                text: 'Panfish',
                img: panfish,
                value: 'panfish',
            },
            {
                type: 'with_img',
                text: 'Red snapper',
                img: snapper,
                value: 'red_snapper',
            },
            {
                type: 'with_img',
                text: 'Tuna',
                img: tuna,
                value: 'tuna',
            },
            {
                type: 'with_img',
                text: 'Mahi-mahi',
                img: mahi,
                value: 'mahi_mahi',
            },
            {
                type: 'with_img',
                text: 'Flounder',
                img: flounder,
                value: 'flounder',
            },
            {
                type: 'with_img',
                text: 'Grouper',
                img: grouper,
                value: 'grouper',
            },
            {
                type: 'with_img',
                text: 'Striped bass',
                img: striped,
                value: 'striped_bass',
            },
            {
                type: 'with_img',
                text: 'Tarpon',
                img: tarpon,
                value: 'tarpon',
            },
            {
                type: 'with_img',
                text: 'Bluefish',
                img: bluefish,
                value: 'bluefish',
            },
            {
                type: 'with_img',
                text: 'Other freshwater fish',
                img: otherFresh,
                imgStyles: 'w-[93px] h-[88px] !min-h-[auto] !min-w-[auto]',
                value: 'other_freshwater_fish',
            },
            {
                type: 'with_img',
                text: 'Other saltwater fish',
                img: otherSalt,
                imgStyles: 'w-[93px] h-[88px] !min-h-[auto] !min-w-[auto]',
                value: 'other_saltwater_fish',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 4,
        nextStep: 5,
        prevStep: 3,
        progress: 60,
        analyticsKey: 'what_time',
        title: 'What time intervals you usually choose to plan your fishing trip?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'Early morning (from 5 a.m. till 9 a.m.)',
                value: 'early_morning',
            },
            {
                type: 'with_tick',
                text: 'Late morning (from 9 a.m. till 12 p.m.)',
                value: 'late_morning',
            },
            {
                type: 'with_tick',
                text: 'Afternoon (from 12 p.m. till 6 p.m.)',
                value: 'afternoon',
            },
            {
                type: 'with_tick',
                text: 'Late evening (from 6 p.m. till 10 p.m.)',
                value: 'late_evening',
            },
            {
                type: 'with_tick',
                text: 'Night time (from 10 p.m. till 5 a.m.)',
                value: 'night_time',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 5,
        nextStep: 6,
        prevStep: 4,
        progress: 75,
        analyticsKey: 'what_angling_method',
        title: 'What angling method do you usually use?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'Bottom fishing',
                value: 'bottom_fishing',
            },
            {
                type: 'with_tick',
                text: 'Fly-fishing',
                value: 'fly_fishing',
            },
            {
                type: 'with_tick',
                text: 'Spin fishing',
                value: 'spin_fishing',
            },
            {
                type: 'with_tick',
                text: 'Jigging',
                value: 'jigging',
            },
            {
                type: 'with_tick',
                text: 'Trolling',
                value: 'trolling',
            },
            {
                type: 'with_tick',
                text: 'Casting',
                value: 'casting',
            },
            {
                type: 'with_tick',
                text: 'Other method',
                value: 'other_method',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 6,
        nextStep: 7,
        prevStep: 5,
        progress: 90,
        analyticsKey: 'what_kind_of_lures',
        title: 'What kind of fishing baits/lures do you use most often?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Flies',
                img: flies,
                value: 'flies',
            },
            {
                type: 'with_img',
                text: 'Jigs',
                img: jigs,
                value: 'jigs',
            },
            {
                type: 'with_img',
                text: 'Spinnerbaits & Buzzbaits',
                img: spinner,
                value: 'spinnerbaits_buzzbaits',
            },
            {
                type: 'with_img',
                text: 'Plugs',
                img: plugs,
                value: 'plugs',
            },
            {
                type: 'with_img',
                text: 'Spoons',
                img: spoons,
                value: 'spoons',
            },
            {
                type: 'with_img',
                text: 'Soft bait lures',
                img: soft,
                value: 'soft_bait_lures',
            },
            {
                type: 'with_img',
                text: 'Other lures',
                img: other,
                imgStyles: 'w-[93px] h-[88px] !min-h-[auto] !min-w-[auto]',
                value: 'other_lures',
            },
            {
                type: 'with_img',
                text: 'Insects',
                img: insects,
                value: 'insects',
            },
            {
                type: 'with_img',
                text: 'Minnows',
                img: minnows,
                value: 'minnows',
            },
            {
                type: 'with_img',
                text: 'Maggots',
                img: maggots,
                value: 'maggots',
            },
            {
                type: 'with_img',
                text: 'Worms',
                img: worms,
                value: 'worms',
            },
            {
                type: 'with_img',
                text: 'Other baits',
                img: baits,
                value: 'other_baits',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 7,
        nextStep: 8,
        prevStep: 6,
        progress: 95,
        analyticsKey: 'in_which_layer_of_water',
        title: 'In which layer of water do you usually catch fish?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'I like to fish in shallow water, around 3-5 feet deep',
                value: '3_5_feet_deep',
            },
            {
                type: 'with_tick',
                text: 'It depends on my current target species, but I usually fish in a 10-15 feet deep water',
                value: '10_15_feet_deep',
            },
            {
                type: 'with_tick',
                text: 'I prefer to fish in deeper water, around 20-30 feet deep',
                value: '20_30_feet_deep',
            },
            {
                type: 'with_tick',
                text: 'I fish in water that is 25+ feet deep',
                value: '25_feet_deep',
            },
        ],
    },

    {
        type: 'screen_1',
        mainQuestion: 2,
        step: 8,
        nextStep: 9,
        progress: 0,
        analyticsKey: 'our_algorithms',
        withOutAnalyticsValue: true,
        title: 'We find the best places based on 21 parameters',
        text: "We will select for you the best fishing spots and lures & baits in your area based on real-time trends, which means you'll always fish in areas filled with fish, and be spared from long preparations for each trip.",
        help: 'Fishbox™ Team',
        img: screen1,
        isImgDynamic: true,
        buttonText: 'Sounds good',
        isButtonEnabled: true,
    },

    {
        type: 'question',
        step: 9,
        nextStep: 10,
        prevStep: 8,
        mainQuestion: 2,
        progress: 20,
        analyticsKey: 'what_describes_you_better',
        title: 'What describes you better?',
        text: 'This information will help us understand your experience.',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'I’m a beginner',
                value: 'beginner',
            },
            {
                type: 'radio',
                text: 'I’m an amateur',
                value: 'amateur',
            },
            {
                type: 'radio',
                text: 'I’m an experienced angler',
                value: 'experienced',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 2,
        step: 10,
        nextStep: 11,
        prevStep: 9,
        progress: 40,
        analyticsKey: 'who_do_you_go_fishing_with',
        title: 'Who do you usually go fishing with?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'I fish alone',
                value: 'alone',
            },
            {
                type: 'with_tick',
                text: 'With family members',
                value: 'family',
            },
            {
                type: 'with_tick',
                text: 'With friends',
                value: 'friends',
            },
            {
                type: 'with_tick',
                text: 'With a professional guide',
                value: 'professional_guide',
            },
            {
                type: 'with_tick',
                text: 'With coworkers or business associates',
                value: 'business_associates',
            },
        ],
    },

    {
        type: 'question',
        step: 11,
        nextStep: 12,
        prevStep: 10,
        mainQuestion: 2,
        progress: 60,
        analyticsKey: 'do_you_agree',
        title: 'Do you agree with the following:',
        desc: "“I don't like crowded fishing spots”",
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'Completely agree',
                value: 'completely_agree',
            },
            {
                type: 'radio',
                text: 'Agree',
                value: 'agree',
            },
            {
                type: 'radio',
                text: 'Neutral',
                value: 'neutral',
            },
            {
                type: 'radio',
                text: 'Disagree',
                value: 'disagree',
            },
            {
                type: 'radio',
                text: 'Totally disagree',
                value: 'totally_disagree',
            },
        ],
    },

    {
        type: 'question',
        step: 12,
        nextStep: 13,
        prevStep: 11,
        mainQuestion: 2,
        progress: 80,
        analyticsKey: 'how_often_disappointed',
        title: 'How often are you disappointed with your fishing trip?',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'Often',
                value: 'often',
            },
            {
                type: 'radio',
                text: 'Sometimes',
                value: 'sometimes',
            },
            {
                type: 'radio',
                text: 'Rarely',
                value: 'rarely',
            },
            {
                type: 'radio',
                text: 'Never',
                value: 'never',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 2,
        step: 13,
        nextStep: 14,
        prevStep: 12,
        progress: 90,
        analyticsKey: 'what_difficulties',
        title: 'What difficulties are you experiencing most often?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: "I don't know what lures or baits to use",
                value: 'what_gear',
            },
            {
                type: 'with_tick',
                text: "I don't know when fish is biting",
                value: 'fish_is_biting',
            },
            {
                type: 'with_tick',
                text: " I don't know spots where fish are active",
                value: 'spots',
            },
            {
                type: 'with_tick',
                text: "I don't know what fish are habitat in a particular spot",
                value: 'fish_are_habitat',
            },
            {
                type: 'with_tick',
                text: "I don't know spot's rules and regulations",
                value: 'regulations',
            },
            {
                type: 'with_tick',
                text: 'Bad weather',
                value: 'bad_weather',
            },
            {
                type: 'with_tick',
                text: 'Fishing gear is a way too expensive',
                value: 'too_expensive',
            },
            {
                type: 'with_tick',
                text: "I don't have enough time 😭",
                value: 'enough_time',
            },
        ],
    },

    {
        type: 'screen_2',
        mainQuestion: 3,
        step: 14,
        nextStep: 15,
        progress: 0,
        img: screen2Lures,
        fullwidth: true,
        analyticsKey: 'you_are_not_alone',
        withOutAnalyticsValue: true,
        buttonText: 'Continue',
        isButtonEnabled: true,
        dynamicText:
            "So don't give up just yet! Keep answering the questions. And you'll find your best lures & baits and favorite spot. Tight lines!”",
    },

    {
        type: 'state',
        mainQuestion: 3,
        step: 15,
        nextStep: 16,
        prevStep: 14,
        progress: 30,
        analyticsKey: 'your_state',
        buttonText: 'Continue',
        titleText: 'Choose your state:',
    },

    {
        type: 'city',
        mainQuestion: 3,
        step: 16,
        nextStep: 17,
        prevStep: 15,
        progress: 60,
        analyticsKey: 'your_city',
        img: city,
        buttonText: 'Continue',
    },

    {
        type: 'question',
        step: 17,
        nextStep: 18,
        prevStep: 16,
        mainQuestion: 3,
        progress: 90,
        analyticsKey: 'how_far',
        title: 'How far from the city are you ready to go on your fishing trip?',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'Up to 25 miles',
                value: '25_miles',
            },
            {
                type: 'radio',
                text: 'Up to 50 miles',
                value: '50_miles',
            },
            {
                type: 'radio',
                text: 'Up to 100 miles',
                value: '100_miles',
            },
            {
                type: 'radio',
                text: 'Up to 200 miles',
                value: '200_miles',
            },
            {
                type: 'radio',
                text: 'Up to 200+ miles',
                value: 'more_200_miles',
            },
        ],
    },

    {
        type: 'loading',
        step: 18,
        nextStep: 19,
        withoutButton: true,
        data: {
            eventFillName: 'analyzing answers',
            nextRoute: '/onboarding/PersonalizationD2',
            title: 'Analyzing your answers...',
            subtitle: 'Trusted by over 149,569 people',
            loadingArrays: [
                {
                    text: 'Analysis of water bodies nearby',
                    title: 'Analyzing your answers...',
                    color: '#5682F2',
                    steps: [
                        {
                            finish: 30,
                            timeout: 133,
                        },
                        {
                            finish: 70,
                            timeout: 70,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
                {
                    text: 'Calculation of biting activity',
                    color: '#4ABCE1',
                    title: 'Analyzing weather parameters...',
                    steps: [
                        {
                            finish: 30,
                            timeout: 133,
                        },
                        {
                            finish: 70,
                            timeout: 50,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
                {
                    text: 'Generating Your Personal Map',
                    color: '#39CAB9',
                    title: 'Looking for hotspots near you...',
                    steps: [
                        {
                            finish: 30,
                            timeout: 100,
                        },
                        {
                            finish: 70,
                            timeout: 50,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
            ],
            loadingArraysSecond: [
                {
                    text: 'Generating Your Personal Map',
                    color: '#39CAB9',
                    title: 'Looking for hotspots near you...',
                    titles: [
                        {
                            text: 'Analyzing weather parameters...',
                            percent: 0,
                        },
                        {
                            text: 'Looking for hotspots near you...',
                            percent: 64,
                        },
                        {
                            text: 'Your personal map is complete',
                            percent: 98,
                        },
                    ],

                    steps: [
                        {
                            finish: 30,
                            timeout: 200,
                        },
                        {
                            finish: 70,
                            timeout: 100,
                        },
                        {
                            finish: 100,
                            timeout: 66,
                        },
                    ],
                },
            ],
            loadingArraysThird: TWEAKS_LOADERS.analyze_loaders,
            loadingTextArray: [
                {
                    startPercent: 0,
                    finishPercent: 50,
                    text: 'Connecting to database',
                },
                {
                    startPercent: 51,
                    finishPercent: 99,
                    text: 'Preliminary processing',
                },
            ],
        },
    },

    {
        type: 'email',
        step: 19,
        nextStep: 20,
        analyticsKey: 'email',
        withoutButton: true,
        title: 'Where should we send your personalized hotspots & fishing map',
        desc: 'Enter your email to get started',
        buttonText: 'See my map',
    },

    {
        type: 'landing',
        step: 20,
    },
];

const stepsFresh = [
    {
        type: 'first_screen',
        mainQuestion: 1,
        progress: 20,
        step: -1,
        withoutButton: true,
        noSet: true,
        screens: {
            1: {
                img: firstScreen,
                title: 'Find the best fishing spots near you',
                text: 'Answer a quick quiz to receive your personal hot spots map',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                prevStep: 0,
                analyticsKey: 'type_of_water',
                answers: [
                    {
                        type: 'button',
                        text: 'Fresh',
                        img: fresh,
                        value: 'fresh',
                    },
                    {
                        type: 'button',
                        text: 'Salt',
                        img: salt,
                        value: 'salt',
                    },
                ],
            },
            2: {
                img: firstScreen,
                imgDesc: '*828 top spots in US',
                title: 'Discover new fishing spots nearby tailored to your fishing preferences',
                nextStep: 1,
                prevStep: 0,
                loadingArrays: [
                    {
                        title: 'Loading the test',
                        color: '#0A364A',
                        colorLoader: '#5682F2',
                        steps: [
                            {
                                finish: 30,
                                timeout: 133,
                            },
                            {
                                finish: 70,
                                timeout: 70,
                            },
                            {
                                finish: 100,
                                timeout: 33,
                            },
                        ],
                    },
                ],
            },
            [FIRST_SCREEN_VARIANT_WATER_V1]: {
                img: firstScreen,
                title: 'Your Fishing Map is Almost Ready!',
                text: 'Just answer 3 quick questions to customize it.',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                mainQuestion: 1,
                prevStep: 0,
                progress: 20,
                analyticsKey: 'type_of_water',
                answerType: 'single',
                answers: [
                    {
                        type: 'with_img',
                        text: 'Fresh',
                        img: freshFS,
                        value: 'fresh',
                    },
                    {
                        type: 'with_img',
                        text: 'Salt',
                        img: saltFS,
                        value: 'salt',
                    },
                ],
            },
            [FIRST_SCREEN_VARIANT_WATER_V2]: {
                title: ' Find the best fishing spots near you',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                mainQuestion: 1,
                prevStep: 0,
                progress: 20,
                analyticsKey: 'type_of_water',
                answerType: 'single',
                answers: [
                    {
                        text: 'Fresh',
                        type: 'button',
                        value: 'fresh',
                    },
                    {
                        text: 'Salt',
                        type: 'button',
                        value: 'salt',
                    },
                ],
            },
        },
    },
    {
        type: 'question',
        step: 0,
        analyticsKey: 'type_of_water',
        nextStep: 1,
        mainQuestion: 1,
        progress: 0,
        title: 'Discover Your Perfect Fishing Map',
        text: 'Answer a quick customized quiz to receive your hotspots map and 7-day fishing forecast.',
        desc: 'Select the type of water you usually fish in:',
        answerType: 'single',
        answers: [
            {
                type: 'with_img',
                text: 'Fresh us',
                img: fresh,
                value: 'fresh',
            },
            {
                type: 'with_img',
                text: 'Salt',
                img: salt,
                value: 'salt',
            },
            {
                type: 'radio',
                text: 'Both',
                value: 'both',
                textStyles: '!text-center',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 1,
        nextStep: 2,
        prevStep: 0,
        prevStepAlt: -1,
        progress: 20,
        analyticsKey: 'where_are_you_fishing',
        title: 'Where are you enjoying fishing most of all?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Boat',
                img: boat,
                value: 'boat',
            },
            {
                type: 'with_img',
                text: 'Bank',
                img: off,
                value: 'bank',
            },
            {
                type: 'with_img',
                text: 'Pier or dock',
                img: pier,
                value: 'pier_or_dock',
            },
            {
                type: 'with_img',
                text: 'Wading position',
                img: wading,
                value: 'wading_position',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 1,
        step: 2,
        nextStep: 3,
        prevStep: 1,
        progress: 30,
        analyticsKey: 'what_waterbodies',
        title: 'What waterbodies you usually fish at?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Lakes',
                img: lake,
                value: 'lakes',
            },
            {
                type: 'with_img',
                text: 'Rivers',
                img: rivers,
                value: 'rivers',
            },
            {
                type: 'with_img',
                text: 'Ponds',
                img: ponds2,
                value: 'ponds',
            },
            {
                type: 'with_img',
                text: 'Creeks',
                img: creeks,
                value: 'creeks',
            },
            {
                type: 'with_img',
                text: 'Reservoirs',
                img: reservioirs,
                value: 'reservoirs',
            },
            {
                type: 'with_img',
                text: 'Swamps',
                img: swamps,
                value: 'swamps',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 1,
        step: 3,
        nextStep: 4,
        prevStep: 2,
        progress: 45,
        analyticsKey: 'what_fish',
        title: 'What fish species do you usually target?',
        text: 'This information will help us understand your preferences.',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Largemouth Bass',
                img: larg,
                value: 'largemous_bass',
            },
            {
                type: 'with_img',
                text: 'Crappie',
                img: crappie,
                value: 'crappie',
            },
            {
                type: 'with_img',
                text: 'Bluegill',
                img: bluegill,
                value: 'bluegill',
            },
            {
                type: 'with_img',
                text: 'Smallmouth Bass',
                img: small,
                value: 'smallmouth_bass',
            },
            {
                type: 'with_img',
                text: 'Channel Catfish',
                img: channel,
                value: 'channel_catfish',
            },
            {
                type: 'with_img',
                text: 'Redfish',
                img: red,
                value: 'redfish',
            },
            {
                type: 'with_img',
                text: 'White Bass',
                img: whiteBass,
                value: 'white_bass',
            },
            {
                type: 'with_img',
                text: 'Walleye',
                img: walleye,
                value: 'walleye',
            },
            {
                type: 'with_img',
                text: 'Brown Trout',
                img: brown,
                value: 'brown_trout',
            },
            {
                type: 'with_img',
                text: 'Northern pike',
                img: north,
                value: 'northern_pike',
            },

            {
                type: 'with_img',
                text: 'Common Carp',
                img: commonCarp,
                value: 'common_carp',
            },
            {
                type: 'with_img',
                text: 'Yellow Perch',
                img: yellowPerch,
                value: 'yellow_perch',
            },
            {
                type: 'with_img',
                text: 'Bullhead Catfish',
                img: bullheadCatfish,
                value: 'bullhead_catfish',
            },
            {
                type: 'with_img',
                text: 'Striped bass',
                img: striped,
                value: 'striped_bass',
            },

            {
                type: 'with_img',
                text: 'Muskie',
                img: muskie,
                value: 'muskie',
            },
            {
                type: 'with_img',
                text: 'Panfish',
                img: panfish,
                value: 'panfish',
            },
            {
                type: 'with_img',
                text: 'Rainbow Trout',
                img: rainbowTrout,
                value: 'rainbow_trout',
            },
            {
                type: 'with_img',
                text: 'Sturgeon',
                img: sturgeon,
                value: 'sturgeon',
            },
            {
                type: 'with_img',
                text: 'Freshwater Drum',
                img: freshwaterDrum,
                value: 'freshwater_drum',
            },
            {
                type: 'with_img',
                text: 'Gar',
                img: gar,
                value: 'gar',
            },
            {
                type: 'with_img',
                text: 'Shad',
                img: shad,
                value: 'shad',
            },
            {
                type: 'with_img',
                text: 'Other freshwater fish',
                img: otherFresh,
                imgStyles: 'w-[93px] h-[88px] !min-h-[auto] !min-w-[auto]',
                value: 'other_freshwater_fish',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 1,
        step: 3,
        nextStep: 4,
        prevStep: 2,
        progress: 45,
        analyticsKey: 'what_fish',
        title: 'What fish species do you usually target?',
        text: 'This information will help us understand your preferences.',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Largemouth Bass',
                img: larg,
                value: 'largemous_bass',
            },
            {
                type: 'with_img',
                text: 'Crappie',
                img: crappie,
                value: 'crappie',
            },
            {
                type: 'with_img',
                text: 'Bluegill',
                img: bluegill,
                value: 'bluegill',
            },
            {
                type: 'with_img',
                text: 'Common Snook',
                img: common,
                value: 'common_snook',
            },
            {
                type: 'with_img',
                text: 'Smallmouth Bass',
                img: small,
                value: 'smallmouth_bass',
            },
            {
                type: 'with_img',
                text: 'Channel Catfish',
                img: channel,
                value: 'channel_catfish',
            },
            {
                type: 'with_img',
                text: 'Redfish',
                img: red,
                value: 'redfish',
            },
            {
                type: 'with_img',
                text: 'Walleye',
                img: walleye,
                value: 'walleye',
            },
            {
                type: 'with_img',
                text: 'Brown Trout',
                img: brown,
                value: 'brown_trout',
            },
            {
                type: 'with_img',
                text: 'Northern pike',
                img: north,
                value: 'northern_pike',
            },
            {
                type: 'with_img',
                text: 'Muskie',
                img: muskie,
                value: 'muskie',
            },
            {
                type: 'with_img',
                text: 'Panfish',
                img: panfish,
                value: 'panfish',
            },
            {
                type: 'with_img',
                text: 'Red snapper',
                img: snapper,
                value: 'red_snapper',
            },
            {
                type: 'with_img',
                text: 'Tuna',
                img: tuna,
                value: 'tuna',
            },
            {
                type: 'with_img',
                text: 'Mahi-mahi',
                img: mahi,
                value: 'mahi_mahi',
            },
            {
                type: 'with_img',
                text: 'Flounder',
                img: flounder,
                value: 'flounder',
            },
            {
                type: 'with_img',
                text: 'Grouper',
                img: grouper,
                value: 'grouper',
            },
            {
                type: 'with_img',
                text: 'Striped bass',
                img: striped,
                value: 'striped_bass',
            },
            {
                type: 'with_img',
                text: 'Tarpon',
                img: tarpon,
                value: 'tarpon',
            },
            {
                type: 'with_img',
                text: 'Bluefish',
                img: bluefish,
                value: 'bluefish',
            },
            {
                type: 'with_img',
                text: 'Other freshwater fish',
                img: otherFresh,
                imgStyles: 'w-[93px] h-[88px] !min-h-[auto] !min-w-[auto]',
                value: 'other_freshwater_fish',
            },
            {
                type: 'with_img',
                text: 'Other saltwater fish',
                img: otherSalt,
                imgStyles: 'w-[93px] h-[88px] !min-h-[auto] !min-w-[auto]',
                value: 'other_saltwater_fish',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 4,
        nextStep: 5,
        prevStep: 3,
        progress: 60,
        analyticsKey: 'what_time',
        title: 'What time intervals you usually choose to plan your fishing trip?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'Early morning (from 5 a.m. till 9 a.m.)',
                value: 'early_morning',
            },
            {
                type: 'with_tick',
                text: 'Late morning (from 9 a.m. till 12 p.m.)',
                value: 'late_morning',
            },
            {
                type: 'with_tick',
                text: 'Afternoon (from 12 p.m. till 6 p.m.)',
                value: 'afternoon',
            },
            {
                type: 'with_tick',
                text: 'Late evening (from 6 p.m. till 10 p.m.)',
                value: 'late_evening',
            },
            {
                type: 'with_tick',
                text: 'Night time (from 10 p.m. till 5 a.m.)',
                value: 'night_time',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 5,
        nextStep: 6,
        prevStep: 4,
        progress: 75,
        analyticsKey: 'what_angling_method',
        title: 'What angling method do you usually use?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'Bottom fishing',
                value: 'bottom_fishing',
            },
            {
                type: 'with_tick',
                text: 'Fly-fishing',
                value: 'fly_fishing',
            },
            {
                type: 'with_tick',
                text: 'Spin fishing',
                value: 'spin_fishing',
            },
            {
                type: 'with_tick',
                text: 'Jigging',
                value: 'jigging',
            },
            {
                type: 'with_tick',
                text: 'Trolling',
                value: 'trolling',
            },
            {
                type: 'with_tick',
                text: 'Casting',
                value: 'casting',
            },
            {
                type: 'with_tick',
                text: 'Other method',
                value: 'other_method',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 1,
        step: 6,
        nextStep: 7,
        prevStep: 5,
        progress: 90,
        analyticsKey: 'what_kind_of_lures',
        title: 'What kind of fishing baits/lures do you use most often?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Flies',
                img: flies,
                value: 'flies',
            },
            {
                type: 'with_img',
                text: 'Jigs',
                img: jigs,
                value: 'jigs',
            },
            {
                type: 'with_img',
                text: 'Spinnerbaits & Buzzbaits',
                img: spinner,
                value: 'spinnerbaits_buzzbaits',
            },
            {
                type: 'with_img',
                text: 'Plugs',
                img: plugs,
                value: 'plugs',
            },
            {
                type: 'with_img',
                text: 'Spoons',
                img: spoons,
                value: 'spoons',
            },
            {
                type: 'with_img',
                text: 'Soft bait lures',
                img: soft,
                value: 'soft_bait_lures',
            },
            {
                type: 'with_img',
                text: 'Other lures',
                img: other,
                imgStyles: 'w-[93px] h-[88px] !min-h-[auto] !min-w-[auto]',
                value: 'other_lures',
            },
            {
                type: 'with_img',
                text: 'Insects',
                img: insects,
                value: 'insects',
            },
            {
                type: 'with_img',
                text: 'Minnows',
                img: minnows,
                value: 'minnows',
            },
            {
                type: 'with_img',
                text: 'Maggots',
                img: maggots,
                value: 'maggots',
            },
            {
                type: 'with_img',
                text: 'Worms',
                img: worms,
                value: 'worms',
            },
            {
                type: 'with_img',
                text: 'Other baits',
                img: baits,
                value: 'other_baits',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 1,
        step: 7,
        nextStep: 8,
        prevStep: 6,
        progress: 95,
        analyticsKey: 'in_which_layer_of_water',
        title: 'In which layer of water do you usually catch fish?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'I like to fish in shallow water, around 3-5 feet deep',
                value: '3_5_feet_deep',
            },
            {
                type: 'with_tick',
                text: 'It depends on my current target species, but I usually fish in a 10-15 feet deep water',
                value: '10_15_feet_deep',
            },
            {
                type: 'with_tick',
                text: 'I prefer to fish in deeper water, around 20-30 feet deep',
                value: '20_30_feet_deep',
            },
            {
                type: 'with_tick',
                text: 'I fish in water that is 25+ feet deep',
                value: '25_feet_deep',
            },
        ],
    },

    {
        type: 'screen_1',
        mainQuestion: 2,
        step: 8,
        nextStep: 9,
        progress: 0,
        analyticsKey: 'our_algorithms',
        withOutAnalyticsValue: true,
        title: 'Our algorithms are based on 21 parameters',
        text: "We'll literally show you the best spots to fish in your area based on real-time trends, which means you'll always fish in areas filled with fish, and be spared from long preparations for each trip.",
        help: 'Fishbox™ Team',
        img: screen1,
        buttonText: 'Sounds good',
        isButtonEnabled: true,
    },

    {
        type: 'question',
        step: 9,
        nextStep: 10,
        prevStep: 8,
        mainQuestion: 2,
        progress: 20,
        analyticsKey: 'what_describes_you_better',
        title: 'What describes you better?',
        text: 'This information will help us understand your experience.',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'I’m a beginner',
                value: 'beginner',
            },
            {
                type: 'radio',
                text: 'I’m an amateur',
                value: 'amateur',
            },
            {
                type: 'radio',
                text: 'I’m an experienced angler',
                value: 'experienced',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 2,
        step: 10,
        nextStep: 11,
        prevStep: 9,
        progress: 40,
        analyticsKey: 'who_do_you_go_fishing_with',
        title: 'Who do you usually go fishing with?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'I fish alone',
                value: 'alone',
            },
            {
                type: 'with_tick',
                text: 'With family members',
                value: 'family',
            },
            {
                type: 'with_tick',
                text: 'With friends',
                value: 'friends',
            },
            {
                type: 'with_tick',
                text: 'With a professional guide',
                value: 'professional_guide',
            },
            {
                type: 'with_tick',
                text: 'With coworkers or business associates',
                value: 'business_associates',
            },
        ],
    },

    {
        type: 'question',
        step: 11,
        nextStep: 12,
        prevStep: 10,
        mainQuestion: 2,
        progress: 60,
        analyticsKey: 'do_you_agree',
        title: 'Do you agree with the following:',
        desc: "“I don't like crowded fishing spots”",
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'Completely agree',
                value: 'completely_agree',
            },
            {
                type: 'radio',
                text: 'Agree',
                value: 'agree',
            },
            {
                type: 'radio',
                text: 'Neutral',
                value: 'neutral',
            },
            {
                type: 'radio',
                text: 'Disagree',
                value: 'disagree',
            },
            {
                type: 'radio',
                text: 'Totally disagree',
                value: 'totally_disagree',
            },
        ],
    },

    {
        type: 'question',
        step: 12,
        nextStep: 13,
        prevStep: 11,
        mainQuestion: 2,
        progress: 80,
        analyticsKey: 'how_often_disappointed',
        title: 'How often are you disappointed with your fishing trip?',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'Often',
                value: 'often',
            },
            {
                type: 'radio',
                text: 'Sometimes',
                value: 'sometimes',
            },
            {
                type: 'radio',
                text: 'Rarely',
                value: 'rarely',
            },
            {
                type: 'radio',
                text: 'Never',
                value: 'never',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 2,
        step: 13,
        nextStep: 14,
        prevStep: 12,
        progress: 90,
        analyticsKey: 'what_difficulties',
        title: 'What difficulties are you experiencing most often?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: "I don't know when fish is biting",
                value: 'fish_is_biting',
            },
            {
                type: 'with_tick',
                text: " I don't know spots where fish are active",
                value: 'spots',
            },
            {
                type: 'with_tick',
                text: "I don't know what fish are habitat in a particular spot",
                value: 'fish_are_habitat',
            },
            {
                type: 'with_tick',
                text: " I don't know what gear I need",
                value: 'what_gear',
            },
            {
                type: 'with_tick',
                text: "I don't know spot's rules and regulations",
                value: 'regulations',
            },
            {
                type: 'with_tick',
                text: 'Bad weather',
                value: 'bad_weather',
            },
            {
                type: 'with_tick',
                text: 'Fishing gear is a way too expensive',
                value: 'too_expensive',
            },
            {
                type: 'with_tick',
                text: "I don't have enough time 😭",
                value: 'enough_time',
            },
        ],
    },

    {
        type: 'screen_2',
        mainQuestion: 3,
        step: 14,
        nextStep: 15,
        progress: 0,
        img: screen2,
        analyticsKey: 'you_are_not_alone',
        withOutAnalyticsValue: true,
        buttonText: 'Continue',
        isButtonEnabled: true,
    },

    {
        type: 'state',
        mainQuestion: 3,
        step: 15,
        nextStep: 16,
        prevStep: 14,
        progress: 30,
        analyticsKey: 'your_state',
        buttonText: 'Continue',
        titleText: 'Choose your state:',
    },

    {
        type: 'city',
        mainQuestion: 3,
        step: 16,
        nextStep: 17,
        prevStep: 15,
        progress: 60,
        analyticsKey: 'your_city',
        img: city,
        buttonText: 'Continue',
    },

    {
        type: 'question',
        step: 17,
        nextStep: 18,
        prevStep: 16,
        mainQuestion: 3,
        progress: 90,
        analyticsKey: 'how_far',
        title: 'How far from the city are you ready to go on your fishing trip?',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'Up to 25 miles',
                value: '25_miles',
            },
            {
                type: 'radio',
                text: 'Up to 50 miles',
                value: '50_miles',
            },
            {
                type: 'radio',
                text: 'Up to 100 miles',
                value: '100_miles',
            },
            {
                type: 'radio',
                text: 'Up to 200 miles',
                value: '200_miles',
            },
            {
                type: 'radio',
                text: 'Up to 200+ miles',
                value: 'more_200_miles',
            },
        ],
    },

    {
        type: 'loading',
        step: 18,
        nextStep: 19,
        withoutButton: true,
        data: {
            eventFillName: 'analyzing answers',
            nextRoute: '/onboarding/PersonalizationD2',
            title: 'Analyzing your answers...',
            subtitle: 'Trusted by over 149,569 people',
            loadingArrays: [
                {
                    text: 'Analysis of water bodies nearby',
                    title: 'Analyzing your answers...',
                    color: '#5682F2',
                    steps: [
                        {
                            finish: 30,
                            timeout: 133,
                        },
                        {
                            finish: 70,
                            timeout: 70,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
                {
                    text: 'Calculation of biting activity',
                    color: '#4ABCE1',
                    title: 'Analyzing weather parameters...',
                    steps: [
                        {
                            finish: 30,
                            timeout: 133,
                        },
                        {
                            finish: 70,
                            timeout: 50,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
                {
                    text: 'Generating Your Personal Map',
                    color: '#39CAB9',
                    title: 'Looking for hotspots near you...',
                    steps: [
                        {
                            finish: 30,
                            timeout: 100,
                        },
                        {
                            finish: 70,
                            timeout: 50,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
            ],
            loadingArraysSecond: [
                {
                    text: 'Generating Your Personal Map',
                    color: '#39CAB9',
                    title: 'Looking for hotspots near you...',
                    titles: [
                        {
                            text: 'Analyzing weather parameters...',
                            percent: 0,
                        },
                        {
                            text: 'Looking for hotspots near you...',
                            percent: 64,
                        },
                        {
                            text: 'Your personal map is complete',
                            percent: 98,
                        },
                    ],

                    steps: [
                        {
                            finish: 30,
                            timeout: 200,
                        },
                        {
                            finish: 70,
                            timeout: 100,
                        },
                        {
                            finish: 100,
                            timeout: 66,
                        },
                    ],
                },
            ],
            loadingArraysThird: TWEAKS_LOADERS.analyze_loaders,
            loadingTextArray: [
                {
                    startPercent: 0,
                    finishPercent: 50,
                    text: 'Connecting to database',
                },
                {
                    startPercent: 51,
                    finishPercent: 99,
                    text: 'Preliminary processing',
                },
            ],
        },
    },

    {
        type: 'email',
        step: 19,
        nextStep: 20,
        analyticsKey: 'email',
        withoutButton: true,
        title: 'Where should we send your personalized hotspots & fishing map',
        desc: 'Enter your email to get started',
        buttonText: 'See my map',
    },

    {
        type: 'landing',
        step: 20,
    },
];

const stepsFreshSalt = [
    {
        type: 'first_screen',
        mainQuestion: 1,
        progress: 20,
        step: -1,
        withoutButton: true,
        noSet: true,
        screens: {
            1: {
                img: firstScreen,
                title: 'Find the best fishing spots near you',
                text: 'Answer a quick quiz to receive your personal hot spots map',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                prevStep: 0,
                analyticsKey: 'type_of_water',
                answers: [
                    {
                        type: 'button',
                        text: 'Fresh',
                        img: fresh,
                        value: 'fresh',
                    },
                    {
                        type: 'button',
                        text: 'Salt',
                        img: salt,
                        value: 'salt',
                    },
                ],
            },
            2: {
                img: firstScreen,
                imgDesc: '*828 top spots in US',
                title: 'Discover new fishing spots nearby tailored to your fishing preferences',
                nextStep: 1,
                prevStep: 0,
                loadingArrays: [
                    {
                        title: 'Loading the test',
                        color: '#0A364A',
                        colorLoader: '#5682F2',
                        steps: [
                            {
                                finish: 30,
                                timeout: 133,
                            },
                            {
                                finish: 70,
                                timeout: 70,
                            },
                            {
                                finish: 100,
                                timeout: 33,
                            },
                        ],
                    },
                ],
            },
            [FIRST_SCREEN_VARIANT_WATER_V1]: {
                img: firstScreen,
                title: 'Your Fishing Map is Almost Ready!',
                text: 'Just answer 3 quick questions to customize it.',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                mainQuestion: 1,
                prevStep: 0,
                progress: 20,
                analyticsKey: 'type_of_water',
                answerType: 'single',
                answers: [
                    {
                        type: 'with_img',
                        text: 'Fresh',
                        img: freshFS,
                        value: 'fresh',
                    },
                    {
                        type: 'with_img',
                        text: 'Salt',
                        img: saltFS,
                        value: 'salt',
                    },
                ],
            },
            [FIRST_SCREEN_VARIANT_WATER_V2]: {
                title: ' Find the best fishing spots near you',
                question: 'Select the type of water you usually fish in:',
                nextStep: 1,
                mainQuestion: 1,
                prevStep: 0,
                progress: 20,
                analyticsKey: 'type_of_water',
                answerType: 'single',
                answers: [
                    {
                        text: 'Fresh',
                        type: 'button',
                        value: 'fresh',
                    },
                    {
                        text: 'Salt',
                        type: 'button',
                        value: 'salt',
                    },
                ],
            },
        },
    },
    {
        type: 'question',
        step: 0,
        analyticsKey: 'type_of_water',
        nextStep: 1,
        mainQuestion: 1,
        progress: 0,
        title: 'Discover Your Perfect Fishing Map',
        text: 'Answer a quick customized quiz to receive your hotspots map and 7-day fishing forecast.',
        desc: 'Select the type of water you usually fish in:',
        answerType: 'single',
        answers: [
            {
                type: 'with_img',
                text: 'Fresh us',
                img: fresh,
                value: 'fresh',
            },
            {
                type: 'with_img',
                text: 'Salt',
                img: salt,
                value: 'salt',
            },
            {
                type: 'radio',
                text: 'Both',
                value: 'both',
                textStyles: '!text-center',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 1,
        nextStep: 2,
        prevStep: 0,
        prevStepAlt: -1,
        progress: 20,
        analyticsKey: 'where_are_you_fishing',
        title: 'Where are you enjoying fishing most of all?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Boat',
                img: boat,
                value: 'boat',
            },
            {
                type: 'with_img',
                text: 'Bank',
                img: off,
                value: 'bank',
            },
            {
                type: 'with_img',
                text: 'Pier or dock',
                img: pier,
                value: 'pier_or_dock',
            },
            {
                type: 'with_img',
                text: 'Wading position',
                img: wading,
                value: 'wading_position',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 1,
        step: 2,
        nextStep: 3,
        prevStep: 1,
        progress: 30,
        analyticsKey: 'what_waterbodies',
        title: 'What waterbodies you usually fish at?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Lakes',
                img: lake,
                value: 'lakes',
            },
            {
                type: 'with_img',
                text: 'Rivers',
                img: rivers,
                value: 'rivers',
            },
            {
                type: 'with_img',
                text: 'Ponds',
                img: ponds2,
                value: 'ponds',
            },
            {
                type: 'with_img',
                text: 'Creeks',
                img: creeks,
                value: 'creeks',
            },
            {
                type: 'with_img',
                text: 'Reservoirs',
                img: reservioirs,
                value: 'reservoirs',
            },
            {
                type: 'with_img',
                text: 'Swamps',
                img: swamps,
                value: 'swamps',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 1,
        step: 3,
        nextStep: 4,
        prevStep: 2,
        progress: 45,
        analyticsKey: 'what_fish',
        title: 'What fish species do you usually target?',
        text: 'This information will help us understand your preferences.',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Largemouth Bass',
                img: larg,
                value: 'largemous_bass',
            },
            {
                type: 'with_img',
                text: 'Crappie',
                img: crappie,
                value: 'crappie',
            },
            {
                type: 'with_img',
                text: 'Bluegill',
                img: bluegill,
                value: 'bluegill',
            },
            {
                type: 'with_img',
                text: 'Common Snook',
                img: common,
                value: 'common_snook',
            },
            {
                type: 'with_img',
                text: 'Smallmouth Bass',
                img: small,
                value: 'smallmouth_bass',
            },
            {
                type: 'with_img',
                text: 'Channel Catfish',
                img: channel,
                value: 'channel_catfish',
            },
            {
                type: 'with_img',
                text: 'Redfish',
                img: red,
                value: 'redfish',
            },
            {
                type: 'with_img',
                text: 'Walleye',
                img: walleye,
                value: 'walleye',
            },
            {
                type: 'with_img',
                text: 'Brown Trout',
                img: brown,
                value: 'brown_trout',
            },
            {
                type: 'with_img',
                text: 'Northern pike',
                img: north,
                value: 'northern_pike',
            },
            {
                type: 'with_img',
                text: 'Muskie',
                img: muskie,
                value: 'muskie',
            },
            {
                type: 'with_img',
                text: 'Panfish',
                img: panfish,
                value: 'panfish',
            },
            {
                type: 'with_img',
                text: 'Red snapper',
                img: snapper,
                value: 'red_snapper',
            },
            {
                type: 'with_img',
                text: 'Tuna',
                img: tuna,
                value: 'tuna',
            },
            {
                type: 'with_img',
                text: 'Mahi-mahi',
                img: mahi,
                value: 'mahi_mahi',
            },
            {
                type: 'with_img',
                text: 'Flounder',
                img: flounder,
                value: 'flounder',
            },
            {
                type: 'with_img',
                text: 'Grouper',
                img: grouper,
                value: 'grouper',
            },
            {
                type: 'with_img',
                text: 'Striped bass',
                img: striped,
                value: 'striped_bass',
            },
            {
                type: 'with_img',
                text: 'Tarpon',
                img: tarpon,
                value: 'tarpon',
            },
            {
                type: 'with_img',
                text: 'Bluefish',
                img: bluefish,
                value: 'bluefish',
            },
            {
                type: 'with_img',
                text: 'Other freshwater fish',
                img: otherFresh,
                imgStyles: 'w-[93px] h-[88px] !min-h-[auto] !min-w-[auto]',
                value: 'other_freshwater_fish',
            },
            {
                type: 'with_img',
                text: 'Other saltwater fish',
                img: otherSalt,
                imgStyles: 'w-[93px] h-[88px] !min-h-[auto] !min-w-[auto]',
                value: 'other_saltwater_fish',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 4,
        nextStep: 5,
        prevStep: 3,
        progress: 60,
        analyticsKey: 'what_time',
        title: 'What time intervals you usually choose to plan your fishing trip?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'Early morning (from 5 a.m. till 9 a.m.)',
                value: 'early_morning',
            },
            {
                type: 'with_tick',
                text: 'Late morning (from 9 a.m. till 12 p.m.)',
                value: 'late_morning',
            },
            {
                type: 'with_tick',
                text: 'Afternoon (from 12 p.m. till 6 p.m.)',
                value: 'afternoon',
            },
            {
                type: 'with_tick',
                text: 'Late evening (from 6 p.m. till 10 p.m.)',
                value: 'late_evening',
            },
            {
                type: 'with_tick',
                text: 'Night time (from 10 p.m. till 5 a.m.)',
                value: 'night_time',
            },
        ],
    },
    {
        type: 'question',
        mainQuestion: 1,
        step: 5,
        nextStep: 6,
        prevStep: 4,
        progress: 75,
        analyticsKey: 'what_angling_method',
        title: 'What angling method do you usually use?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'Bottom fishing',
                value: 'bottom_fishing',
            },
            {
                type: 'with_tick',
                text: 'Fly-fishing',
                value: 'fly_fishing',
            },
            {
                type: 'with_tick',
                text: 'Spin fishing',
                value: 'spin_fishing',
            },
            {
                type: 'with_tick',
                text: 'Jigging',
                value: 'jigging',
            },
            {
                type: 'with_tick',
                text: 'Trolling',
                value: 'trolling',
            },
            {
                type: 'with_tick',
                text: 'Casting',
                value: 'casting',
            },
            {
                type: 'with_tick',
                text: 'Other method',
                value: 'other_method',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 1,
        step: 6,
        nextStep: 7,
        prevStep: 5,
        progress: 90,
        analyticsKey: 'what_kind_of_lures',
        title: 'What kind of fishing baits/lures do you use most often?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_img',
                text: 'Flies',
                img: flies,
                value: 'flies',
            },
            {
                type: 'with_img',
                text: 'Jigs',
                img: jigs,
                value: 'jigs',
            },
            {
                type: 'with_img',
                text: 'Spinnerbaits & Buzzbaits',
                img: spinner,
                value: 'spinnerbaits_buzzbaits',
            },
            {
                type: 'with_img',
                text: 'Plugs',
                img: plugs,
                value: 'plugs',
            },
            {
                type: 'with_img',
                text: 'Spoons',
                img: spoons,
                value: 'spoons',
            },
            {
                type: 'with_img',
                text: 'Soft bait lures',
                img: soft,
                value: 'soft_bait_lures',
            },
            {
                type: 'with_img',
                text: 'Other lures',
                img: other,
                imgStyles: 'w-[93px] h-[88px] !min-h-[auto] !min-w-[auto]',
                value: 'other_lures',
            },
            {
                type: 'with_img',
                text: 'Insects',
                img: insects,
                value: 'insects',
            },
            {
                type: 'with_img',
                text: 'Minnows',
                img: minnows,
                value: 'minnows',
            },
            {
                type: 'with_img',
                text: 'Maggots',
                img: maggots,
                value: 'maggots',
            },
            {
                type: 'with_img',
                text: 'Worms',
                img: worms,
                value: 'worms',
            },
            {
                type: 'with_img',
                text: 'Other baits',
                img: baits,
                value: 'other_baits',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 1,
        step: 7,
        nextStep: 8,
        prevStep: 6,
        progress: 95,
        analyticsKey: 'in_which_layer_of_water',
        title: 'In which layer of water do you usually catch fish?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'I like to fish in shallow water, around 3-5 feet deep',
                value: '3_5_feet_deep',
            },
            {
                type: 'with_tick',
                text: 'It depends on my current target species, but I usually fish in a 10-15 feet deep water',
                value: '10_15_feet_deep',
            },
            {
                type: 'with_tick',
                text: 'I prefer to fish in deeper water, around 20-30 feet deep',
                value: '20_30_feet_deep',
            },
            {
                type: 'with_tick',
                text: 'I fish in water that is 25+ feet deep',
                value: '25_feet_deep',
            },
        ],
    },

    {
        type: 'screen_1',
        mainQuestion: 2,
        step: 8,
        nextStep: 9,
        progress: 0,
        analyticsKey: 'our_algorithms',
        withOutAnalyticsValue: true,
        title: 'Our algorithms are based on 21 parameters',
        text: "We'll literally show you the best spots to fish in your area based on real-time trends, which means you'll always fish in areas filled with fish, and be spared from long preparations for each trip.",
        help: 'Fishbox™ Team',
        img: screen1,
        buttonText: 'Sounds good',
        isButtonEnabled: true,
    },

    {
        type: 'question',
        step: 9,
        nextStep: 10,
        prevStep: 8,
        mainQuestion: 2,
        progress: 20,
        analyticsKey: 'what_describes_you_better',
        title: 'What describes you better?',
        text: 'This information will help us understand your experience.',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'I’m a beginner',
                value: 'beginner',
            },
            {
                type: 'radio',
                text: 'I’m an amateur',
                value: 'amateur',
            },
            {
                type: 'radio',
                text: 'I’m an experienced angler',
                value: 'experienced',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 2,
        step: 10,
        nextStep: 11,
        prevStep: 9,
        progress: 40,
        analyticsKey: 'who_do_you_go_fishing_with',
        title: 'Who do you usually go fishing with?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: 'I fish alone',
                value: 'alone',
            },
            {
                type: 'with_tick',
                text: 'With family members',
                value: 'family',
            },
            {
                type: 'with_tick',
                text: 'With friends',
                value: 'friends',
            },
            {
                type: 'with_tick',
                text: 'With a professional guide',
                value: 'professional_guide',
            },
            {
                type: 'with_tick',
                text: 'With coworkers or business associates',
                value: 'business_associates',
            },
        ],
    },

    {
        type: 'question',
        step: 11,
        nextStep: 12,
        prevStep: 10,
        mainQuestion: 2,
        progress: 60,
        analyticsKey: 'do_you_agree',
        title: 'Do you agree with the following:',
        desc: "“I don't like crowded fishing spots”",
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'Completely agree',
                value: 'completely_agree',
            },
            {
                type: 'radio',
                text: 'Agree',
                value: 'agree',
            },
            {
                type: 'radio',
                text: 'Neutral',
                value: 'neutral',
            },
            {
                type: 'radio',
                text: 'Disagree',
                value: 'disagree',
            },
            {
                type: 'radio',
                text: 'Totally disagree',
                value: 'totally_disagree',
            },
        ],
    },

    {
        type: 'question',
        step: 12,
        nextStep: 13,
        prevStep: 11,
        mainQuestion: 2,
        progress: 80,
        analyticsKey: 'how_often_disappointed',
        title: 'How often are you disappointed with your fishing trip?',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'Often',
                value: 'often',
            },
            {
                type: 'radio',
                text: 'Sometimes',
                value: 'sometimes',
            },
            {
                type: 'radio',
                text: 'Rarely',
                value: 'rarely',
            },
            {
                type: 'radio',
                text: 'Never',
                value: 'never',
            },
        ],
    },

    {
        type: 'question',
        mainQuestion: 2,
        step: 13,
        nextStep: 14,
        prevStep: 12,
        progress: 90,
        analyticsKey: 'what_difficulties',
        title: 'What difficulties are you experiencing most often?',
        help: 'Choose as many as you like',
        answerType: 'multiple',
        answers: [
            {
                type: 'with_tick',
                text: "I don't know when fish is biting",
                value: 'fish_is_biting',
            },
            {
                type: 'with_tick',
                text: " I don't know spots where fish are active",
                value: 'spots',
            },
            {
                type: 'with_tick',
                text: "I don't know what fish are habitat in a particular spot",
                value: 'fish_are_habitat',
            },
            {
                type: 'with_tick',
                text: " I don't know what gear I need",
                value: 'what_gear',
            },
            {
                type: 'with_tick',
                text: "I don't know spot's rules and regulations",
                value: 'regulations',
            },
            {
                type: 'with_tick',
                text: 'Bad weather',
                value: 'bad_weather',
            },
            {
                type: 'with_tick',
                text: 'Fishing gear is a way too expensive',
                value: 'too_expensive',
            },
            {
                type: 'with_tick',
                text: "I don't have enough time 😭",
                value: 'enough_time',
            },
        ],
    },

    {
        type: 'screen_2',
        mainQuestion: 3,
        step: 14,
        nextStep: 15,
        progress: 0,
        img: screen2,
        analyticsKey: 'you_are_not_alone',
        withOutAnalyticsValue: true,
        buttonText: 'Continue',
        isButtonEnabled: true,
    },

    {
        type: 'state',
        mainQuestion: 3,
        step: 15,
        nextStep: 16,
        prevStep: 14,
        progress: 30,
        analyticsKey: 'your_state',
        buttonText: 'Continue',
        titleText: 'Choose your state:',
    },

    {
        type: 'city',
        mainQuestion: 3,
        step: 16,
        nextStep: 17,
        prevStep: 15,
        progress: 60,
        analyticsKey: 'your_city',
        img: city,
        buttonText: 'Continue',
    },

    {
        type: 'question',
        step: 17,
        nextStep: 18,
        prevStep: 16,
        mainQuestion: 3,
        progress: 90,
        analyticsKey: 'how_far',
        title: 'How far from the city are you ready to go on your fishing trip?',
        answerType: 'single',
        answers: [
            {
                type: 'radio',
                text: 'Up to 25 miles',
                value: '25_miles',
            },
            {
                type: 'radio',
                text: 'Up to 50 miles',
                value: '50_miles',
            },
            {
                type: 'radio',
                text: 'Up to 100 miles',
                value: '100_miles',
            },
            {
                type: 'radio',
                text: 'Up to 200 miles',
                value: '200_miles',
            },
            {
                type: 'radio',
                text: 'Up to 200+ miles',
                value: 'more_200_miles',
            },
        ],
    },

    {
        type: 'loading',
        step: 18,
        nextStep: 19,
        withoutButton: true,
        data: {
            eventFillName: 'analyzing answers',
            nextRoute: '/onboarding/PersonalizationD2',
            title: 'Analyzing your answers...',
            subtitle: 'Trusted by over 149,569 people',
            loadingArrays: [
                {
                    text: 'Analysis of water bodies nearby',
                    title: 'Analyzing your answers...',
                    color: '#5682F2',
                    steps: [
                        {
                            finish: 30,
                            timeout: 133,
                        },
                        {
                            finish: 70,
                            timeout: 70,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
                {
                    text: 'Calculation of biting activity',
                    color: '#4ABCE1',
                    title: 'Analyzing weather parameters...',
                    steps: [
                        {
                            finish: 30,
                            timeout: 133,
                        },
                        {
                            finish: 70,
                            timeout: 50,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
                {
                    text: 'Generating Your Personal Map',
                    color: '#39CAB9',
                    title: 'Looking for hotspots near you...',
                    steps: [
                        {
                            finish: 30,
                            timeout: 100,
                        },
                        {
                            finish: 70,
                            timeout: 50,
                        },
                        {
                            finish: 100,
                            timeout: 33,
                        },
                    ],
                },
            ],
            loadingArraysSecond: [
                {
                    text: 'Generating Your Personal Map',
                    color: '#39CAB9',
                    title: 'Looking for hotspots near you...',
                    titles: [
                        {
                            text: 'Analyzing weather parameters...',
                            percent: 0,
                        },
                        {
                            text: 'Looking for hotspots near you...',
                            percent: 64,
                        },
                        {
                            text: 'Your personal map is complete',
                            percent: 98,
                        },
                    ],

                    steps: [
                        {
                            finish: 30,
                            timeout: 200,
                        },
                        {
                            finish: 70,
                            timeout: 100,
                        },
                        {
                            finish: 100,
                            timeout: 66,
                        },
                    ],
                },
            ],
            loadingArraysThird: TWEAKS_LOADERS.analyze_loaders,
            loadingTextArray: [
                {
                    startPercent: 0,
                    finishPercent: 50,
                    text: 'Connecting to database',
                },
                {
                    startPercent: 51,
                    finishPercent: 99,
                    text: 'Preliminary processing',
                },
            ],
        },
    },

    {
        type: 'email',
        step: 19,
        nextStep: 20,
        analyticsKey: 'email',
        withoutButton: true,
        title: 'Where should we send your personalized hotspots & fishing map',
        desc: 'Enter your email to get started',
        buttonText: 'See my map',
    },

    {
        type: 'landing',
        step: 20,
    },
];

export const quizData = {
    totalMainSteps: 4,
    steps: [
        {
            type: 'first_screen',
            mainQuestion: 1,
            progress: 20,
            step: -1,
            withoutButton: true,
            noSet: true,
            screens: {
                1: {
                    img: firstScreen,
                    title: 'Find the best fishing spots near you',
                    text: 'Answer a quick quiz to receive your personal hot spots map',
                    question: 'Select the type of water you usually fish in:',
                    nextStep: 1,
                    prevStep: 0,
                    analyticsKey: 'type_of_water',
                    answers: [
                        {
                            type: 'button',
                            text: 'Fresh',
                            img: fresh,
                            value: 'fresh',
                        },
                        {
                            type: 'button',
                            text: 'Salt',
                            img: salt,
                            value: 'salt',
                        },
                    ],
                },
                2: {
                    img: firstScreen,
                    imgDesc: '*828 top spots in US',
                    title: 'Discover new fishing spots nearby tailored to your fishing preferences',
                    nextStep: 1,
                    prevStep: 0,
                    loadingArrays: [
                        {
                            title: 'Loading the test',
                            color: '#0A364A',
                            colorLoader: '#5682F2',
                            steps: [
                                {
                                    finish: 30,
                                    timeout: 133,
                                },
                                {
                                    finish: 70,
                                    timeout: 70,
                                },
                                {
                                    finish: 100,
                                    timeout: 33,
                                },
                            ],
                        },
                    ],
                },
                [FIRST_SCREEN_VARIANT_WATER_V1]: {
                    img: firstScreen,
                    title: 'Your Fishing Map is Almost Ready!',
                    text: 'Just answer 3 quick questions to customize it.',
                    question: 'Select the type of water you usually fish in:',
                    nextStep: 1,
                    mainQuestion: 1,
                    prevStep: 0,
                    progress: 20,
                    analyticsKey: 'type_of_water',
                    answerType: 'single',
                    answers: [
                        {
                            type: 'with_img',
                            text: 'Fresh',
                            img: freshFS,
                            value: 'fresh',
                        },
                        {
                            type: 'with_img',
                            text: 'Salt',
                            img: saltFS,
                            value: 'salt',
                        },
                    ],
                },
                [FIRST_SCREEN_VARIANT_WATER_V2]: {
                    title: ' Find the best fishing spots near you',
                    question: 'Select the type of water you usually fish in:',
                    nextStep: 1,
                    mainQuestion: 1,
                    prevStep: 0,
                    progress: 20,
                    analyticsKey: 'type_of_water',
                    answerType: 'single',
                    answers: [
                        {
                            text: 'Fresh',
                            type: 'button',
                            value: 'fresh',
                        },
                        {
                            text: 'Salt',
                            type: 'button',
                            value: 'salt',
                        },
                    ],
                },
            },
        },
        {
            type: 'question',
            step: 0,
            analyticsKey: 'type_of_water',
            nextStep: 1,
            mainQuestion: 1,
            progress: 25,
            title: 'Discover Your Perfect Fishing Map',
            text: 'Answer a quick customized quiz to receive your hotspots map and 7-day fishing forecast.',
            desc: 'Select the type of water you usually fish in:',
            answerType: 'single',
            answers: [
                {
                    type: 'with_img',
                    text: 'Fresh',
                    img: fresh,
                    value: 'fresh',
                },
                {
                    type: 'with_img',
                    text: 'Salt',
                    img: salt,
                    value: 'salt',
                },
                {
                    type: 'radio',
                    text: 'Both',
                    value: 'both',
                    textStyles: '!text-center',
                },
            ],
        },
        {
            type: 'question',
            mainQuestion: 1,
            step: 1,
            nextStep: 2,
            prevStep: 0,
            prevStepAlt: -1,
            progress: 20,
            analyticsKey: 'where_are_you_fishing',
            title: 'Where are you enjoying fishing most of all?',
            help: 'Choose as many as you like',
            answerType: 'multiple',
            answers: [
                {
                    type: 'with_img',
                    text: 'Boat',
                    img: boat,
                    value: 'boat',
                },
                {
                    type: 'with_img',
                    text: 'Bank',
                    img: off,
                    value: 'bank',
                },
                {
                    type: 'with_img',
                    text: 'Pier or dock',
                    img: pier,
                    value: 'pier_or_dock',
                },
                {
                    type: 'with_img',
                    text: 'Wading position',
                    img: wading,
                    value: 'wading_position',
                },
            ],
        },
        {
            type: 'question',
            mainQuestion: 1,
            step: 2,
            nextStep: 3,
            prevStep: 1,
            progress: 30,
            analyticsKey: 'what_waterbodies',
            title: 'What waterbodies you usually fish at?',
            help: 'Choose as many as you like',
            answerType: 'multiple',
            answers: [
                {
                    type: 'with_img',
                    text: 'Lakes',
                    img: lake,
                    value: 'lakes',
                },
                {
                    type: 'with_img',
                    text: 'Rivers',
                    img: rivers,
                    value: 'rivers',
                },
                {
                    type: 'with_img',
                    text: 'Ponds',
                    img: ponds,
                    value: 'ponds',
                },
                {
                    type: 'with_img',
                    text: 'Creeks',
                    img: creeks,
                    value: 'creeks',
                },
                {
                    type: 'with_img',
                    text: 'Reservoirs',
                    img: reservioirs,
                    value: 'reservoirs',
                },
                {
                    type: 'with_img',
                    text: 'Swamps',
                    img: swamps,
                    value: 'swamps',
                },
            ],
        },
        {
            type: 'question',
            mainQuestion: 1,
            step: 3,
            nextStep: 4,
            prevStep: 2,
            progress: 45,
            analyticsKey: 'what_fish',
            title: 'What fish species do you usually target?',
            text: 'This information will help us understand your preferences.',
            help: 'Choose as many as you like',
            answerType: 'multiple',
            answers: [
                {
                    type: 'with_img',
                    text: 'Largemouth Bass',
                    img: larg,
                    value: 'largemous_bass',
                },
                {
                    type: 'with_img',
                    text: 'Crappie',
                    img: crappie,
                    value: 'crappie',
                },
                {
                    type: 'with_img',
                    text: 'Bluegill',
                    img: bluegill,
                    value: 'bluegill',
                },
                {
                    type: 'with_img',
                    text: 'Smallmouth Bass',
                    img: small,
                    value: 'smallmouth_bass',
                },
                {
                    type: 'with_img',
                    text: 'Channel Catfish',
                    img: channel,
                    value: 'channel_catfish',
                },
                {
                    type: 'with_img',
                    text: 'Redfish',
                    img: red,
                    value: 'redfish',
                },
                {
                    type: 'with_img',
                    text: 'White Bass',
                    img: whiteBass,
                    value: 'white_bass',
                },
                {
                    type: 'with_img',
                    text: 'Walleye',
                    img: walleye,
                    value: 'walleye',
                },
                {
                    type: 'with_img',
                    text: 'Brown Trout',
                    img: brown,
                    value: 'brown_trout',
                },
                {
                    type: 'with_img',
                    text: 'Northern pike',
                    img: north,
                    value: 'northern_pike',
                },

                {
                    type: 'with_img',
                    text: 'Common Carp',
                    img: commonCarp,
                    value: 'common_carp',
                },
                {
                    type: 'with_img',
                    text: 'Yellow Perch',
                    img: yellowPerch,
                    value: 'yellow_perch',
                },
                {
                    type: 'with_img',
                    text: 'Bullhead Catfish',
                    img: bullheadCatfish,
                    value: 'bullhead_catfish',
                },
                {
                    type: 'with_img',
                    text: 'Striped bass',
                    img: striped,
                    value: 'striped_bass',
                },

                {
                    type: 'with_img',
                    text: 'Muskie',
                    img: muskie,
                    value: 'muskie',
                },
                {
                    type: 'with_img',
                    text: 'Panfish',
                    img: panfish,
                    value: 'panfish',
                },
                {
                    type: 'with_img',
                    text: 'Rainbow Trout',
                    img: rainbowTrout,
                    value: 'rainbow_trout',
                },
                {
                    type: 'with_img',
                    text: 'Sturgeon',
                    img: sturgeon,
                    value: 'sturgeon',
                },
                {
                    type: 'with_img',
                    text: 'Freshwater Drum',
                    img: freshwaterDrum,
                    value: 'freshwater_drum',
                },
                {
                    type: 'with_img',
                    text: 'Gar',
                    img: gar,
                    value: 'gar',
                },
                {
                    type: 'with_img',
                    text: 'Shad',
                    img: shad,
                    value: 'shad',
                },
                {
                    type: 'with_img',
                    text: 'Other freshwater fish',
                    img: otherFresh,
                    imgStyles: 'w-[93px] h-[88px] !min-h-[auto] !min-w-[auto]',
                    value: 'other_freshwater_fish',
                },
            ],
        },
        {
            type: 'question',
            mainQuestion: 1,
            step: 4,
            nextStep: 5,
            prevStep: 3,
            progress: 60,
            analyticsKey: 'what_time',
            title: 'What time intervals you usually choose to plan your fishing trip?',
            help: 'Choose as many as you like',
            answerType: 'multiple',
            answers: [
                {
                    type: 'with_tick',
                    text: 'Early morning (from 5 a.m. till 9 a.m.)',
                    value: 'early_morning',
                },
                {
                    type: 'with_tick',
                    text: 'Late morning (from 9 a.m. till 12 p.m.)',
                    value: 'late_morning',
                },
                {
                    type: 'with_tick',
                    text: 'Afternoon (from 12 p.m. till 6 p.m.)',
                    value: 'afternoon',
                },
                {
                    type: 'with_tick',
                    text: 'Late evening (from 6 p.m. till 10 p.m.)',
                    value: 'late_evening',
                },
                {
                    type: 'with_tick',
                    text: 'Night time (from 10 p.m. till 5 a.m.)',
                    value: 'night_time',
                },
            ],
        },
        {
            type: 'question',
            mainQuestion: 1,
            step: 5,
            nextStep: 6,
            prevStep: 4,
            progress: 75,
            analyticsKey: 'what_angling_method',
            title: 'What angling method do you usually use?',
            help: 'Choose as many as you like',
            answerType: 'multiple',
            answers: [
                {
                    type: 'with_tick',
                    text: 'Bottom fishing',
                    value: 'bottom_fishing',
                },
                {
                    type: 'with_tick',
                    text: 'Fly-fishing',
                    value: 'fly_fishing',
                },
                {
                    type: 'with_tick',
                    text: 'Spin fishing',
                    value: 'spin_fishing',
                },
                {
                    type: 'with_tick',
                    text: 'Jigging',
                    value: 'jigging',
                },
                {
                    type: 'with_tick',
                    text: 'Trolling',
                    value: 'trolling',
                },
                {
                    type: 'with_tick',
                    text: 'Casting',
                    value: 'casting',
                },
                {
                    type: 'with_tick',
                    text: 'Other method',
                    value: 'other_method',
                },
            ],
        },
        {
            type: 'question',
            mainQuestion: 1,
            step: 6,
            nextStep: 7,
            prevStep: 5,
            progress: 90,
            analyticsKey: 'what_kind_of_lures',
            title: 'What kind of fishing baits/lures do you use most often?',
            help: 'Choose as many as you like',
            answerType: 'multiple',
            answers: [
                {
                    type: 'with_img',
                    text: 'Flies',
                    img: flies,
                    value: 'flies',
                },
                {
                    type: 'with_img',
                    text: 'Jigs',
                    img: jigs,
                    value: 'jigs',
                },
                {
                    type: 'with_img',
                    text: 'Spinnerbaits & Buzzbaits',
                    img: spinner,
                    value: 'spinnerbaits_buzzbaits',
                },
                {
                    type: 'with_img',
                    text: 'Plugs',
                    img: plugs,
                    value: 'plugs',
                },
                {
                    type: 'with_img',
                    text: 'Spoons',
                    img: spoons,
                    value: 'spoons',
                },
                {
                    type: 'with_img',
                    text: 'Soft bait lures',
                    img: soft,
                    value: 'soft_bait_lures',
                },
                {
                    type: 'with_img',
                    text: 'Other lures',
                    img: other,
                    imgStyles: 'w-[93px] h-[88px] !min-h-[auto] !min-w-[auto]',
                    value: 'other_lures',
                },
                {
                    type: 'with_img',
                    text: 'Insects',
                    img: insects,
                    value: 'insects',
                },
                {
                    type: 'with_img',
                    text: 'Minnows',
                    img: minnows,
                    value: 'minnows',
                },
                {
                    type: 'with_img',
                    text: 'Maggots',
                    img: maggots,
                    value: 'maggots',
                },
                {
                    type: 'with_img',
                    text: 'Worms',
                    img: worms,
                    value: 'worms',
                },
                {
                    type: 'with_img',
                    text: 'Other baits',
                    img: baits,
                    value: 'other_baits',
                },
            ],
        },
        {
            type: 'question',
            mainQuestion: 1,
            step: 7,
            nextStep: 8,
            prevStep: 6,
            progress: 95,
            analyticsKey: 'in_which_layer_of_water',
            title: 'In which layer of water do you usually catch fish?',
            help: 'Choose as many as you like',
            answerType: 'multiple',
            answers: [
                {
                    type: 'with_tick',
                    text: 'I like to fish in shallow water, around 3-5 feet deep',
                    value: '3_5_feet_deep',
                },
                {
                    type: 'with_tick',
                    text: 'It depends on my current target species, but I usually fish in a 10-15 feet deep water',
                    value: '10_15_feet_deep',
                },
                {
                    type: 'with_tick',
                    text: 'I prefer to fish in deeper water, around 20-30 feet deep',
                    value: '20_30_feet_deep',
                },
                {
                    type: 'with_tick',
                    text: 'I fish in water that is 25+ feet deep',
                    value: '25_feet_deep',
                },
            ],
        },
        {
            type: 'screen_1',
            mainQuestion: 2,
            step: 8,
            nextStep: 9,
            progress: 0,
            analyticsKey: 'our_algorithms',
            withOutAnalyticsValue: true,
            title: 'Our algorithms are based on 21 parameters',
            text: "We'll literally show you the best spots to fish in your area based on real-time trends, which means you'll always fish in areas filled with fish, and be spared from long preparations for each trip.",
            help: 'Fishbox™ Team',
            img: screen1,
            buttonText: 'Sounds good',
            isButtonEnabled: true,
        },
        {
            type: 'question',
            step: 9,
            nextStep: 10,
            prevStep: 8,
            mainQuestion: 2,
            progress: 20,
            analyticsKey: 'what_describes_you_better',
            title: 'What describes you better?',
            text: 'This information will help us understand your experience.',
            answerType: 'single',
            answers: [
                {
                    type: 'radio',
                    text: 'I’m a beginner',
                    value: 'beginner',
                },
                {
                    type: 'radio',
                    text: 'I’m an amateur',
                    value: 'amateur',
                },
                {
                    type: 'radio',
                    text: 'I’m an experienced angler',
                    value: 'experienced',
                },
            ],
        },
        {
            type: 'question',
            mainQuestion: 2,
            step: 10,
            nextStep: 11,
            prevStep: 9,
            progress: 40,
            analyticsKey: 'who_do_you_go_fishing_with',
            title: 'Who do you usually go fishing with?',
            help: 'Choose as many as you like',
            answerType: 'multiple',
            answers: [
                {
                    type: 'with_tick',
                    text: 'I fish alone',
                    value: 'alone',
                },
                {
                    type: 'with_tick',
                    text: 'With family members',
                    value: 'family',
                },
                {
                    type: 'with_tick',
                    text: 'With friends',
                    value: 'friends',
                },
                {
                    type: 'with_tick',
                    text: 'With a professional guide',
                    value: 'professional_guide',
                },
                {
                    type: 'with_tick',
                    text: 'With coworkers or business associates',
                    value: 'business_associates',
                },
            ],
        },
        {
            type: 'question',
            step: 11,
            nextStep: 12,
            prevStep: 10,
            mainQuestion: 2,
            progress: 60,
            analyticsKey: 'do_you_agree',
            title: 'Do you agree with the following:',
            desc: "“I don't like crowded fishing spots”",
            answerType: 'single',
            answers: [
                {
                    type: 'radio',
                    text: 'Completely agree',
                    value: 'completely_agree',
                },
                {
                    type: 'radio',
                    text: 'Agree',
                    value: 'agree',
                },
                {
                    type: 'radio',
                    text: 'Neutral',
                    value: 'neutral',
                },
                {
                    type: 'radio',
                    text: 'Disagree',
                    value: 'disagree',
                },
                {
                    type: 'radio',
                    text: 'Totally disagree',
                    value: 'totally_disagree',
                },
            ],
        },
        {
            type: 'question',
            step: 12,
            nextStep: 13,
            prevStep: 11,
            mainQuestion: 2,
            progress: 80,
            analyticsKey: 'how_often_disappointed',
            title: 'How often are you disappointed with your fishing trip?',
            answerType: 'single',
            answers: [
                {
                    type: 'radio',
                    text: 'Often',
                    value: 'often',
                },
                {
                    type: 'radio',
                    text: 'Sometimes',
                    value: 'sometimes',
                },
                {
                    type: 'radio',
                    text: 'Rarely',
                    value: 'rarely',
                },
                {
                    type: 'radio',
                    text: 'Never',
                    value: 'never',
                },
            ],
        },
        {
            type: 'question',
            mainQuestion: 2,
            step: 13,
            nextStep: 14,
            prevStep: 12,
            progress: 90,
            analyticsKey: 'what_difficulties',
            title: 'What difficulties are you experiencing most often?',
            help: 'Choose as many as you like',
            answerType: 'multiple',
            answers: [
                {
                    type: 'with_tick',
                    text: "I don't know when fish is biting",
                    value: 'fish_is_biting',
                },
                {
                    type: 'with_tick',
                    text: " I don't know spots where fish are active",
                    value: 'spots',
                },
                {
                    type: 'with_tick',
                    text: "I don't know what fish are habitat in a particular spot",
                    value: 'fish_are_habitat',
                },
                {
                    type: 'with_tick',
                    text: " I don't know what gear I need",
                    value: 'what_gear',
                },
                {
                    type: 'with_tick',
                    text: "I don't know spot's rules and regulations",
                    value: 'regulations',
                },
                {
                    type: 'with_tick',
                    text: 'Bad weather',
                    value: 'bad_weather',
                },
                {
                    type: 'with_tick',
                    text: 'Fishing gear is a way too expensive',
                    value: 'too_expensive',
                },
                {
                    type: 'with_tick',
                    text: "I don't have enough time 😭",
                    value: 'enough_time',
                },
            ],
        },
        {
            type: 'screen_2',
            mainQuestion: 3,
            step: 14,
            nextStep: 15,
            progress: 0,
            img: screen2,
            analyticsKey: 'you_are_not_alone',
            withOutAnalyticsValue: true,
            buttonText: 'Continue',
            isButtonEnabled: true,
        },
        {
            type: 'state',
            mainQuestion: 3,
            step: 15,
            nextStep: 16,
            prevStep: 14,
            progress: 30,
            analyticsKey: 'your_state',
            buttonText: 'Continue',
            titleText: 'Choose your state:',
        },
        {
            type: 'city',
            mainQuestion: 3,
            step: 16,
            nextStep: 17,
            prevStep: 15,
            progress: 60,
            analyticsKey: 'your_city',
            img: city,
            buttonText: 'Continue',
        },
        {
            type: 'question',
            step: 17,
            nextStep: 18,
            prevStep: 16,
            mainQuestion: 3,
            progress: 90,
            analyticsKey: 'how_far',
            title: 'How far from the city are you ready to go on your fishing trip?',
            answerType: 'single',
            answers: [
                {
                    type: 'radio',
                    text: 'Up to 25 miles',
                    value: '25_miles',
                },
                {
                    type: 'radio',
                    text: 'Up to 50 miles',
                    value: '50_miles',
                },
                {
                    type: 'radio',
                    text: 'Up to 100 miles',
                    value: '100_miles',
                },
                {
                    type: 'radio',
                    text: 'Up to 200 miles',
                    value: '200_miles',
                },
                {
                    type: 'radio',
                    text: 'Up to 200+ miles',
                    value: 'more_200_miles',
                },
            ],
        },
        {
            type: 'loading',
            step: 18,
            nextStep: 19,
            withoutButton: true,
            data: {
                eventFillName: 'analyzing answers',
                nextRoute: '/onboarding/PersonalizationD2',
                title: 'Analyzing your answers...',
                subtitle: 'Trusted by over 149,569 people',
                loadingArrays: [
                    {
                        text: 'Analysis of water bodies nearby',
                        title: 'Analyzing your answers...',
                        color: '#5682F2',
                        steps: [
                            {
                                finish: 30,
                                timeout: 133,
                            },
                            {
                                finish: 70,
                                timeout: 70,
                            },
                            {
                                finish: 100,
                                timeout: 33,
                            },
                        ],
                    },
                    {
                        text: 'Calculation of biting activity',
                        color: '#4ABCE1',
                        title: 'Analyzing weather parameters...',
                        steps: [
                            {
                                finish: 30,
                                timeout: 133,
                            },
                            {
                                finish: 70,
                                timeout: 50,
                            },
                            {
                                finish: 100,
                                timeout: 33,
                            },
                        ],
                    },
                    {
                        text: 'Generating Your Personal Map',
                        color: '#39CAB9',
                        title: 'Looking for hotspots near you...',
                        steps: [
                            {
                                finish: 30,
                                timeout: 100,
                            },
                            {
                                finish: 70,
                                timeout: 50,
                            },
                            {
                                finish: 100,
                                timeout: 33,
                            },
                        ],
                    },
                ],
                loadingArraysSecond: [
                    {
                        text: 'Generating Your Personal Map',
                        color: '#39CAB9',
                        title: 'Looking for hotspots near you...',
                        titles: [
                            {
                                text: 'Analyzing weather parameters...',
                                percent: 0,
                            },
                            {
                                text: 'Looking for hotspots near you...',
                                percent: 64,
                            },
                            {
                                text: 'Your personal map is complete',
                                percent: 98,
                            },
                        ],

                        steps: [
                            {
                                finish: 30,
                                timeout: 200,
                            },
                            {
                                finish: 70,
                                timeout: 100,
                            },
                            {
                                finish: 100,
                                timeout: 66,
                            },
                        ],
                    },
                ],
                loadingArraysThird: TWEAKS_LOADERS.analyze_loaders,
                loadingTextArray: [
                    {
                        startPercent: 0,
                        finishPercent: 50,
                        text: 'Connecting to database',
                    },
                    {
                        startPercent: 51,
                        finishPercent: 99,
                        text: 'Preliminary processing',
                    },
                ],
            },
        },

        {
            type: 'email',
            step: 19,
            nextStep: 20,
            analyticsKey: 'email',
            withoutButton: true,
            title: 'Where should we send your personalized hotspots & fishing map',
            desc: 'Enter your email to get started',
            buttonText: 'See my map',
        },

        {
            type: 'landing',
            step: 20,
        },
    ],
    // стандартный квиз
    steps_us: stepsUs,
    steps_au: stepsAu,
    steps_au_salt: stepsAuSalt,
    // japan
    steps_jp: stepsJp,
    steps_jp_salt: stepsJpSalt,
    steps_ca: stepsCa,
    steps_ca_salt: stepsCaSalt,
    steps_uk: stepsUk,
    steps_uk_salt: stepsUkSalt,
    steps_salt: stepsSalt,
    steps_lures: stepsLures,
    steps_fresh: stepsFresh,
    steps_fresh_salt: stepsFreshSalt,
};

export const percents = [
    {
        percent: 82,
        text: 'of our users caught their biggest fish ever in just 4 weeks of using our product',
    },
    {
        percent: 76,
        text: 'of our users reported feeling less stressed and more relaxed while fishing',
    },
    {
        percent: 59,
        text: 'of our users had a difficulty finding the right fishing spots before using our product',
    },
];

export const access = {
    us: [
        {
            img: access1,
            title: 'Smart Fishing Forecast™',
            text: 'Our forecast works for 1.2M+ U.S. waters. You can use it for your favorite spots',
            imgStyles: 'max-w-[126px]',
        },
        {
            img: access2,
            title: 'Your Personal Assistant',
            text: 'Get advice and help about fishing forecast and your spots',
            imgStyles: 'max-w-[278px]',
        },
        {
            img: access3,
            title: 'Learn 50+ Fishing Knots',
            text: 'Step-by-Step video guides for beginners and pros',
            imgStyles: 'max-w-[170px]',
        },
    ],
    ca: [
        {
            img: access1,
            title: 'Smart Fishing Forecast™',
            text: 'Our forecast works for all CA waters. You can use it for your favorite spots',
            imgStyles: 'max-w-[126px]',
        },
        {
            img: access2,
            title: 'Your Personal Assistant',
            text: 'Get advice and help about fishing forecast and your spots',
            imgStyles: 'max-w-[278px]',
        },
        {
            img: access3,
            title: 'Learn 50+ Fishing Knots',
            text: 'Step-by-Step video guides for beginners and pros',
            imgStyles: 'max-w-[170px]',
        },
    ],
    uk: [
        {
            img: access1,
            title: 'Smart Fishing Forecast™',
            text: 'Our forecast works for all UK waters. You can use it for your favorite spots',
            imgStyles: 'max-w-[126px]',
        },
        {
            img: access2,
            title: 'Your Personal Assistant',
            text: 'Get advice and help about fishing forecast and your spots',
            imgStyles: 'max-w-[278px]',
        },
        {
            img: access3,
            title: 'Learn 50+ Fishing Knots',
            text: 'Step-by-Step video guides for beginners and pros',
            imgStyles: 'max-w-[170px]',
        },
    ],
    default: [
        {
            img: access1,
            title: 'Smart Fishing Forecast™',
            text: 'Our forecast works for 1.2M+ U.S. waters. You can use it for your favorite spots',
            imgStyles: 'max-w-[126px]',
        },
        {
            img: access2,
            title: 'Your Personal Assistant',
            text: 'Get advice and help about fishing forecast and your spots',
            imgStyles: 'max-w-[278px]',
        },
        {
            img: access3,
            title: 'Learn 50+ Fishing Knots',
            text: 'Step-by-Step video guides for beginners and pros',
            imgStyles: 'max-w-[170px]',
        },
    ],
    lures: [
        {
            img: access1,
            title: 'Smart Fishing Forecast™',
            text: 'Our forecast works for 1.2M+ U.S. waters. You can use it for your favorite spots',
            imgStyles: 'max-w-[126px]',
        },
        {
            img: accessLures,
            title: 'The Best Lures & Baits for Your Place',
            text: 'Find out the best lures and baits for fish in your body of water',
        },
        {
            img: access2,
            title: 'Your Personal Assistant',
            text: 'Get advice and help about fishing forecast and your spots',
            imgStyles: 'max-w-[278px]',
        },
        {
            img: access3,
            title: 'Learn 50+ Fishing Knots',
            text: 'Step-by-Step video guides for beginners and pros',
            imgStyles: 'max-w-[170px]',
        },
    ],
    au: [
        {
            img: access1,
            title: 'Smart Fishing Forecast™',
            text: 'Our forecast works for 1.2M+ AU waters. You can use it for your favorite spots',
            imgStyles: 'max-w-[126px]',
        },
        {
            img: access2,
            title: 'Your Personal Assistant',
            text: 'Get advice and help about fishing forecast and your spots',
            imgStyles: 'max-w-[278px]',
        },
        {
            img: access3,
            title: 'Learn 50+ Fishing Knots',
            text: 'Step-by-Step video guides for beginners and pros',
            imgStyles: 'max-w-[170px]',
        },
    ],
    jp: [
        {
            img: access1Jp,
            title: 'Smart Fishing Forecast™',
            text: '日本の全海域に対応しています。お気に入りのスポットにご利用ください。',
            imgStyles: 'max-w-[126px]',
        },
        {
            img: access2Jp,
            title: 'パーソナル アシスタント',
            text: '釣り予報とスポットに関するアドバイスとサポートを受けられます',
            imgStyles: 'max-w-[278px]',
        },
        {
            img: access3,
            title: '50 以上の釣り糸の結び方を学べます',
            text: '初心者とプロ向けのステップ バイ ステップのビデオ ガイド',
            imgStyles: 'max-w-[170px]',
        },
    ],
};

export const landingPlans = [
    {
        value: '0.99',
        text: '$1',
        trialValue: 0.99,
        trialValueText: '$0.99',
        willBeText:
            'Then your yearly Subscription Period will start and you will be charged $27.99 every quarter. No refunds if canceled before the end of Subscription Period.',
    },
    {
        value: '9.78',
        text: '$9.78',
        trialValue: 9.78,
        trialValueText: '$9.78',
        willBeText:
            'Then your yearly Subscription Period will start and you will be charged $27.99 every quarter. No refunds if canceled before the end of Subscription Period.',
    },
];

export const landingPlansJp = [
    {
        value: '80',
        text: '¥80',
        trialValue: 0.8,
        trialValueText: '¥80',
        willBeText:
            'Then your yearly Subscription Period will start and you will be charged $27.99 every quarter. No refunds if canceled before the end of Subscription Period.',
    },
    {
        value: '580',
        text: '¥580',
        trialValue: 5.8,
        trialValueText: '¥580',
        willBeText:
            'Then your yearly Subscription Period will start and you will be charged $27.99 every quarter. No refunds if canceled before the end of Subscription Period.',
    },
];

export const landingPlansUk = [
    {
        value: '1',
        text: '£1',
        trialValue: 1,
        trialValueText: '£1',
        willBeText:
            'Then your yearly Subscription Period will start and you will be charged £27.99 every quarter. No refunds if canceled before the end of Subscription Period.',
    },
    {
        value: '9.78',
        text: '£9.78',
        trialValue: 9.78,
        trialValueText: '£9.78',
        willBeText:
            'Then your yearly Subscription Period will start and you will be charged £27.99 every quarter. No refunds if canceled before the end of Subscription Period.',
    },
];

export const includesArray = [
    'We work with GFS, NAM and ECMWF forecast models.',
    'We get new data every 30 min',
    'You will be able to analyze any weather changes in our hourly mode',
];

export const defaultLandingFishes = {
    ca: { fresh: 'northern_pike', salt: 'northern_pike' },
    uk: { fresh: 'common_carp', salt: 'cod' },
    au: {
        fresh: 'barramundi',
        salt: 'australian_salmon',
    },
    default: {
        fresh: 'largemous_bass',
        salt: 'striped_bass',
    },
};

export const defaultPlans = {
    ca: 'CA_34.99q',
    uk: 'UK_25.49q',
};

export const VARS = {
    THREE_PLANS: '3_plans',
    THREE_PLANS_1: 'plans_v1',
    THREE_PLANS_2: 'plans_v2',
    THREE_PLANS_3: 'plans_v3',
};

export const plansData = [
    {
        title: '1–Week Trial',
        badge: '12-WEEK PLAN',
        oldPrice: '$13,86',
        priceText: '$6,93',
        oldPricePerDay: '$1,98',
        pricePerDay: '$0.99',
        bottomText: {
            __html: `Without cancellation, before the <b>7-day trial</b> ends, I accept that it will convert to a 12-week subscription and Fishbox will automatically charge <b>US$27.99</b> every <b>12 weeks</b> until I cancel.`,
        },
        customPlan: '3_plans_27.99_trial',
        trialCost: {
            value: '6.93',
            text: '$6.93',
            trialValue: 693,
            trialValueText: '$6.93',
            willBeText: '',
        },
    },
    {
        title: '12–Week Plan',
        oldPrice: '$55,99',
        priceText: '$27,99',
        oldPricePerDay: '$0.68',
        pricePerDay: '$0.34',
        isPopular: true,
        bottomText: {
            __html: `Without cancellation, I accept that Fishbox will automatically charge <b>US$27.99</b> every <b>12 weeks</b> until I cancel.`,
        },
        customPlan: '3_plans_27.99_withOUt_trial',
        trialCost: {
            value: '0',
            text: '',
            trialValue: 0,
            trialValueText: '',
            willBeText: '',
        },
    },
    {
        title: '4–Week Plan',
        oldPrice: '$38,64',
        priceText: '$19,32',
        oldPricePerDay: '$1.38',
        pricePerDay: '$0.69',
        bottomText: {
            __html: `Without cancellation, I accept that Fishbox will automatically charge <b>US$19.32</b> every <b>4 weeks</b> until I cancel.`,
        },
        customPlan: '3_plans_19.32_trial',
        trialCost: {
            value: '0',
            text: '',
            trialValue: 0,
            trialValueText: '',
            willBeText: '',
        },
    },
];

export const plansData_1 = [
    {
        title: '1–Week Trial',
        badge: '12-WEEK PLAN',
        oldPrice: '$13,86',
        priceText: '$6,93',
        oldPricePerDay: '$1,98',
        pricePerDay: '$0.99',
        bottomText: {
            __html: `Without cancellation, before the <b>7-day trial</b> ends, I accept that it will convert to a 12-week subscription and Fishbox will automatically charge <b>US$27.99</b> every <b>12 weeks</b> until I cancel.`,
        },
        customPlan: '3_plans_27.99_trial',
        trialCost: {
            value: '6.93',
            text: '$6.93',
            trialValue: 693,
            trialValueText: '$6.93',
            willBeText: '',
        },
    },
    {
        title: '12–Week Plan',
        oldPrice: '$55,99',
        priceText: '$27,99',
        oldPricePerDay: '$0.68',
        pricePerDay: '$0.34',
        isPopular: true,
        bottomText: {
            __html: `Without cancellation, I accept that Fishbox will automatically charge <b>US$27.99</b> every <b>12 weeks</b> until I cancel.`,
        },
        customPlan: '3_plans_27.99_withOUt_trial',
        trialCost: {
            value: '0',
            text: '',
            trialValue: 0,
            trialValueText: '',
            willBeText: '',
        },
    },
    {
        title: '4–Week Plan',
        oldPrice: '$38,64',
        priceText: '$19,32',
        oldPricePerDay: '$1.38',
        pricePerDay: '$0.69',
        bottomText: {
            __html: `Without cancellation, I accept that Fishbox will automatically charge <b>US$19.32</b> every <b>4 weeks</b> until I cancel.`,
        },
        customPlan: '3_plans_19.32_without_trial',
        trialCost: {
            value: '0',
            text: '',
            trialValue: 0,
            trialValueText: '',
            willBeText: '',
        },
    },
];

export const plansData_2 = [
    {
        title: '1–Week Trial',
        badge: '3-Month Plan',
        oldPrice: '$13,99',
        priceText: '$6,99',
        oldPricePerDay: '$1,99',
        pricePerDay: '$0.99',
        bottomText: {
            __html: `Without cancellation, before the <b>7-day trial</b> ends, I accept that it will convert to a 3-month subscription and Fishbox will automatically charge  <b>US$29.99</b> every <b>3 months</b> until I cancel.`,
        },
        customPlan: '3_plans_v2_29.99_trial',
        trialCost: {
            value: '6.99',
            text: '$6.99',
            trialValue: 699,
            trialValueText: '$6.99',
            willBeText: '',
        },
    },
    {
        title: '1-Month Plan',
        oldPrice: '$39,99',
        priceText: '$19,99',
        oldPricePerDay: '$1.33',
        pricePerDay: '$0.67',
        isPopular: true,
        bottomText: {
            __html: `Without cancellation, I accept that Fishbox will automatically charge <b>US$19.99</b> every <b>month</b> until I cancel.`,
        },
        customPlan: '3_plans_v2_19.99_without_trial',
        trialCost: {
            value: '0',
            text: '',
            trialValue: 0,
            trialValueText: '',
            willBeText: '',
        },
    },
    {
        title: '3-Month Plan',
        oldPrice: '$59,99',
        priceText: '$29,99',
        oldPricePerDay: '$0.67',
        pricePerDay: '$0.33',
        bottomText: {
            __html: `Without cancellation, I accept that Fishbox will automatically charge <b>US$29.99</b> every <b>3 months</b> until I cancel.`,
        },
        customPlan: '3_plans_v2_29.99_without_trial',
        trialCost: {
            value: '0',
            text: '',
            trialValue: 0,
            trialValueText: '',
            willBeText: '',
        },
    },
];

export const plansData_3 = [
    {
        title: '1-Month Plan',

        oldPrice: '$39,99',
        priceText: '$19,99',
        oldPricePerDay: '$1,33',
        pricePerDay: '$0.99',
        bottomText: {
            __html: `Without cancellation, I accept that Fishbox will automatically charge <b>US$19.99</b> every <b> month</b> until I cancel.`,
        },
        customPlan: '3_plans_v3_19.99_without_trial',
        trialCost: {
            value: '0',
            text: '',
            trialValue: 0,
            trialValueText: '',
            willBeText: '',
        },
    },
    {
        title: '3-Month Plan',
        oldPrice: '$59,99',
        priceText: '$29,99',
        oldPricePerDay: '$0.66',
        pricePerDay: '$0.33',
        isPopular: true,
        bottomText: {
            __html: `Without cancellation, I accept that Fishbox will automatically charge <b>US$29.99</b> every <b>3 months</b> until I cancel.`,
        },
        customPlan: '3_plans_v3_29.99_without_trial',
        trialCost: {
            value: '0',
            text: '',
            trialValue: 0,
            trialValueText: '',
            willBeText: '',
        },
    },
    {
        title: '12-Month Plan',
        oldPrice: '$99,99',
        priceText: '$49,99',
        oldPricePerDay: '$0.28',
        pricePerDay: '$0.14',
        bottomText: {
            __html: `Without cancellation, I accept that Fishbox will automatically charge <b>US$49.99</b> every <b>12 months</b> until I cancel.`,
        },
        customPlan: '3_plans_v3_49.99_without_trial',
        trialCost: {
            value: '0',
            text: '',
            trialValue: 0,
            trialValueText: '',
            willBeText: '',
        },
    },
];

export const CUSTOMER_DATA_URL =
    'https://billing.stripe.com/p/login/cN2cNbd6R6WX3IsbII';
