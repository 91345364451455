import React, { useEffect, Suspense, Fragment, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ROUTES } from './constants/routes';
import {
    amplitudeInit,
    setPosthogUserProperties,
    setUserProperties,
    setUserPropertiesFromObj,
} from './amplitude/events';
import { remoteFunction } from './constants/remoteKeys';
import { useDispatch, useSelector } from 'react-redux';
import Loader from './components/Loader/Loader';
import {
    setUrlParams,
    setPlan,
    setPageId,
    setPageName,
    setEmail,
    setUTMParams,
    setRemoteConfigValue,
} from './store/reducers/analyticsSlice';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { QUIZ_TYPES } from './constants/quizData';

const Quiz = React.lazy(() => import('./pages/Quiz'));
const Land = React.lazy(() => import('./sections/Land/Land'));
const Landing = React.lazy(() => import('./pages/Landing'));
const Paywall = React.lazy(() => import('./pages/Paywall'));
const Finish = React.lazy(() => import('./pages/Finish'));
const First = React.lazy(() => import('./pages/First'));
const Summary = React.lazy(() => import('./pages/Summary'));

function App() {
    const dispatch = useDispatch();

    const isPopupOpen = useSelector((state) => state.quiz.isPopupOpen);
    const experiments = useSelector(
        (state) => state.analytics.remoteKeys.experiments
    );

    useEffect(() => {
        //analytics
        amplitudeInit();
        setUserProperties(experiments);

        getQueryParams();
    }, []);

    useEffect(() => {
        isPopupOpen
            ? disableBodyScroll(document.body)
            : enableBodyScroll(document.body);
    }, [isPopupOpen]);

    useEffect(() => {
        if (!experiments) return;
        setPosthogUserProperties(experiments);
    }, [experiments]);

    const getQueryParams = (url = window.location) => {
        let params = {};

        new URL(url).searchParams.forEach(function (val, key) {
            if (params[key] !== undefined) {
                if (!Array.isArray(params[key])) {
                    params[key] = [params[key]];
                }
                params[key].push(val);
            } else {
                params[key] = val;
            }
        });
        let resultString = '';
        let plan = '';

        if (params.hasOwnProperty('page_id')) {
            dispatch(setPageId(params.page_id));
        }

        if (params.hasOwnProperty('page_name')) {
            dispatch(setPageName(params.page_name));
        }

        if (params.hasOwnProperty('email')) {
            dispatch(setEmail(params.email));
            setUserPropertiesFromObj({ email: params.email });
        }

        if (params.hasOwnProperty('utm_quiz')) {
            setUserPropertiesFromObj({ fd_quiz: params.utm_quiz });
        }

        dispatch(
            setUTMParams(
                Object.keys(params).reduce((accumulator, key) => {
                    if (key.startsWith('utm_')) {
                        accumulator[key] = params[key];
                    }
                    return accumulator;
                }, {})
            )
        );

        Object.keys(params).forEach((key, index) => {
            if (key === 'plan') {
                localStorage.setItem('plan', params[key]);
                plan = params[key];
            }
            if (index === 0) {
                resultString = resultString + `${key}=${params[key]}`;
            } else {
                resultString = resultString + `&${key}=${params[key]}`;
            }
        });
        remoteFunction(dispatch, setPlan, plan, setRemoteConfigValue);
        dispatch(setUrlParams(resultString));
    };

    return (
        <Routes>
            <Route
                path={ROUTES.HOME}
                element={
                    <Suspense fallback={<Loader />}>
                        <Landing />
                    </Suspense>
                }
            />
            <Route
                path={ROUTES.PAYWALL}
                element={
                    <Suspense fallback={<Loader />}>
                        <Paywall />
                    </Suspense>
                }
            />
            <Route
                path={ROUTES.QUIZ}
                element={
                    <Suspense fallback={<Loader />}>
                        <Quiz type={QUIZ_TYPES.QUIZ_DEFAULT} />
                    </Suspense>
                }
            />
            <Route
                path={`${ROUTES.QUIZ}/:country/:urlStep`}
                element={
                    <Suspense fallback={<Loader />}>
                        <Quiz type={QUIZ_TYPES.QUIZ_DEFAULT} />
                    </Suspense>
                }
            />
            <Route
                path={`${ROUTES.QUIZ}/:country`}
                element={
                    <Suspense fallback={<Loader />}>
                        <Quiz type={QUIZ_TYPES.QUIZ_DEFAULT} />
                    </Suspense>
                }
            />
            <Route
                path={`${ROUTES.QUIZ_TEST}`}
                element={
                    <Suspense fallback={<Loader />}>
                        <Quiz type={QUIZ_TYPES.QUIZ_DEFAULT} />
                    </Suspense>
                }
            />
            <Route
                path={`${ROUTES.QUIZ_TEST}/:country/:urlStep`}
                element={
                    <Suspense fallback={<Loader />}>
                        <Quiz type={QUIZ_TYPES.QUIZ_DEFAULT} />
                    </Suspense>
                }
            />
            <Route
                path={`${ROUTES.QUIZ_TEST}/:country`}
                element={
                    <Suspense fallback={<Loader />}>
                        <Quiz type={QUIZ_TYPES.QUIZ_DEFAULT} />
                    </Suspense>
                }
            />

            <Route
                path={`${ROUTES.QUIZ_LURES}`}
                element={
                    <Suspense fallback={<Loader />}>
                        <Quiz type={QUIZ_TYPES.QUIZ_LURES} />
                    </Suspense>
                }
            />
            <Route
                path={`${ROUTES.QUIZ_LURES}/:country/:urlStep`}
                element={
                    <Suspense fallback={<Loader />}>
                        <Quiz type={QUIZ_TYPES.QUIZ_LURES} />
                    </Suspense>
                }
            />
            <Route
                path={`${ROUTES.QUIZ_LURES}/:country`}
                element={
                    <Suspense fallback={<Loader />}>
                        <Quiz type={QUIZ_TYPES.QUIZ_LURES} />
                    </Suspense>
                }
            />
            <Route
                path={ROUTES.LANDING}
                element={
                    <Suspense fallback={<Loader />}>
                        <Land />
                    </Suspense>
                }
            />
            <Route
                path={`${ROUTES.LANDING}/:country`}
                element={
                    <Suspense fallback={<Loader />}>
                        <Land />
                    </Suspense>
                }
            />
            <Route
                path={ROUTES.FINISH}
                element={
                    <Suspense fallback={<Loader />}>
                        <Finish />
                    </Suspense>
                }
            />
            <Route
                path={`${ROUTES.FIRST_PAGE}/:country`}
                element={
                    <Suspense fallback={<Loader />}>
                        <First />
                    </Suspense>
                }
            />

            <Route
                path={`${ROUTES.SUMMARY}/:country/:nextStep`}
                element={
                    <Suspense fallback={<Loader />}>
                        <Summary />
                    </Suspense>
                }
            />
        </Routes>
    );
}

export default App;
